.serif {      font-family: $font-stack-serif; }
.sans-serif { font-family: $font-stack-sans; }

.measure-extra-wide { max-width: 64em; }

.tracked { letter-spacing: .1em; }

.small-caps {
  font-feature-settings: "c2sc", "smcp";
  font-variant-caps: all-small-caps;
  letter-spacing: 0.05em;
}

.faux-small-caps {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.link {
  cursor: pointer;
  transition: color .15s ease-in, opacity .15s ease-in;
}

.link-black {
  color: $dark !important;
}

.link-red {
  color: $red !important;
}

.link-teal {
  color: $teal !important;
}

.link:link,
.link:visited,
.link:hover,
.link:active {
  transition: color .15s ease-in, opacity .15s ease-in;
}

.link:focus   {
  outline: 1px dotted currentColor;
  transition: color .15s ease-in, opacity .15s ease-in;
}

/**
 * WRAP AND TRUNCATE ------------------ */

.nowrap {
  white-space: nowrap;
}


.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media #{$breakpoint-not-small} {
  .truncate-ns {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/**
 * FONT WEIGHT ------------------------ */

.normal { font-weight: normal; }
.b      { font-weight: bold; }
.fw4    { font-weight: 400; }
.fw7    { font-weight: 700; }

/**
 * FONT STYLE ------------------------- */

.i         { font-style: italic; }
.fs-normal { font-style: normal; }

/**
 * TEXT TRANSFORM --------------------- */

.ttc { text-transform: capitalize; }
.ttl { text-transform: lowercase; }
.ttu { text-transform: uppercase; }
.ttn { text-transform: none; }

/**
 * VERTICAL ALIGN --------------------- */

.v-base     { vertical-align: baseline; }
.v-mid      { vertical-align: middle; }
.v-top      { vertical-align: top; }
.v-btm      { vertical-align: bottom; }
