.sk-checkbox {
  // <div class="sk-checkbox">
  //   <input type="checkbox" id="a">
  //   <label for="a">label</label>
  // </div>
  display: block;
  position: relative;
  min-height: 1.5rem;
  padding-left: 2rem;
  padding-top: 0.15rem;

  input[type=checkbox] {
    left: 0.3rem;
    opacity: 0;
    position: absolute;
    top: 0.95rem;
    z-index: -1;

    &:checked ~ label:before {
      color: $teal;
    }

    &:checked ~ label:after {
      content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath fill='%23017a73' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }

    &:focus ~ label:before {
      outline: 1px solid $teal;
    }
  }

  label {
    margin-bottom: 0;
    display: inline-block;

    &:before {
      background-color: #fff;
      border: 1px solid $gray;
      content: '';
      display: block;
      height: 1.25rem;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: .25rem;
      user-select: none;
      width: 1.25rem;
    }

    &:after {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 50% 50%;
      content: '';
      display: block;
      height: 1.25rem;
      left: 0.18rem;
      position: absolute;
      top: .45rem;
      width: 1.25rem;
    }
  }

}

.sk-input-group {
  position: relative;

  input[type=text],
  input[type=email],
  input[type=password] {
    background-color:: $white;
    border: 1px solid $gray;
    font-family: $font-stack-sans;
    font-size: 1rem;
    min-height: 2rem;
    outline: none;
    padding: .75rem 1rem;
    vertical-align: middle;
  }

  label {
    color: $dark-gray;
    display: inline-block;
    font-family: $font-stack-sans;
    font-size: 1rem;
    left: 1rem;
    pointer-events: none;
    position: absolute;
    top: 0.75rem;
    transition: all 0.2s ease-out;
    z-index: 1;
  }

  input:focus ~ label,
  input:not([value='']) ~ label {
    top: -.75rem;
    left: .5rem;
    font-size: ms(-1);
    padding: 0 .5rem;
  }

  input:not([value='']) ~ label {
    background-color: $white;
    color: $dark;
  }

  input:focus ~ label {
    background-color: $white;
    color: $teal;
  }

  .errorlist {
    margin-bottom: ms(4);
  }
}
