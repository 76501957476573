.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-left: -(ms(-16));
  margin-right: -(ms(-16));
}

.tabs .tab {
  display: block;
  padding: 0 ms(-16);
}

.tabs .tab > a {
  display: block;
  padding: ms(-4) ms(0);
  text-align: center;
  text-decoration: none;
}

@media #{$breakpoint-not-small} {
  .tabs {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .tabs .tab {
    width: auto;
  }
}
