[data-accordion] {

  [data-tab] {
    overflow: hidden;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  label {
    cursor: pointer;
    position: relative;
  }

  .plus-minus {
    display: inline-block;
    width: ms(4);
    height: ms(4);
    border: 0;
    position: relative;
  }

  .plus-minus span {
      position: absolute;
      transition: 0.25s;
      background: $gray;
  }

  .plus-minus span:first-of-type {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
  }

  .plus-minus span:last-of-type {
      top: 45%;
      height: 10%;
      right: 25%;
      left: 25%;
  }

  [data-content] {
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease;
  }

  input[type="checkbox"]:checked ~ label {
    background-color: $light-gray;
  }

  input[type="checkbox"]:checked ~ [data-content] {
    max-height: 25em;
  }

  input[type="checkbox"]:checked ~ label .plus-minus span:first-of-type,
  input[type="checkbox"]:checked ~ label .plus-minus span:last-of-type {
    transform: rotate(90deg);
  }

  input[type="checkbox"]:checked ~ label .plus-minus span:last-of-type {
      left: 50%;
      right: 50%;
  }
}