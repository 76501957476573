.filter-select {
  &-input,
  &-wrapper {
    position: relative;
  }

  &-dropdown {
    background: $white;
    border: 1px solid $gray;
    border-top: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    display: block;
    left: 0;
    margin: 0;
    position: absolute;
    top: 4.15rem;
    visibility: visible;
    width: 100%;

    .fs-label {
      @extend %slab-bold;
      font-size: ms(1);
    }

    .fs-option,
    .fs-message {
      padding: .5rem .75rem;
    }

    &-content {
      max-height: 200px;
      overflow-x: hidden;
      overflow-y: auto;

      .fs-active,
      > div:not(.fs-message):hover {
        background: $pale-yellow;
      }
    }

    &-content > div {
      cursor: pointer;
    }
  }
}
