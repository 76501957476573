.subscription-plan {
  background-color: $light-gray;
  border: 3px solid transparent;
  transition: transform .2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &--selected {
    border: 3px solid $teal;
    transform: scale(1.05);
    z-index: 5;
  }

  .icon-subscription-type {
    height: ms(18);
    width: ms(18);
  }
}

.subscription-header {
  .icon-subscription-type {
    height: ms(20);
    width: ms(20);
  }
}

@media #{$breakpoint-medium} {
  .subscription-plan {
    padding-left: span(1 of 9) + gutter(9);
    padding-right: span(1 of 9) + gutter(9);
    width: span(9 of 9);

    .icon-subscription-type {
      height: ms(22);
      width: ms(22);
    }
  }

  .choose-subscription-plan {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 760px) {
  .subscription-header {
    .icon-subscription-type {
      height: ms(22);
      width: ms(22);
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1540px) {
  .subscription-header {
    .icon-subscription-type {
      height: ms(20);
      width: ms(20);
    }
  }
}

@media screen and (min-width: 1400px) {
  .choose-subscription-plan {
    flex-direction: row;
  }

  .subscription-plan {
    margin-bottom: 0;
    width: span(6 of 12);
  }

  .subscription-plan + .subscription-plan {
    margin-left: gutter(12);
  }
}

@media screen and (min-width: 1650px) {
  .subscription-plan {
    .icon-subscription-type {
      height: ms(22);
      width: ms(22);
    }
  }
}
