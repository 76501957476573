// <div class="spinner">
//   <div class="spinner-cube spinner-cube-1"></div>
//   <div class="spinner-cube spinner-cube-2"></div>
//   <div class="spinner-cube spinner-cube-4"></div>
//   <div class="spinner-cube spinner-cube-3"></div>
// </div>

.spinner {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;

    .spinner-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $dark-60;
            animation: fold-spinner 2.4s infinite linear both;
            transform-origin: 100% 100%;
        }
    }

    &--light .spinner-cube::before {
        background-color: $light-gray;
    }

    .spinner-cube-2 {
        transform: rotateZ(90deg);

        &::before { animation-delay: 0.3s; }
    }

    .spinner-cube-3 {
        transform: rotateZ(180deg);

        &::before { animation-delay: 0.6s; }
    }

    .spinner-cube-4 {
        transform: rotateZ(270deg);

        &::before { animation-delay: 0.9s; }
    }
}

@keyframes fold-spinner {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    } 25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    } 90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}