/*

  TEXT ALIGN
  Docs: http://tachyons.io/docs/typography/text-align/

  Base
    t = text-align

  Modifiers
    l = left
    r = right
    c = center
    j = justify

  Media Query Extensions:
    -ns = not-small
    -m  = medium
    -l  = large

*/

.tl  { text-align: left; }
.tr  { text-align: right; }
.tc  { text-align: center; }
.tj  { text-align: justify; }

@media #{$breakpoint-not-small} {
  .tl-ns  { text-align: left; }
  .tr-ns  { text-align: right; }
  .tc-ns  { text-align: center; }
  .tj-ns  { text-align: justify; }
}

@media #{$breakpoint-medium} {
  .tl-m  { text-align: left; }
  .tr-m  { text-align: right; }
  .tc-m  { text-align: center; }
  .tj-m  { text-align: justify; }
}

@media #{$breakpoint-large} {
  .tl-l  { text-align: left; }
  .tr-l  { text-align: right; }
  .tc-l  { text-align: center; }
  .tj-l  { text-align: justify; }
}

