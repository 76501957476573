.schive-mobile-menu {
  background-color: $dark;
  bottom: 0;
  display: block;
  left: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transform: translate3d(-300px, 0, 0);
  transition: transform 0.3s ease;
  width: 300px;
  z-index: 500;
}

.schive-mobile-menu ul > li {
  cursor: pointer;
  display: block;
}

.schive-mobile-menu ul > li:hover,
.schive-mobile-menu ul > li:active {
  background-color: $teal;
}

.schive-mobile-menu-show {
  transform: translate3d(0, 0, 0);
}
