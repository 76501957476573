.tos-nav {
  list-style: decimal inside none;

  li {
    list-style: inherit;

    > a {
      color: inherit;
      text-decoration: none;
    }
  }
}

@media #{$breakpoint-not-small} {
  .tos-nav {
    list-style: decimal outside none;
    margin-left: -0.2rem;

    li {
      padding-left: 0.2rem;
    }
  }
}
