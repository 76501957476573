.progress-circle {
  &,
  svg {
    display: block;
  }
}

.progress-circle circle {
  stroke-width: .15rem;
}

.progress-circle-max-height .progress-circle {
  max-height: 4rem;
}

.progress-circle-bg-white circle:nth-of-type(2n-1) {
  stroke: $white;
}

.progress-circle-wrapper {
  position: relative;

  .progress {
    color: $gray;
    line-height: 1;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    width: 100%;
  }
}
