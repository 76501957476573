.forum-shortcuts {
  margin-top: 2em;
  background: $white;
  bottom: 0;

  display: none;
  text-align: right;
  z-index: 50;

  &-stuck {
    width: 90%;
    left: 0;
    bottom: 0;
    position: fixed;
    padding: 0 5%;
  }

  li {
    float: right;
    margin: 0;

    &:first-of-type {
      margin-right: -.5em;
    }
  }
}

@include susy-breakpoint(min-width 60em) {
  .forum-shortcuts {
    background: none;
    width: span(2 of 9);
    padding: 0;
    float: right;
    margin-right: -(span(2 of 9) + gutter(9));
    margin-top: -7rem;

    &-stuck {
      left: auto;
      bottom: 1em;
      right: 2.5%;
      width: span(2 of 12);
      margin-right: 0;
    }

    a {
      padding: 0.5rem;
    }

    li,
    li:first-of-type {
      margin: 0 -0.5rem;
      float: none;
    }
  }
}

@media #{$breakpoint-large} {
  .forum-shortcuts-stuck {
    right: 10%;
  }
}
