@charset "UTF-8";
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

template,
[hidden] {
  display: none; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
select {
  text-transform: none; }

textarea {
  overflow: auto; }

[type="search"] {
  -webkit-appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/*

    BORDERS
    Docs: http://tachyons.io/docs/themes/borders/

    Base:
      b = border

    Modifiers:
      a = all
      t = top
      r = right
      b = bottom
      l = left
      n = none
*/
.ba {
  border-style: solid;
  border-width: 1px; }

.bt {
  border-top-style: solid;
  border-top-width: 1px; }

.br {
  border-right-style: solid;
  border-right-width: 1px; }

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px; }

.bl {
  border-left-style: solid;
  border-left-width: 1px; }

.bn {
  border-style: none;
  border-width: 0; }

/*

  BOX SIZING

*/
html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
a,
h1, h2, h3, h4, h5, h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
table,
td,
th,
tr,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"],
.border-box {
  box-sizing: border-box; }

/* Text colors */
.black {
  color: #19181a; }

.white {
  color: #fff; }

.black-60 {
  color: rgba(25, 24, 26, 0.6); }

.gray {
  color: #b7b8ae; }

.dark-gray {
  color: #5a6161; }

.light-gray {
  color: #f4f5f5; }

.gold {
  color: #db9e02; }

.red {
  color: #bd3236; }

.pale-red {
  color: #bb6c67; }

.teal {
  color: #017a73; }

.pale-teal {
  color: #77d0b2; }

.pale-teal-20 {
  color: rgba(119, 208, 178, 0.2); }

.yellow {
  color: #ffd34e; }

.pale-yellow {
  color: #ffecb4; }

.hover-opacity:hover,
.hover-opacity:focus {
  opacity: 0.5; }

.hover-black:hover,
.hover-black:focus {
  color: #19181a; }

.hover-white:hover,
.hover-white:focus {
  color: #fff; }

.hover-light-gray:hover,
.hover-light-gray:focus {
  color: #f4f5f5; }

.hover-red:hover,
.hover-red:focus {
  color: #bd3236; }

.hover-pale-red:hover,
.hover-pale-red:focus {
  color: #bb6c67; }

.link.red:hover {
  color: #bb6c67; }

/* Background colors */
.bg-black {
  background-color: #19181a; }

.bg-black-60 {
  background-color: rgba(25, 24, 26, 0.6); }

.bg-white {
  background-color: #fff; }

.bg-gray {
  background-color: #b7b8ae; }

.bg-dark-gray {
  background-color: #5a6161; }

.bg-light-gray {
  background-color: #f4f5f5; }

.bg-gold {
  background-color: #db9e02; }

.bg-teal {
  background-color: #017a73; }

.bg-pale-teal {
  background-color: #77d0b2; }

.bg-pale-teal-20 {
  background-color: rgba(119, 208, 178, 0.2); }

.bg-red {
  background-color: #bd3236; }

.bg-yellow {
  background-color: #ffd34e; }

.bg-pale-yellow {
  background-color: #ffecb4; }

.bg-fb-blue {
  background-color: #4267b2; }

.bg-hover-teal:hover {
  background-color: #017a73; }

/* Border colors */
.b--black {
  border-color: #19181a; }

.b--dark-gray {
  border-color: #5a6161; }

.b--gray {
  border-color: #b7b8ae; }

.b--light-gray {
  border-color: #f4f5f5; }

.b--pale-red {
  border-color: #bb6c67; }

.b--red {
  border-color: #bd3236; }

.b--teal {
  border-color: #017a73; }

/* Landing */
.bg-landing-teal {
  background-color: #297b8e; }

.bg-landing-pale-teal {
  background-color: #48aab7; }

.bg-landing-pale-teal-20 {
  background-color: #88c8d0; }

.bg-landing-red {
  background-color: #ca5b39; }

.bg-landing-yellow {
  background-color: #ff7550; }

.bg-landing-pale-yellow {
  background-color: #fff5d9; }

.landing-teal {
  color: #297b8e; }

.landing-pale-teal {
  color: #48aab7; }

.landing-pale-teal-20 {
  color: #88c8d0; }

.landing-red {
  color: #ca5b39; }

.landing-yellow {
  color: #ff7550; }

.landing-pale-yellow {
  color: #fff5d9; }

.b--landing-teal {
  border-color: #297b8e; }

.b--landing-pale-teal {
  border-color: #48aab7; }

.b--landing-pale-teal-20 {
  border-color: #88c8d0; }

.b--landing-red {
  border-color: #ca5b39; }

.b--landing-yellow {
  border-color: #ff7550; }

.b--landing-pale-yellow {
  border-color: #fff5d9; }

/*

   DISPLAY
   Docs: http://tachyons.io/docs/layout/display

   Base:
    d = display

   Modifiers:
    n     = none
    b     = block
    ib    = inline-block
    it    = inline-table
    t     = table
    tc    = table-cell
    t-row          = table-row
    t-columm       = table-column
    t-column-group = table-column-group

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.dn {
  display: none; }

.di {
  display: inline; }

.db {
  display: block; }

.dib {
  display: inline-block; }

.dit {
  display: inline-table; }

.dt {
  display: table; }

.dtc {
  display: table-cell; }

.dt-row {
  display: table-row; }

.dt-row-group {
  display: table-row-group; }

.dt-column {
  display: table-column; }

.dt-column-group {
  display: table-column-group; }

/*
  This will set table to full width and then
  all cells will be equal width
*/
.dt--fixed {
  table-layout: fixed;
  width: 100%; }

@media screen and (min-width: 48em) {
  .dn-ns {
    display: none; }
  .di-ns {
    display: inline; }
  .db-ns {
    display: block; }
  .dib-ns {
    display: inline-block; }
  .dit-ns {
    display: inline-table; }
  .dt-ns {
    display: table; }
  .dtc-ns {
    display: table-cell; }
  .dt-row-ns {
    display: table-row; }
  .dt-row-group-ns {
    display: table-row-group; }
  .dt-column-ns {
    display: table-column; }
  .dt-column-group-ns {
    display: table-column-group; }
  .dt--fixed-ns {
    table-layout: fixed;
    width: 100%; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .dn-m {
    display: none; }
  .di-m {
    display: inline; }
  .db-m {
    display: block; }
  .dib-m {
    display: inline-block; }
  .dit-m {
    display: inline-table; }
  .dt-m {
    display: table; }
  .dtc-m {
    display: table-cell; }
  .dt-row-m {
    display: table-row; }
  .dt-row-group-m {
    display: table-row-group; }
  .dt-column-m {
    display: table-column; }
  .dt-column-group-m {
    display: table-column-group; }
  .dt--fixed-m {
    table-layout: fixed;
    width: 100%; } }

@media screen and (min-width: 70em) {
  .dn-l {
    display: none; }
  .di-l {
    display: inline; }
  .db-l {
    display: block; }
  .dib-l {
    display: inline-block; }
  .dit-l {
    display: inline-table; }
  .dt-l {
    display: table; }
  .dtc-l {
    display: table-cell; }
  .dt-row-l {
    display: table-row; }
  .dt-row-group-l {
    display: table-row-group; }
  .dt-column-l {
    display: table-column; }
  .dt-column-group-l {
    display: table-column-group; }
  .dt--fixed-l {
    table-layout: fixed;
    width: 100%; } }

@media screen and (min-width: 92.5em) {
  .dn-xl {
    display: none; }
  .db-xl {
    display: block; } }

/*

  FLEXBOX

  Media Query Extensions:
   -ns = not-small
   -m  = medium
   -l  = large

*/
.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

/* 1. Fix for Chrome 44 bug.
 * https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  /* 1 */
  min-height: 0;
  /* 1 */ }

.flex-none {
  flex: none; }

.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-nowrap {
  flex-wrap: nowrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-center {
  align-items: center; }

.items-baseline {
  align-items: baseline; }

.items-stretch {
  align-items: stretch; }

.self-start {
  align-self: flex-start; }

.self-end {
  align-self: flex-end; }

.self-center {
  align-self: center; }

.self-baseline {
  align-self: baseline; }

.self-stretch {
  align-self: stretch; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.justify-around {
  justify-content: space-around; }

.content-start {
  align-content: flex-start; }

.content-end {
  align-content: flex-end; }

.content-center {
  align-content: center; }

.content-between {
  align-content: space-between; }

.content-around {
  align-content: space-around; }

.content-stretch {
  align-content: stretch; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-last {
  order: 99999; }

.flex-grow-0 {
  flex-grow: 0; }

.flex-grow-1 {
  flex-grow: 1; }

.flex-shrink-0 {
  flex-shrink: 0; }

.flex-shrink-1 {
  flex-shrink: 1; }

@media screen and (min-width: 48em) {
  .flex-ns {
    display: flex; }
  .inline-flex-ns {
    display: inline-flex; }
  .flex-auto-ns {
    flex: 1 1 auto;
    min-width: 0;
    /* 1 */
    min-height: 0;
    /* 1 */ }
  .flex-none-ns {
    flex: none; }
  .flex-column-ns {
    flex-direction: column; }
  .flex-row-ns {
    flex-direction: row; }
  .flex-wrap-ns {
    flex-wrap: wrap; }
  .flex-nowrap-ns {
    flex-wrap: nowrap; }
  .flex-wrap-reverse-ns {
    flex-wrap: wrap-reverse; }
  .flex-column-reverse-ns {
    flex-direction: column-reverse; }
  .flex-row-reverse-ns {
    flex-direction: row-reverse; }
  .items-start-ns {
    align-items: flex-start; }
  .items-end-ns {
    align-items: flex-end; }
  .items-center-ns {
    align-items: center; }
  .items-baseline-ns {
    align-items: baseline; }
  .items-stretch-ns {
    align-items: stretch; }
  .self-start-ns {
    align-self: flex-start; }
  .self-end-ns {
    align-self: flex-end; }
  .self-center-ns {
    align-self: center; }
  .self-baseline-ns {
    align-self: baseline; }
  .self-stretch-ns {
    align-self: stretch; }
  .justify-start-ns {
    justify-content: flex-start; }
  .justify-end-ns {
    justify-content: flex-end; }
  .justify-center-ns {
    justify-content: center; }
  .justify-between-ns {
    justify-content: space-between; }
  .justify-around-ns {
    justify-content: space-around; }
  .content-start-ns {
    align-content: flex-start; }
  .content-end-ns {
    align-content: flex-end; }
  .content-center-ns {
    align-content: center; }
  .content-between-ns {
    align-content: space-between; }
  .content-around-ns {
    align-content: space-around; }
  .content-stretch-ns {
    align-content: stretch; }
  .order-0-ns {
    order: 0; }
  .order-1-ns {
    order: 1; }
  .order-2-ns {
    order: 2; }
  .order-3-ns {
    order: 3; }
  .order-4-ns {
    order: 4; }
  .order-5-ns {
    order: 5; }
  .order-6-ns {
    order: 6; }
  .order-7-ns {
    order: 7; }
  .order-8-ns {
    order: 8; }
  .order-last-ns {
    order: 99999; }
  .flex-grow-0-ns {
    flex-grow: 0; }
  .flex-grow-1-ns {
    flex-grow: 1; }
  .flex-shrink-0-ns {
    flex-shrink: 0; }
  .flex-shrink-1-ns {
    flex-shrink: 1; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .flex-m {
    display: flex; }
  .inline-flex-m {
    display: inline-flex; }
  .flex-auto-m {
    flex: 1 1 auto;
    min-width: 0;
    /* 1 */
    min-height: 0;
    /* 1 */ }
  .flex-none-m {
    flex: none; }
  .flex-column-m {
    flex-direction: column; }
  .flex-row-m {
    flex-direction: row; }
  .flex-wrap-m {
    flex-wrap: wrap; }
  .flex-nowrap-m {
    flex-wrap: nowrap; }
  .flex-wrap-reverse-m {
    flex-wrap: wrap-reverse; }
  .flex-column-reverse-m {
    flex-direction: column-reverse; }
  .flex-row-reverse-m {
    flex-direction: row-reverse; }
  .items-start-m {
    align-items: flex-start; }
  .items-end-m {
    align-items: flex-end; }
  .items-center-m {
    align-items: center; }
  .items-baseline-m {
    align-items: baseline; }
  .items-stretch-m {
    align-items: stretch; }
  .self-start-m {
    align-self: flex-start; }
  .self-end-m {
    align-self: flex-end; }
  .self-center-m {
    align-self: center; }
  .self-baseline-m {
    align-self: baseline; }
  .self-stretch-m {
    align-self: stretch; }
  .justify-start-m {
    justify-content: flex-start; }
  .justify-end-m {
    justify-content: flex-end; }
  .justify-center-m {
    justify-content: center; }
  .justify-between-m {
    justify-content: space-between; }
  .justify-around-m {
    justify-content: space-around; }
  .content-start-m {
    align-content: flex-start; }
  .content-end-m {
    align-content: flex-end; }
  .content-center-m {
    align-content: center; }
  .content-between-m {
    align-content: space-between; }
  .content-around-m {
    align-content: space-around; }
  .content-stretch-m {
    align-content: stretch; }
  .order-0-m {
    order: 0; }
  .order-1-m {
    order: 1; }
  .order-2-m {
    order: 2; }
  .order-3-m {
    order: 3; }
  .order-4-m {
    order: 4; }
  .order-5-m {
    order: 5; }
  .order-6-m {
    order: 6; }
  .order-7-m {
    order: 7; }
  .order-8-m {
    order: 8; }
  .order-last-m {
    order: 99999; }
  .flex-grow-0-m {
    flex-grow: 0; }
  .flex-grow-1-m {
    flex-grow: 1; }
  .flex-shrink-0-m {
    flex-shrink: 0; }
  .flex-shrink-1-m {
    flex-shrink: 1; } }

@media screen and (min-width: 70em) {
  .flex-l {
    display: flex; }
  .inline-flex-l {
    display: inline-flex; }
  .flex-auto-l {
    flex: 1 1 auto;
    min-width: 0;
    /* 1 */
    min-height: 0;
    /* 1 */ }
  .flex-none-l {
    flex: none; }
  .flex-column-l {
    flex-direction: column; }
  .flex-row-l {
    flex-direction: row; }
  .flex-wrap-l {
    flex-wrap: wrap; }
  .flex-nowrap-l {
    flex-wrap: nowrap; }
  .flex-wrap-reverse-l {
    flex-wrap: wrap-reverse; }
  .flex-column-reverse-l {
    flex-direction: column-reverse; }
  .flex-row-reverse-l {
    flex-direction: row-reverse; }
  .items-start-l {
    align-items: flex-start; }
  .items-end-l {
    align-items: flex-end; }
  .items-center-l {
    align-items: center; }
  .items-baseline-l {
    align-items: baseline; }
  .items-stretch-l {
    align-items: stretch; }
  .self-start-l {
    align-self: flex-start; }
  .self-end-l {
    align-self: flex-end; }
  .self-center-l {
    align-self: center; }
  .self-baseline-l {
    align-self: baseline; }
  .self-stretch-l {
    align-self: stretch; }
  .justify-start-l {
    justify-content: flex-start; }
  .justify-end-l {
    justify-content: flex-end; }
  .justify-center-l {
    justify-content: center; }
  .justify-between-l {
    justify-content: space-between; }
  .justify-around-l {
    justify-content: space-around; }
  .content-start-l {
    align-content: flex-start; }
  .content-end-l {
    align-content: flex-end; }
  .content-center-l {
    align-content: center; }
  .content-between-l {
    align-content: space-between; }
  .content-around-l {
    align-content: space-around; }
  .content-stretch-l {
    align-content: stretch; }
  .order-0-l {
    order: 0; }
  .order-1-l {
    order: 1; }
  .order-2-l {
    order: 2; }
  .order-3-l {
    order: 3; }
  .order-4-l {
    order: 4; }
  .order-5-l {
    order: 5; }
  .order-6-l {
    order: 6; }
  .order-7-l {
    order: 7; }
  .order-8-l {
    order: 8; }
  .order-last-l {
    order: 99999; }
  .flex-grow-0-l {
    flex-grow: 0; }
  .flex-grow-1-l {
    flex-grow: 1; }
  .flex-shrink-0-l {
    flex-shrink: 0; }
  .flex-shrink-1-l {
    flex-shrink: 1; } }

/*

   FLOATS
   http://tachyons.io/docs/layout/floats/

   1. Floated elements are automatically rendered as block level elements.
      Setting floats to display inline will fix the double margin bug in
      ie6. You know... just in case.

   2. Don't forget to clearfix your floats with .cf

   Base:
     f = float

   Modifiers:
     l = left
     r = right
     n = none

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.cf:before,
.cf:after {
  content: " ";
  display: table; }

.cf:after {
  clear: both; }

.cf {
  *zoom: 1; }

.fl {
  float: left;
  _display: inline; }

.fr {
  float: right;
  _display: inline; }

.fn {
  float: none; }

@media screen and (min-width: 48em) {
  .fl-ns {
    float: left;
    _display: inline; }
  .fr-ns {
    float: right;
    _display: inline; }
  .fn-ns {
    float: none; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .fl-m {
    float: left;
    _display: inline; }
  .fr-m {
    float: right;
    _display: inline; }
  .fn-m {
    float: none; } }

@media screen and (min-width: 70em) {
  .fl-l {
    float: left;
    _display: inline; }
  .fr-l {
    float: right;
    _display: inline; }
  .fn-l {
    float: none; } }

.sk-checkbox {
  display: block;
  position: relative;
  min-height: 1.5rem;
  padding-left: 2rem;
  padding-top: 0.15rem; }
  .sk-checkbox input[type=checkbox] {
    left: 0.3rem;
    opacity: 0;
    position: absolute;
    top: 0.95rem;
    z-index: -1; }
    .sk-checkbox input[type=checkbox]:checked ~ label:before {
      color: #017a73; }
    .sk-checkbox input[type=checkbox]:checked ~ label:after {
      content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath fill='%23017a73' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }
    .sk-checkbox input[type=checkbox]:focus ~ label:before {
      outline: 1px solid #017a73; }
  .sk-checkbox label {
    margin-bottom: 0;
    display: inline-block; }
    .sk-checkbox label:before {
      background-color: #fff;
      border: 1px solid #b7b8ae;
      content: '';
      display: block;
      height: 1.25rem;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: .25rem;
      user-select: none;
      width: 1.25rem; }
    .sk-checkbox label:after {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 50% 50%;
      content: '';
      display: block;
      height: 1.25rem;
      left: 0.18rem;
      position: absolute;
      top: .45rem;
      width: 1.25rem; }

.sk-input-group {
  position: relative; }
  .sk-input-group input[type=text],
  .sk-input-group input[type=email],
  .sk-input-group input[type=password] {
    background-color: #fff;
    border: 1px solid #b7b8ae;
    font-family: "adelle-sans", sans-serif;
    font-size: 1rem;
    min-height: 2rem;
    outline: none;
    padding: .75rem 1rem;
    vertical-align: middle; }
  .sk-input-group label {
    color: #5a6161;
    display: inline-block;
    font-family: "adelle-sans", sans-serif;
    font-size: 1rem;
    left: 1rem;
    pointer-events: none;
    position: absolute;
    top: 0.75rem;
    transition: all 0.2s ease-out;
    z-index: 1; }
  .sk-input-group input:focus ~ label,
  .sk-input-group input:not([value='']) ~ label {
    top: -.75rem;
    left: .5rem;
    font-size: 0.88rem;
    padding: 0 .5rem; }
  .sk-input-group input:not([value='']) ~ label {
    background-color: #fff;
    color: #19181a; }
  .sk-input-group input:focus ~ label {
    background-color: #fff;
    color: #017a73; }
  .sk-input-group .errorlist {
    margin-bottom: 1.5625rem; }

.col-1-9 {
  width: 9.09091%; }

.col-1-9-g {
  width: 11.36364%; }

.push-1-9 {
  margin-left: 9.09091%; }

.pull-1-9 {
  margin-right: 9.09091%; }

.col-2-9 {
  width: 20.45455%; }

.col-2-9-g {
  width: 22.72727%; }

.push-2-9 {
  margin-left: 20.45455%; }

.pull-2-9 {
  margin-right: 20.45455%; }

.col-3-9 {
  width: 31.81818%; }

.col-3-9-g {
  width: 34.09091%; }

.push-3-9 {
  margin-left: 31.81818%; }

.pull-3-9 {
  margin-right: 31.81818%; }

.col-4-9 {
  width: 43.18182%; }

.col-4-9-g {
  width: 45.45455%; }

.push-4-9 {
  margin-left: 43.18182%; }

.pull-4-9 {
  margin-right: 43.18182%; }

.col-5-9 {
  width: 54.54545%; }

.col-5-9-g {
  width: 56.81818%; }

.push-5-9 {
  margin-left: 54.54545%; }

.pull-5-9 {
  margin-right: 54.54545%; }

.col-6-9 {
  width: 65.90909%; }

.col-6-9-g {
  width: 68.18182%; }

.push-6-9 {
  margin-left: 65.90909%; }

.pull-6-9 {
  margin-right: 65.90909%; }

.col-7-9 {
  width: 77.27273%; }

.col-7-9-g {
  width: 79.54545%; }

.push-7-9 {
  margin-left: 77.27273%; }

.pull-7-9 {
  margin-right: 77.27273%; }

.col-8-9 {
  width: 88.63636%; }

.col-8-9-g {
  width: 90.90909%; }

.push-8-9 {
  margin-left: 88.63636%; }

.pull-8-9 {
  margin-right: 88.63636%; }

.col-9-9 {
  width: 100%; }

.col-9-9-g {
  width: 102.27273%; }

.push-9-9 {
  margin-left: 100%; }

.pull-9-9 {
  margin-right: 100%; }

.push-0-9-g {
  margin-left: 2.27273%; }

.pull-0-9-g {
  margin-right: 2.27273%; }

.push-1-9-g {
  margin-left: 11.36364%; }

.pull-1-9-g {
  margin-right: 11.36364%; }

.push-2-9-g {
  margin-left: 22.72727%; }

.pull-2-9-g {
  margin-right: 22.72727%; }

.push-3-9-g {
  margin-left: 34.09091%; }

.pull-3-9-g {
  margin-right: 34.09091%; }

.push-4-9-g {
  margin-left: 45.45455%; }

.pull-4-9-g {
  margin-right: 45.45455%; }

.push-5-9-g {
  margin-left: 56.81818%; }

.pull-5-9-g {
  margin-right: 56.81818%; }

.push-6-9-g {
  margin-left: 68.18182%; }

.pull-6-9-g {
  margin-right: 68.18182%; }

.push-7-9-g {
  margin-left: 79.54545%; }

.pull-7-9-g {
  margin-right: 79.54545%; }

.push-8-9-g {
  margin-left: 90.90909%; }

.pull-8-9-g {
  margin-right: 90.90909%; }

.push-9-9-g {
  margin-left: 102.27273%; }

.pull-9-9-g {
  margin-right: 102.27273%; }

.push-g-9 {
  margin-left: 4.54545%; }

.pull-g-9 {
  margin-right: 4.54545%; }

.col-1-12 {
  width: 6.77966%; }

.col-1-12-g {
  width: 8.47458%; }

.push-1-12 {
  margin-left: 6.77966%; }

.pull-1-12 {
  margin-right: 6.77966%; }

.col-2-12 {
  width: 15.25424%; }

.col-2-12-g {
  width: 16.94915%; }

.push-2-12 {
  margin-left: 15.25424%; }

.pull-2-12 {
  margin-right: 15.25424%; }

.col-3-12 {
  width: 23.72881%; }

.col-3-12-g {
  width: 25.42373%; }

.push-3-12 {
  margin-left: 23.72881%; }

.pull-3-12 {
  margin-right: 23.72881%; }

.col-4-12 {
  width: 32.20339%; }

.col-4-12-g {
  width: 33.89831%; }

.push-4-12 {
  margin-left: 32.20339%; }

.pull-4-12 {
  margin-right: 32.20339%; }

.col-5-12 {
  width: 40.67797%; }

.col-5-12-g {
  width: 42.37288%; }

.push-5-12 {
  margin-left: 40.67797%; }

.pull-5-12 {
  margin-right: 40.67797%; }

.col-6-12 {
  width: 49.15254%; }

.col-6-12-g {
  width: 50.84746%; }

.push-6-12 {
  margin-left: 49.15254%; }

.pull-6-12 {
  margin-right: 49.15254%; }

.col-7-12 {
  width: 57.62712%; }

.col-7-12-g {
  width: 59.32203%; }

.push-7-12 {
  margin-left: 57.62712%; }

.pull-7-12 {
  margin-right: 57.62712%; }

.col-8-12 {
  width: 66.10169%; }

.col-8-12-g {
  width: 67.79661%; }

.push-8-12 {
  margin-left: 66.10169%; }

.pull-8-12 {
  margin-right: 66.10169%; }

.col-9-12 {
  width: 74.57627%; }

.col-9-12-g {
  width: 76.27119%; }

.push-9-12 {
  margin-left: 74.57627%; }

.pull-9-12 {
  margin-right: 74.57627%; }

.col-10-12 {
  width: 83.05085%; }

.col-10-12-g {
  width: 84.74576%; }

.push-10-12 {
  margin-left: 83.05085%; }

.pull-10-12 {
  margin-right: 83.05085%; }

.col-11-12 {
  width: 91.52542%; }

.col-11-12-g {
  width: 93.22034%; }

.push-11-12 {
  margin-left: 91.52542%; }

.pull-11-12 {
  margin-right: 91.52542%; }

.col-12-12 {
  width: 100%; }

.col-12-12-g {
  width: 101.69492%; }

.push-12-12 {
  margin-left: 100%; }

.pull-12-12 {
  margin-right: 100%; }

.push-0-12-g {
  margin-left: 1.69492%; }

.pull-0-12-g {
  margin-right: 1.69492%; }

.push-1-12-g {
  margin-left: 8.47458%; }

.pull-1-12-g {
  margin-right: 8.47458%; }

.push-2-12-g {
  margin-left: 16.94915%; }

.pull-2-12-g {
  margin-right: 16.94915%; }

.push-3-12-g {
  margin-left: 25.42373%; }

.pull-3-12-g {
  margin-right: 25.42373%; }

.push-4-12-g {
  margin-left: 33.89831%; }

.pull-4-12-g {
  margin-right: 33.89831%; }

.push-5-12-g {
  margin-left: 42.37288%; }

.pull-5-12-g {
  margin-right: 42.37288%; }

.push-6-12-g {
  margin-left: 50.84746%; }

.pull-6-12-g {
  margin-right: 50.84746%; }

.push-7-12-g {
  margin-left: 59.32203%; }

.pull-7-12-g {
  margin-right: 59.32203%; }

.push-8-12-g {
  margin-left: 67.79661%; }

.pull-8-12-g {
  margin-right: 67.79661%; }

.push-9-12-g {
  margin-left: 76.27119%; }

.pull-9-12-g {
  margin-right: 76.27119%; }

.push-10-12-g {
  margin-left: 84.74576%; }

.pull-10-12-g {
  margin-right: 84.74576%; }

.push-11-12-g {
  margin-left: 93.22034%; }

.pull-11-12-g {
  margin-right: 93.22034%; }

.push-12-12-g {
  margin-left: 101.69492%; }

.pull-12-12-g {
  margin-right: 101.69492%; }

.push-g-12 {
  margin-left: 3.38983%; }

.pull-g-12 {
  margin-right: 3.38983%; }

.col-1-16 {
  width: 5.06329%; }

.col-1-16-g {
  width: 6.32911%; }

.push-1-16 {
  margin-left: 5.06329%; }

.pull-1-16 {
  margin-right: 5.06329%; }

.col-2-16 {
  width: 11.39241%; }

.col-2-16-g {
  width: 12.65823%; }

.push-2-16 {
  margin-left: 11.39241%; }

.pull-2-16 {
  margin-right: 11.39241%; }

.col-3-16 {
  width: 17.72152%; }

.col-3-16-g {
  width: 18.98734%; }

.push-3-16 {
  margin-left: 17.72152%; }

.pull-3-16 {
  margin-right: 17.72152%; }

.col-4-16 {
  width: 24.05063%; }

.col-4-16-g {
  width: 25.31646%; }

.push-4-16 {
  margin-left: 24.05063%; }

.pull-4-16 {
  margin-right: 24.05063%; }

.col-5-16 {
  width: 30.37975%; }

.col-5-16-g {
  width: 31.64557%; }

.push-5-16 {
  margin-left: 30.37975%; }

.pull-5-16 {
  margin-right: 30.37975%; }

.col-6-16 {
  width: 36.70886%; }

.col-6-16-g {
  width: 37.97468%; }

.push-6-16 {
  margin-left: 36.70886%; }

.pull-6-16 {
  margin-right: 36.70886%; }

.col-7-16 {
  width: 43.03797%; }

.col-7-16-g {
  width: 44.3038%; }

.push-7-16 {
  margin-left: 43.03797%; }

.pull-7-16 {
  margin-right: 43.03797%; }

.col-8-16 {
  width: 49.36709%; }

.col-8-16-g {
  width: 50.63291%; }

.push-8-16 {
  margin-left: 49.36709%; }

.pull-8-16 {
  margin-right: 49.36709%; }

.col-9-16 {
  width: 55.6962%; }

.col-9-16-g {
  width: 56.96203%; }

.push-9-16 {
  margin-left: 55.6962%; }

.pull-9-16 {
  margin-right: 55.6962%; }

.col-10-16 {
  width: 62.02532%; }

.col-10-16-g {
  width: 63.29114%; }

.push-10-16 {
  margin-left: 62.02532%; }

.pull-10-16 {
  margin-right: 62.02532%; }

.col-11-16 {
  width: 68.35443%; }

.col-11-16-g {
  width: 69.62025%; }

.push-11-16 {
  margin-left: 68.35443%; }

.pull-11-16 {
  margin-right: 68.35443%; }

.col-12-16 {
  width: 74.68354%; }

.col-12-16-g {
  width: 75.94937%; }

.push-12-16 {
  margin-left: 74.68354%; }

.pull-12-16 {
  margin-right: 74.68354%; }

.col-13-16 {
  width: 81.01266%; }

.col-13-16-g {
  width: 82.27848%; }

.push-13-16 {
  margin-left: 81.01266%; }

.pull-13-16 {
  margin-right: 81.01266%; }

.col-14-16 {
  width: 87.34177%; }

.col-14-16-g {
  width: 88.60759%; }

.push-14-16 {
  margin-left: 87.34177%; }

.pull-14-16 {
  margin-right: 87.34177%; }

.col-15-16 {
  width: 93.67089%; }

.col-15-16-g {
  width: 94.93671%; }

.push-15-16 {
  margin-left: 93.67089%; }

.pull-15-16 {
  margin-right: 93.67089%; }

.col-16-16 {
  width: 100%; }

.col-16-16-g {
  width: 101.26582%; }

.push-16-16 {
  margin-left: 100%; }

.pull-16-16 {
  margin-right: 100%; }

.push-0-16-g {
  margin-left: 1.26582%; }

.pull-0-16-g {
  margin-right: 1.26582%; }

.push-1-16-g {
  margin-left: 6.32911%; }

.pull-1-16-g {
  margin-right: 6.32911%; }

.push-2-16-g {
  margin-left: 12.65823%; }

.pull-2-16-g {
  margin-right: 12.65823%; }

.push-3-16-g {
  margin-left: 18.98734%; }

.pull-3-16-g {
  margin-right: 18.98734%; }

.push-4-16-g {
  margin-left: 25.31646%; }

.pull-4-16-g {
  margin-right: 25.31646%; }

.push-5-16-g {
  margin-left: 31.64557%; }

.pull-5-16-g {
  margin-right: 31.64557%; }

.push-6-16-g {
  margin-left: 37.97468%; }

.pull-6-16-g {
  margin-right: 37.97468%; }

.push-7-16-g {
  margin-left: 44.3038%; }

.pull-7-16-g {
  margin-right: 44.3038%; }

.push-8-16-g {
  margin-left: 50.63291%; }

.pull-8-16-g {
  margin-right: 50.63291%; }

.push-9-16-g {
  margin-left: 56.96203%; }

.pull-9-16-g {
  margin-right: 56.96203%; }

.push-10-16-g {
  margin-left: 63.29114%; }

.pull-10-16-g {
  margin-right: 63.29114%; }

.push-11-16-g {
  margin-left: 69.62025%; }

.pull-11-16-g {
  margin-right: 69.62025%; }

.push-12-16-g {
  margin-left: 75.94937%; }

.pull-12-16-g {
  margin-right: 75.94937%; }

.push-13-16-g {
  margin-left: 82.27848%; }

.pull-13-16-g {
  margin-right: 82.27848%; }

.push-14-16-g {
  margin-left: 88.60759%; }

.pull-14-16-g {
  margin-right: 88.60759%; }

.push-15-16-g {
  margin-left: 94.93671%; }

.pull-15-16-g {
  margin-right: 94.93671%; }

.push-16-16-g {
  margin-left: 101.26582%; }

.pull-16-16-g {
  margin-right: 101.26582%; }

.push-g-16 {
  margin-left: 2.53165%; }

.pull-g-16 {
  margin-right: 2.53165%; }

@media screen and (min-width: 48em) {
  .col-1-9-ns {
    width: 9.09091%; }
  .col-1-9-g-ns {
    width: 11.36364%; }
  .push-1-9-ns {
    margin-left: 9.09091%; }
  .pull-1-9-ns {
    margin-right: 9.09091%; }
  .col-2-9-ns {
    width: 20.45455%; }
  .col-2-9-g-ns {
    width: 22.72727%; }
  .push-2-9-ns {
    margin-left: 20.45455%; }
  .pull-2-9-ns {
    margin-right: 20.45455%; }
  .col-3-9-ns {
    width: 31.81818%; }
  .col-3-9-g-ns {
    width: 34.09091%; }
  .push-3-9-ns {
    margin-left: 31.81818%; }
  .pull-3-9-ns {
    margin-right: 31.81818%; }
  .col-4-9-ns {
    width: 43.18182%; }
  .col-4-9-g-ns {
    width: 45.45455%; }
  .push-4-9-ns {
    margin-left: 43.18182%; }
  .pull-4-9-ns {
    margin-right: 43.18182%; }
  .col-5-9-ns {
    width: 54.54545%; }
  .col-5-9-g-ns {
    width: 56.81818%; }
  .push-5-9-ns {
    margin-left: 54.54545%; }
  .pull-5-9-ns {
    margin-right: 54.54545%; }
  .col-6-9-ns {
    width: 65.90909%; }
  .col-6-9-g-ns {
    width: 68.18182%; }
  .push-6-9-ns {
    margin-left: 65.90909%; }
  .pull-6-9-ns {
    margin-right: 65.90909%; }
  .col-7-9-ns {
    width: 77.27273%; }
  .col-7-9-g-ns {
    width: 79.54545%; }
  .push-7-9-ns {
    margin-left: 77.27273%; }
  .pull-7-9-ns {
    margin-right: 77.27273%; }
  .col-8-9-ns {
    width: 88.63636%; }
  .col-8-9-g-ns {
    width: 90.90909%; }
  .push-8-9-ns {
    margin-left: 88.63636%; }
  .pull-8-9-ns {
    margin-right: 88.63636%; }
  .col-9-9-ns {
    width: 100%; }
  .col-9-9-g-ns {
    width: 102.27273%; }
  .push-9-9-ns {
    margin-left: 100%; }
  .pull-9-9-ns {
    margin-right: 100%; }
  .push-0-9-g-ns {
    margin-left: 2.27273%; }
  .pull-0-9-g-ns {
    margin-right: 2.27273%; }
  .push-1-9-g-ns {
    margin-left: 11.36364%; }
  .pull-1-9-g-ns {
    margin-right: 11.36364%; }
  .push-2-9-g-ns {
    margin-left: 22.72727%; }
  .pull-2-9-g-ns {
    margin-right: 22.72727%; }
  .push-3-9-g-ns {
    margin-left: 34.09091%; }
  .pull-3-9-g-ns {
    margin-right: 34.09091%; }
  .push-4-9-g-ns {
    margin-left: 45.45455%; }
  .pull-4-9-g-ns {
    margin-right: 45.45455%; }
  .push-5-9-g-ns {
    margin-left: 56.81818%; }
  .pull-5-9-g-ns {
    margin-right: 56.81818%; }
  .push-6-9-g-ns {
    margin-left: 68.18182%; }
  .pull-6-9-g-ns {
    margin-right: 68.18182%; }
  .push-7-9-g-ns {
    margin-left: 79.54545%; }
  .pull-7-9-g-ns {
    margin-right: 79.54545%; }
  .push-8-9-g-ns {
    margin-left: 90.90909%; }
  .pull-8-9-g-ns {
    margin-right: 90.90909%; }
  .push-9-9-g-ns {
    margin-left: 102.27273%; }
  .pull-9-9-g-ns {
    margin-right: 102.27273%; }
  .push-g-9-ns {
    margin-left: 4.54545%; }
  .pull-g-9-ns {
    margin-right: 4.54545%; }
  .col-1-12-ns {
    width: 6.77966%; }
  .col-1-12-g-ns {
    width: 8.47458%; }
  .push-1-12-ns {
    margin-left: 6.77966%; }
  .pull-1-12-ns {
    margin-right: 6.77966%; }
  .col-2-12-ns {
    width: 15.25424%; }
  .col-2-12-g-ns {
    width: 16.94915%; }
  .push-2-12-ns {
    margin-left: 15.25424%; }
  .pull-2-12-ns {
    margin-right: 15.25424%; }
  .col-3-12-ns {
    width: 23.72881%; }
  .col-3-12-g-ns {
    width: 25.42373%; }
  .push-3-12-ns {
    margin-left: 23.72881%; }
  .pull-3-12-ns {
    margin-right: 23.72881%; }
  .col-4-12-ns {
    width: 32.20339%; }
  .col-4-12-g-ns {
    width: 33.89831%; }
  .push-4-12-ns {
    margin-left: 32.20339%; }
  .pull-4-12-ns {
    margin-right: 32.20339%; }
  .col-5-12-ns {
    width: 40.67797%; }
  .col-5-12-g-ns {
    width: 42.37288%; }
  .push-5-12-ns {
    margin-left: 40.67797%; }
  .pull-5-12-ns {
    margin-right: 40.67797%; }
  .col-6-12-ns {
    width: 49.15254%; }
  .col-6-12-g-ns {
    width: 50.84746%; }
  .push-6-12-ns {
    margin-left: 49.15254%; }
  .pull-6-12-ns {
    margin-right: 49.15254%; }
  .col-7-12-ns {
    width: 57.62712%; }
  .col-7-12-g-ns {
    width: 59.32203%; }
  .push-7-12-ns {
    margin-left: 57.62712%; }
  .pull-7-12-ns {
    margin-right: 57.62712%; }
  .col-8-12-ns {
    width: 66.10169%; }
  .col-8-12-g-ns {
    width: 67.79661%; }
  .push-8-12-ns {
    margin-left: 66.10169%; }
  .pull-8-12-ns {
    margin-right: 66.10169%; }
  .col-9-12-ns {
    width: 74.57627%; }
  .col-9-12-g-ns {
    width: 76.27119%; }
  .push-9-12-ns {
    margin-left: 74.57627%; }
  .pull-9-12-ns {
    margin-right: 74.57627%; }
  .col-10-12-ns {
    width: 83.05085%; }
  .col-10-12-g-ns {
    width: 84.74576%; }
  .push-10-12-ns {
    margin-left: 83.05085%; }
  .pull-10-12-ns {
    margin-right: 83.05085%; }
  .col-11-12-ns {
    width: 91.52542%; }
  .col-11-12-g-ns {
    width: 93.22034%; }
  .push-11-12-ns {
    margin-left: 91.52542%; }
  .pull-11-12-ns {
    margin-right: 91.52542%; }
  .col-12-12-ns {
    width: 100%; }
  .col-12-12-g-ns {
    width: 101.69492%; }
  .push-12-12-ns {
    margin-left: 100%; }
  .pull-12-12-ns {
    margin-right: 100%; }
  .push-0-12-g-ns {
    margin-left: 1.69492%; }
  .pull-0-12-g-ns {
    margin-right: 1.69492%; }
  .push-1-12-g-ns {
    margin-left: 8.47458%; }
  .pull-1-12-g-ns {
    margin-right: 8.47458%; }
  .push-2-12-g-ns {
    margin-left: 16.94915%; }
  .pull-2-12-g-ns {
    margin-right: 16.94915%; }
  .push-3-12-g-ns {
    margin-left: 25.42373%; }
  .pull-3-12-g-ns {
    margin-right: 25.42373%; }
  .push-4-12-g-ns {
    margin-left: 33.89831%; }
  .pull-4-12-g-ns {
    margin-right: 33.89831%; }
  .push-5-12-g-ns {
    margin-left: 42.37288%; }
  .pull-5-12-g-ns {
    margin-right: 42.37288%; }
  .push-6-12-g-ns {
    margin-left: 50.84746%; }
  .pull-6-12-g-ns {
    margin-right: 50.84746%; }
  .push-7-12-g-ns {
    margin-left: 59.32203%; }
  .pull-7-12-g-ns {
    margin-right: 59.32203%; }
  .push-8-12-g-ns {
    margin-left: 67.79661%; }
  .pull-8-12-g-ns {
    margin-right: 67.79661%; }
  .push-9-12-g-ns {
    margin-left: 76.27119%; }
  .pull-9-12-g-ns {
    margin-right: 76.27119%; }
  .push-10-12-g-ns {
    margin-left: 84.74576%; }
  .pull-10-12-g-ns {
    margin-right: 84.74576%; }
  .push-11-12-g-ns {
    margin-left: 93.22034%; }
  .pull-11-12-g-ns {
    margin-right: 93.22034%; }
  .push-12-12-g-ns {
    margin-left: 101.69492%; }
  .pull-12-12-g-ns {
    margin-right: 101.69492%; }
  .push-g-12-ns {
    margin-left: 3.38983%; }
  .pull-g-12-ns {
    margin-right: 3.38983%; }
  .col-1-16-ns {
    width: 5.06329%; }
  .col-1-16-g-ns {
    width: 6.32911%; }
  .push-1-16-ns {
    margin-left: 5.06329%; }
  .pull-1-16-ns {
    margin-right: 5.06329%; }
  .col-2-16-ns {
    width: 11.39241%; }
  .col-2-16-g-ns {
    width: 12.65823%; }
  .push-2-16-ns {
    margin-left: 11.39241%; }
  .pull-2-16-ns {
    margin-right: 11.39241%; }
  .col-3-16-ns {
    width: 17.72152%; }
  .col-3-16-g-ns {
    width: 18.98734%; }
  .push-3-16-ns {
    margin-left: 17.72152%; }
  .pull-3-16-ns {
    margin-right: 17.72152%; }
  .col-4-16-ns {
    width: 24.05063%; }
  .col-4-16-g-ns {
    width: 25.31646%; }
  .push-4-16-ns {
    margin-left: 24.05063%; }
  .pull-4-16-ns {
    margin-right: 24.05063%; }
  .col-5-16-ns {
    width: 30.37975%; }
  .col-5-16-g-ns {
    width: 31.64557%; }
  .push-5-16-ns {
    margin-left: 30.37975%; }
  .pull-5-16-ns {
    margin-right: 30.37975%; }
  .col-6-16-ns {
    width: 36.70886%; }
  .col-6-16-g-ns {
    width: 37.97468%; }
  .push-6-16-ns {
    margin-left: 36.70886%; }
  .pull-6-16-ns {
    margin-right: 36.70886%; }
  .col-7-16-ns {
    width: 43.03797%; }
  .col-7-16-g-ns {
    width: 44.3038%; }
  .push-7-16-ns {
    margin-left: 43.03797%; }
  .pull-7-16-ns {
    margin-right: 43.03797%; }
  .col-8-16-ns {
    width: 49.36709%; }
  .col-8-16-g-ns {
    width: 50.63291%; }
  .push-8-16-ns {
    margin-left: 49.36709%; }
  .pull-8-16-ns {
    margin-right: 49.36709%; }
  .col-9-16-ns {
    width: 55.6962%; }
  .col-9-16-g-ns {
    width: 56.96203%; }
  .push-9-16-ns {
    margin-left: 55.6962%; }
  .pull-9-16-ns {
    margin-right: 55.6962%; }
  .col-10-16-ns {
    width: 62.02532%; }
  .col-10-16-g-ns {
    width: 63.29114%; }
  .push-10-16-ns {
    margin-left: 62.02532%; }
  .pull-10-16-ns {
    margin-right: 62.02532%; }
  .col-11-16-ns {
    width: 68.35443%; }
  .col-11-16-g-ns {
    width: 69.62025%; }
  .push-11-16-ns {
    margin-left: 68.35443%; }
  .pull-11-16-ns {
    margin-right: 68.35443%; }
  .col-12-16-ns {
    width: 74.68354%; }
  .col-12-16-g-ns {
    width: 75.94937%; }
  .push-12-16-ns {
    margin-left: 74.68354%; }
  .pull-12-16-ns {
    margin-right: 74.68354%; }
  .col-13-16-ns {
    width: 81.01266%; }
  .col-13-16-g-ns {
    width: 82.27848%; }
  .push-13-16-ns {
    margin-left: 81.01266%; }
  .pull-13-16-ns {
    margin-right: 81.01266%; }
  .col-14-16-ns {
    width: 87.34177%; }
  .col-14-16-g-ns {
    width: 88.60759%; }
  .push-14-16-ns {
    margin-left: 87.34177%; }
  .pull-14-16-ns {
    margin-right: 87.34177%; }
  .col-15-16-ns {
    width: 93.67089%; }
  .col-15-16-g-ns {
    width: 94.93671%; }
  .push-15-16-ns {
    margin-left: 93.67089%; }
  .pull-15-16-ns {
    margin-right: 93.67089%; }
  .col-16-16-ns {
    width: 100%; }
  .col-16-16-g-ns {
    width: 101.26582%; }
  .push-16-16-ns {
    margin-left: 100%; }
  .pull-16-16-ns {
    margin-right: 100%; }
  .push-0-16-g-ns {
    margin-left: 1.26582%; }
  .pull-0-16-g-ns {
    margin-right: 1.26582%; }
  .push-1-16-g-ns {
    margin-left: 6.32911%; }
  .pull-1-16-g-ns {
    margin-right: 6.32911%; }
  .push-2-16-g-ns {
    margin-left: 12.65823%; }
  .pull-2-16-g-ns {
    margin-right: 12.65823%; }
  .push-3-16-g-ns {
    margin-left: 18.98734%; }
  .pull-3-16-g-ns {
    margin-right: 18.98734%; }
  .push-4-16-g-ns {
    margin-left: 25.31646%; }
  .pull-4-16-g-ns {
    margin-right: 25.31646%; }
  .push-5-16-g-ns {
    margin-left: 31.64557%; }
  .pull-5-16-g-ns {
    margin-right: 31.64557%; }
  .push-6-16-g-ns {
    margin-left: 37.97468%; }
  .pull-6-16-g-ns {
    margin-right: 37.97468%; }
  .push-7-16-g-ns {
    margin-left: 44.3038%; }
  .pull-7-16-g-ns {
    margin-right: 44.3038%; }
  .push-8-16-g-ns {
    margin-left: 50.63291%; }
  .pull-8-16-g-ns {
    margin-right: 50.63291%; }
  .push-9-16-g-ns {
    margin-left: 56.96203%; }
  .pull-9-16-g-ns {
    margin-right: 56.96203%; }
  .push-10-16-g-ns {
    margin-left: 63.29114%; }
  .pull-10-16-g-ns {
    margin-right: 63.29114%; }
  .push-11-16-g-ns {
    margin-left: 69.62025%; }
  .pull-11-16-g-ns {
    margin-right: 69.62025%; }
  .push-12-16-g-ns {
    margin-left: 75.94937%; }
  .pull-12-16-g-ns {
    margin-right: 75.94937%; }
  .push-13-16-g-ns {
    margin-left: 82.27848%; }
  .pull-13-16-g-ns {
    margin-right: 82.27848%; }
  .push-14-16-g-ns {
    margin-left: 88.60759%; }
  .pull-14-16-g-ns {
    margin-right: 88.60759%; }
  .push-15-16-g-ns {
    margin-left: 94.93671%; }
  .pull-15-16-g-ns {
    margin-right: 94.93671%; }
  .push-16-16-g-ns {
    margin-left: 101.26582%; }
  .pull-16-16-g-ns {
    margin-right: 101.26582%; }
  .push-g-16-ns {
    margin-left: 2.53165%; }
  .pull-g-16-ns {
    margin-right: 2.53165%; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .col-1-9-m {
    width: 9.09091%; }
  .col-1-9-g-m {
    width: 11.36364%; }
  .push-1-9-m {
    margin-left: 9.09091%; }
  .pull-1-9-m {
    margin-right: 9.09091%; }
  .col-2-9-m {
    width: 20.45455%; }
  .col-2-9-g-m {
    width: 22.72727%; }
  .push-2-9-m {
    margin-left: 20.45455%; }
  .pull-2-9-m {
    margin-right: 20.45455%; }
  .col-3-9-m {
    width: 31.81818%; }
  .col-3-9-g-m {
    width: 34.09091%; }
  .push-3-9-m {
    margin-left: 31.81818%; }
  .pull-3-9-m {
    margin-right: 31.81818%; }
  .col-4-9-m {
    width: 43.18182%; }
  .col-4-9-g-m {
    width: 45.45455%; }
  .push-4-9-m {
    margin-left: 43.18182%; }
  .pull-4-9-m {
    margin-right: 43.18182%; }
  .col-5-9-m {
    width: 54.54545%; }
  .col-5-9-g-m {
    width: 56.81818%; }
  .push-5-9-m {
    margin-left: 54.54545%; }
  .pull-5-9-m {
    margin-right: 54.54545%; }
  .col-6-9-m {
    width: 65.90909%; }
  .col-6-9-g-m {
    width: 68.18182%; }
  .push-6-9-m {
    margin-left: 65.90909%; }
  .pull-6-9-m {
    margin-right: 65.90909%; }
  .col-7-9-m {
    width: 77.27273%; }
  .col-7-9-g-m {
    width: 79.54545%; }
  .push-7-9-m {
    margin-left: 77.27273%; }
  .pull-7-9-m {
    margin-right: 77.27273%; }
  .col-8-9-m {
    width: 88.63636%; }
  .col-8-9-g-m {
    width: 90.90909%; }
  .push-8-9-m {
    margin-left: 88.63636%; }
  .pull-8-9-m {
    margin-right: 88.63636%; }
  .col-9-9-m {
    width: 100%; }
  .col-9-9-g-m {
    width: 102.27273%; }
  .push-9-9-m {
    margin-left: 100%; }
  .pull-9-9-m {
    margin-right: 100%; }
  .push-0-9-g-m {
    margin-left: 2.27273%; }
  .pull-0-9-g-m {
    margin-right: 2.27273%; }
  .push-1-9-g-m {
    margin-left: 11.36364%; }
  .pull-1-9-g-m {
    margin-right: 11.36364%; }
  .push-2-9-g-m {
    margin-left: 22.72727%; }
  .pull-2-9-g-m {
    margin-right: 22.72727%; }
  .push-3-9-g-m {
    margin-left: 34.09091%; }
  .pull-3-9-g-m {
    margin-right: 34.09091%; }
  .push-4-9-g-m {
    margin-left: 45.45455%; }
  .pull-4-9-g-m {
    margin-right: 45.45455%; }
  .push-5-9-g-m {
    margin-left: 56.81818%; }
  .pull-5-9-g-m {
    margin-right: 56.81818%; }
  .push-6-9-g-m {
    margin-left: 68.18182%; }
  .pull-6-9-g-m {
    margin-right: 68.18182%; }
  .push-7-9-g-m {
    margin-left: 79.54545%; }
  .pull-7-9-g-m {
    margin-right: 79.54545%; }
  .push-8-9-g-m {
    margin-left: 90.90909%; }
  .pull-8-9-g-m {
    margin-right: 90.90909%; }
  .push-9-9-g-m {
    margin-left: 102.27273%; }
  .pull-9-9-g-m {
    margin-right: 102.27273%; }
  .push-g-9-m {
    margin-left: 4.54545%; }
  .pull-g-9-m {
    margin-right: 4.54545%; }
  .col-1-12-m {
    width: 6.77966%; }
  .col-1-12-g-m {
    width: 8.47458%; }
  .push-1-12-m {
    margin-left: 6.77966%; }
  .pull-1-12-m {
    margin-right: 6.77966%; }
  .col-2-12-m {
    width: 15.25424%; }
  .col-2-12-g-m {
    width: 16.94915%; }
  .push-2-12-m {
    margin-left: 15.25424%; }
  .pull-2-12-m {
    margin-right: 15.25424%; }
  .col-3-12-m {
    width: 23.72881%; }
  .col-3-12-g-m {
    width: 25.42373%; }
  .push-3-12-m {
    margin-left: 23.72881%; }
  .pull-3-12-m {
    margin-right: 23.72881%; }
  .col-4-12-m {
    width: 32.20339%; }
  .col-4-12-g-m {
    width: 33.89831%; }
  .push-4-12-m {
    margin-left: 32.20339%; }
  .pull-4-12-m {
    margin-right: 32.20339%; }
  .col-5-12-m {
    width: 40.67797%; }
  .col-5-12-g-m {
    width: 42.37288%; }
  .push-5-12-m {
    margin-left: 40.67797%; }
  .pull-5-12-m {
    margin-right: 40.67797%; }
  .col-6-12-m {
    width: 49.15254%; }
  .col-6-12-g-m {
    width: 50.84746%; }
  .push-6-12-m {
    margin-left: 49.15254%; }
  .pull-6-12-m {
    margin-right: 49.15254%; }
  .col-7-12-m {
    width: 57.62712%; }
  .col-7-12-g-m {
    width: 59.32203%; }
  .push-7-12-m {
    margin-left: 57.62712%; }
  .pull-7-12-m {
    margin-right: 57.62712%; }
  .col-8-12-m {
    width: 66.10169%; }
  .col-8-12-g-m {
    width: 67.79661%; }
  .push-8-12-m {
    margin-left: 66.10169%; }
  .pull-8-12-m {
    margin-right: 66.10169%; }
  .col-9-12-m {
    width: 74.57627%; }
  .col-9-12-g-m {
    width: 76.27119%; }
  .push-9-12-m {
    margin-left: 74.57627%; }
  .pull-9-12-m {
    margin-right: 74.57627%; }
  .col-10-12-m {
    width: 83.05085%; }
  .col-10-12-g-m {
    width: 84.74576%; }
  .push-10-12-m {
    margin-left: 83.05085%; }
  .pull-10-12-m {
    margin-right: 83.05085%; }
  .col-11-12-m {
    width: 91.52542%; }
  .col-11-12-g-m {
    width: 93.22034%; }
  .push-11-12-m {
    margin-left: 91.52542%; }
  .pull-11-12-m {
    margin-right: 91.52542%; }
  .col-12-12-m {
    width: 100%; }
  .col-12-12-g-m {
    width: 101.69492%; }
  .push-12-12-m {
    margin-left: 100%; }
  .pull-12-12-m {
    margin-right: 100%; }
  .push-0-12-g-m {
    margin-left: 1.69492%; }
  .pull-0-12-g-m {
    margin-right: 1.69492%; }
  .push-1-12-g-m {
    margin-left: 8.47458%; }
  .pull-1-12-g-m {
    margin-right: 8.47458%; }
  .push-2-12-g-m {
    margin-left: 16.94915%; }
  .pull-2-12-g-m {
    margin-right: 16.94915%; }
  .push-3-12-g-m {
    margin-left: 25.42373%; }
  .pull-3-12-g-m {
    margin-right: 25.42373%; }
  .push-4-12-g-m {
    margin-left: 33.89831%; }
  .pull-4-12-g-m {
    margin-right: 33.89831%; }
  .push-5-12-g-m {
    margin-left: 42.37288%; }
  .pull-5-12-g-m {
    margin-right: 42.37288%; }
  .push-6-12-g-m {
    margin-left: 50.84746%; }
  .pull-6-12-g-m {
    margin-right: 50.84746%; }
  .push-7-12-g-m {
    margin-left: 59.32203%; }
  .pull-7-12-g-m {
    margin-right: 59.32203%; }
  .push-8-12-g-m {
    margin-left: 67.79661%; }
  .pull-8-12-g-m {
    margin-right: 67.79661%; }
  .push-9-12-g-m {
    margin-left: 76.27119%; }
  .pull-9-12-g-m {
    margin-right: 76.27119%; }
  .push-10-12-g-m {
    margin-left: 84.74576%; }
  .pull-10-12-g-m {
    margin-right: 84.74576%; }
  .push-11-12-g-m {
    margin-left: 93.22034%; }
  .pull-11-12-g-m {
    margin-right: 93.22034%; }
  .push-12-12-g-m {
    margin-left: 101.69492%; }
  .pull-12-12-g-m {
    margin-right: 101.69492%; }
  .push-g-12-m {
    margin-left: 3.38983%; }
  .pull-g-12-m {
    margin-right: 3.38983%; }
  .col-1-16-m {
    width: 5.06329%; }
  .col-1-16-g-m {
    width: 6.32911%; }
  .push-1-16-m {
    margin-left: 5.06329%; }
  .pull-1-16-m {
    margin-right: 5.06329%; }
  .col-2-16-m {
    width: 11.39241%; }
  .col-2-16-g-m {
    width: 12.65823%; }
  .push-2-16-m {
    margin-left: 11.39241%; }
  .pull-2-16-m {
    margin-right: 11.39241%; }
  .col-3-16-m {
    width: 17.72152%; }
  .col-3-16-g-m {
    width: 18.98734%; }
  .push-3-16-m {
    margin-left: 17.72152%; }
  .pull-3-16-m {
    margin-right: 17.72152%; }
  .col-4-16-m {
    width: 24.05063%; }
  .col-4-16-g-m {
    width: 25.31646%; }
  .push-4-16-m {
    margin-left: 24.05063%; }
  .pull-4-16-m {
    margin-right: 24.05063%; }
  .col-5-16-m {
    width: 30.37975%; }
  .col-5-16-g-m {
    width: 31.64557%; }
  .push-5-16-m {
    margin-left: 30.37975%; }
  .pull-5-16-m {
    margin-right: 30.37975%; }
  .col-6-16-m {
    width: 36.70886%; }
  .col-6-16-g-m {
    width: 37.97468%; }
  .push-6-16-m {
    margin-left: 36.70886%; }
  .pull-6-16-m {
    margin-right: 36.70886%; }
  .col-7-16-m {
    width: 43.03797%; }
  .col-7-16-g-m {
    width: 44.3038%; }
  .push-7-16-m {
    margin-left: 43.03797%; }
  .pull-7-16-m {
    margin-right: 43.03797%; }
  .col-8-16-m {
    width: 49.36709%; }
  .col-8-16-g-m {
    width: 50.63291%; }
  .push-8-16-m {
    margin-left: 49.36709%; }
  .pull-8-16-m {
    margin-right: 49.36709%; }
  .col-9-16-m {
    width: 55.6962%; }
  .col-9-16-g-m {
    width: 56.96203%; }
  .push-9-16-m {
    margin-left: 55.6962%; }
  .pull-9-16-m {
    margin-right: 55.6962%; }
  .col-10-16-m {
    width: 62.02532%; }
  .col-10-16-g-m {
    width: 63.29114%; }
  .push-10-16-m {
    margin-left: 62.02532%; }
  .pull-10-16-m {
    margin-right: 62.02532%; }
  .col-11-16-m {
    width: 68.35443%; }
  .col-11-16-g-m {
    width: 69.62025%; }
  .push-11-16-m {
    margin-left: 68.35443%; }
  .pull-11-16-m {
    margin-right: 68.35443%; }
  .col-12-16-m {
    width: 74.68354%; }
  .col-12-16-g-m {
    width: 75.94937%; }
  .push-12-16-m {
    margin-left: 74.68354%; }
  .pull-12-16-m {
    margin-right: 74.68354%; }
  .col-13-16-m {
    width: 81.01266%; }
  .col-13-16-g-m {
    width: 82.27848%; }
  .push-13-16-m {
    margin-left: 81.01266%; }
  .pull-13-16-m {
    margin-right: 81.01266%; }
  .col-14-16-m {
    width: 87.34177%; }
  .col-14-16-g-m {
    width: 88.60759%; }
  .push-14-16-m {
    margin-left: 87.34177%; }
  .pull-14-16-m {
    margin-right: 87.34177%; }
  .col-15-16-m {
    width: 93.67089%; }
  .col-15-16-g-m {
    width: 94.93671%; }
  .push-15-16-m {
    margin-left: 93.67089%; }
  .pull-15-16-m {
    margin-right: 93.67089%; }
  .col-16-16-m {
    width: 100%; }
  .col-16-16-g-m {
    width: 101.26582%; }
  .push-16-16-m {
    margin-left: 100%; }
  .pull-16-16-m {
    margin-right: 100%; }
  .push-0-16-g-m {
    margin-left: 1.26582%; }
  .pull-0-16-g-m {
    margin-right: 1.26582%; }
  .push-1-16-g-m {
    margin-left: 6.32911%; }
  .pull-1-16-g-m {
    margin-right: 6.32911%; }
  .push-2-16-g-m {
    margin-left: 12.65823%; }
  .pull-2-16-g-m {
    margin-right: 12.65823%; }
  .push-3-16-g-m {
    margin-left: 18.98734%; }
  .pull-3-16-g-m {
    margin-right: 18.98734%; }
  .push-4-16-g-m {
    margin-left: 25.31646%; }
  .pull-4-16-g-m {
    margin-right: 25.31646%; }
  .push-5-16-g-m {
    margin-left: 31.64557%; }
  .pull-5-16-g-m {
    margin-right: 31.64557%; }
  .push-6-16-g-m {
    margin-left: 37.97468%; }
  .pull-6-16-g-m {
    margin-right: 37.97468%; }
  .push-7-16-g-m {
    margin-left: 44.3038%; }
  .pull-7-16-g-m {
    margin-right: 44.3038%; }
  .push-8-16-g-m {
    margin-left: 50.63291%; }
  .pull-8-16-g-m {
    margin-right: 50.63291%; }
  .push-9-16-g-m {
    margin-left: 56.96203%; }
  .pull-9-16-g-m {
    margin-right: 56.96203%; }
  .push-10-16-g-m {
    margin-left: 63.29114%; }
  .pull-10-16-g-m {
    margin-right: 63.29114%; }
  .push-11-16-g-m {
    margin-left: 69.62025%; }
  .pull-11-16-g-m {
    margin-right: 69.62025%; }
  .push-12-16-g-m {
    margin-left: 75.94937%; }
  .pull-12-16-g-m {
    margin-right: 75.94937%; }
  .push-13-16-g-m {
    margin-left: 82.27848%; }
  .pull-13-16-g-m {
    margin-right: 82.27848%; }
  .push-14-16-g-m {
    margin-left: 88.60759%; }
  .pull-14-16-g-m {
    margin-right: 88.60759%; }
  .push-15-16-g-m {
    margin-left: 94.93671%; }
  .pull-15-16-g-m {
    margin-right: 94.93671%; }
  .push-16-16-g-m {
    margin-left: 101.26582%; }
  .pull-16-16-g-m {
    margin-right: 101.26582%; }
  .push-g-16-m {
    margin-left: 2.53165%; }
  .pull-g-16-m {
    margin-right: 2.53165%; } }

@media screen and (min-width: 70em) {
  .col-1-9-l {
    width: 9.09091%; }
  .col-1-9-g-l {
    width: 11.36364%; }
  .push-1-9-l {
    margin-left: 9.09091%; }
  .pull-1-9-l {
    margin-right: 9.09091%; }
  .col-2-9-l {
    width: 20.45455%; }
  .col-2-9-g-l {
    width: 22.72727%; }
  .push-2-9-l {
    margin-left: 20.45455%; }
  .pull-2-9-l {
    margin-right: 20.45455%; }
  .col-3-9-l {
    width: 31.81818%; }
  .col-3-9-g-l {
    width: 34.09091%; }
  .push-3-9-l {
    margin-left: 31.81818%; }
  .pull-3-9-l {
    margin-right: 31.81818%; }
  .col-4-9-l {
    width: 43.18182%; }
  .col-4-9-g-l {
    width: 45.45455%; }
  .push-4-9-l {
    margin-left: 43.18182%; }
  .pull-4-9-l {
    margin-right: 43.18182%; }
  .col-5-9-l {
    width: 54.54545%; }
  .col-5-9-g-l {
    width: 56.81818%; }
  .push-5-9-l {
    margin-left: 54.54545%; }
  .pull-5-9-l {
    margin-right: 54.54545%; }
  .col-6-9-l {
    width: 65.90909%; }
  .col-6-9-g-l {
    width: 68.18182%; }
  .push-6-9-l {
    margin-left: 65.90909%; }
  .pull-6-9-l {
    margin-right: 65.90909%; }
  .col-7-9-l {
    width: 77.27273%; }
  .col-7-9-g-l {
    width: 79.54545%; }
  .push-7-9-l {
    margin-left: 77.27273%; }
  .pull-7-9-l {
    margin-right: 77.27273%; }
  .col-8-9-l {
    width: 88.63636%; }
  .col-8-9-g-l {
    width: 90.90909%; }
  .push-8-9-l {
    margin-left: 88.63636%; }
  .pull-8-9-l {
    margin-right: 88.63636%; }
  .col-9-9-l {
    width: 100%; }
  .col-9-9-g-l {
    width: 102.27273%; }
  .push-9-9-l {
    margin-left: 100%; }
  .pull-9-9-l {
    margin-right: 100%; }
  .push-0-9-g-l {
    margin-left: 2.27273%; }
  .pull-0-9-g-l {
    margin-right: 2.27273%; }
  .push-1-9-g-l {
    margin-left: 11.36364%; }
  .pull-1-9-g-l {
    margin-right: 11.36364%; }
  .push-2-9-g-l {
    margin-left: 22.72727%; }
  .pull-2-9-g-l {
    margin-right: 22.72727%; }
  .push-3-9-g-l {
    margin-left: 34.09091%; }
  .pull-3-9-g-l {
    margin-right: 34.09091%; }
  .push-4-9-g-l {
    margin-left: 45.45455%; }
  .pull-4-9-g-l {
    margin-right: 45.45455%; }
  .push-5-9-g-l {
    margin-left: 56.81818%; }
  .pull-5-9-g-l {
    margin-right: 56.81818%; }
  .push-6-9-g-l {
    margin-left: 68.18182%; }
  .pull-6-9-g-l {
    margin-right: 68.18182%; }
  .push-7-9-g-l {
    margin-left: 79.54545%; }
  .pull-7-9-g-l {
    margin-right: 79.54545%; }
  .push-8-9-g-l {
    margin-left: 90.90909%; }
  .pull-8-9-g-l {
    margin-right: 90.90909%; }
  .push-9-9-g-l {
    margin-left: 102.27273%; }
  .pull-9-9-g-l {
    margin-right: 102.27273%; }
  .push-g-9-l {
    margin-left: 4.54545%; }
  .pull-g-9-l {
    margin-right: 4.54545%; }
  .col-1-12-l {
    width: 6.77966%; }
  .col-1-12-g-l {
    width: 8.47458%; }
  .push-1-12-l {
    margin-left: 6.77966%; }
  .pull-1-12-l {
    margin-right: 6.77966%; }
  .col-2-12-l {
    width: 15.25424%; }
  .col-2-12-g-l {
    width: 16.94915%; }
  .push-2-12-l {
    margin-left: 15.25424%; }
  .pull-2-12-l {
    margin-right: 15.25424%; }
  .col-3-12-l {
    width: 23.72881%; }
  .col-3-12-g-l {
    width: 25.42373%; }
  .push-3-12-l {
    margin-left: 23.72881%; }
  .pull-3-12-l {
    margin-right: 23.72881%; }
  .col-4-12-l {
    width: 32.20339%; }
  .col-4-12-g-l {
    width: 33.89831%; }
  .push-4-12-l {
    margin-left: 32.20339%; }
  .pull-4-12-l {
    margin-right: 32.20339%; }
  .col-5-12-l {
    width: 40.67797%; }
  .col-5-12-g-l {
    width: 42.37288%; }
  .push-5-12-l {
    margin-left: 40.67797%; }
  .pull-5-12-l {
    margin-right: 40.67797%; }
  .col-6-12-l {
    width: 49.15254%; }
  .col-6-12-g-l {
    width: 50.84746%; }
  .push-6-12-l {
    margin-left: 49.15254%; }
  .pull-6-12-l {
    margin-right: 49.15254%; }
  .col-7-12-l {
    width: 57.62712%; }
  .col-7-12-g-l {
    width: 59.32203%; }
  .push-7-12-l {
    margin-left: 57.62712%; }
  .pull-7-12-l {
    margin-right: 57.62712%; }
  .col-8-12-l {
    width: 66.10169%; }
  .col-8-12-g-l {
    width: 67.79661%; }
  .push-8-12-l {
    margin-left: 66.10169%; }
  .pull-8-12-l {
    margin-right: 66.10169%; }
  .col-9-12-l {
    width: 74.57627%; }
  .col-9-12-g-l {
    width: 76.27119%; }
  .push-9-12-l {
    margin-left: 74.57627%; }
  .pull-9-12-l {
    margin-right: 74.57627%; }
  .col-10-12-l {
    width: 83.05085%; }
  .col-10-12-g-l {
    width: 84.74576%; }
  .push-10-12-l {
    margin-left: 83.05085%; }
  .pull-10-12-l {
    margin-right: 83.05085%; }
  .col-11-12-l {
    width: 91.52542%; }
  .col-11-12-g-l {
    width: 93.22034%; }
  .push-11-12-l {
    margin-left: 91.52542%; }
  .pull-11-12-l {
    margin-right: 91.52542%; }
  .col-12-12-l {
    width: 100%; }
  .col-12-12-g-l {
    width: 101.69492%; }
  .push-12-12-l {
    margin-left: 100%; }
  .pull-12-12-l {
    margin-right: 100%; }
  .push-0-12-g-l {
    margin-left: 1.69492%; }
  .pull-0-12-g-l {
    margin-right: 1.69492%; }
  .push-1-12-g-l {
    margin-left: 8.47458%; }
  .pull-1-12-g-l {
    margin-right: 8.47458%; }
  .push-2-12-g-l {
    margin-left: 16.94915%; }
  .pull-2-12-g-l {
    margin-right: 16.94915%; }
  .push-3-12-g-l {
    margin-left: 25.42373%; }
  .pull-3-12-g-l {
    margin-right: 25.42373%; }
  .push-4-12-g-l {
    margin-left: 33.89831%; }
  .pull-4-12-g-l {
    margin-right: 33.89831%; }
  .push-5-12-g-l {
    margin-left: 42.37288%; }
  .pull-5-12-g-l {
    margin-right: 42.37288%; }
  .push-6-12-g-l {
    margin-left: 50.84746%; }
  .pull-6-12-g-l {
    margin-right: 50.84746%; }
  .push-7-12-g-l {
    margin-left: 59.32203%; }
  .pull-7-12-g-l {
    margin-right: 59.32203%; }
  .push-8-12-g-l {
    margin-left: 67.79661%; }
  .pull-8-12-g-l {
    margin-right: 67.79661%; }
  .push-9-12-g-l {
    margin-left: 76.27119%; }
  .pull-9-12-g-l {
    margin-right: 76.27119%; }
  .push-10-12-g-l {
    margin-left: 84.74576%; }
  .pull-10-12-g-l {
    margin-right: 84.74576%; }
  .push-11-12-g-l {
    margin-left: 93.22034%; }
  .pull-11-12-g-l {
    margin-right: 93.22034%; }
  .push-12-12-g-l {
    margin-left: 101.69492%; }
  .pull-12-12-g-l {
    margin-right: 101.69492%; }
  .push-g-12-l {
    margin-left: 3.38983%; }
  .pull-g-12-l {
    margin-right: 3.38983%; }
  .col-1-16-l {
    width: 5.06329%; }
  .col-1-16-g-l {
    width: 6.32911%; }
  .push-1-16-l {
    margin-left: 5.06329%; }
  .pull-1-16-l {
    margin-right: 5.06329%; }
  .col-2-16-l {
    width: 11.39241%; }
  .col-2-16-g-l {
    width: 12.65823%; }
  .push-2-16-l {
    margin-left: 11.39241%; }
  .pull-2-16-l {
    margin-right: 11.39241%; }
  .col-3-16-l {
    width: 17.72152%; }
  .col-3-16-g-l {
    width: 18.98734%; }
  .push-3-16-l {
    margin-left: 17.72152%; }
  .pull-3-16-l {
    margin-right: 17.72152%; }
  .col-4-16-l {
    width: 24.05063%; }
  .col-4-16-g-l {
    width: 25.31646%; }
  .push-4-16-l {
    margin-left: 24.05063%; }
  .pull-4-16-l {
    margin-right: 24.05063%; }
  .col-5-16-l {
    width: 30.37975%; }
  .col-5-16-g-l {
    width: 31.64557%; }
  .push-5-16-l {
    margin-left: 30.37975%; }
  .pull-5-16-l {
    margin-right: 30.37975%; }
  .col-6-16-l {
    width: 36.70886%; }
  .col-6-16-g-l {
    width: 37.97468%; }
  .push-6-16-l {
    margin-left: 36.70886%; }
  .pull-6-16-l {
    margin-right: 36.70886%; }
  .col-7-16-l {
    width: 43.03797%; }
  .col-7-16-g-l {
    width: 44.3038%; }
  .push-7-16-l {
    margin-left: 43.03797%; }
  .pull-7-16-l {
    margin-right: 43.03797%; }
  .col-8-16-l {
    width: 49.36709%; }
  .col-8-16-g-l {
    width: 50.63291%; }
  .push-8-16-l {
    margin-left: 49.36709%; }
  .pull-8-16-l {
    margin-right: 49.36709%; }
  .col-9-16-l {
    width: 55.6962%; }
  .col-9-16-g-l {
    width: 56.96203%; }
  .push-9-16-l {
    margin-left: 55.6962%; }
  .pull-9-16-l {
    margin-right: 55.6962%; }
  .col-10-16-l {
    width: 62.02532%; }
  .col-10-16-g-l {
    width: 63.29114%; }
  .push-10-16-l {
    margin-left: 62.02532%; }
  .pull-10-16-l {
    margin-right: 62.02532%; }
  .col-11-16-l {
    width: 68.35443%; }
  .col-11-16-g-l {
    width: 69.62025%; }
  .push-11-16-l {
    margin-left: 68.35443%; }
  .pull-11-16-l {
    margin-right: 68.35443%; }
  .col-12-16-l {
    width: 74.68354%; }
  .col-12-16-g-l {
    width: 75.94937%; }
  .push-12-16-l {
    margin-left: 74.68354%; }
  .pull-12-16-l {
    margin-right: 74.68354%; }
  .col-13-16-l {
    width: 81.01266%; }
  .col-13-16-g-l {
    width: 82.27848%; }
  .push-13-16-l {
    margin-left: 81.01266%; }
  .pull-13-16-l {
    margin-right: 81.01266%; }
  .col-14-16-l {
    width: 87.34177%; }
  .col-14-16-g-l {
    width: 88.60759%; }
  .push-14-16-l {
    margin-left: 87.34177%; }
  .pull-14-16-l {
    margin-right: 87.34177%; }
  .col-15-16-l {
    width: 93.67089%; }
  .col-15-16-g-l {
    width: 94.93671%; }
  .push-15-16-l {
    margin-left: 93.67089%; }
  .pull-15-16-l {
    margin-right: 93.67089%; }
  .col-16-16-l {
    width: 100%; }
  .col-16-16-g-l {
    width: 101.26582%; }
  .push-16-16-l {
    margin-left: 100%; }
  .pull-16-16-l {
    margin-right: 100%; }
  .push-0-16-g-l {
    margin-left: 1.26582%; }
  .pull-0-16-g-l {
    margin-right: 1.26582%; }
  .push-1-16-g-l {
    margin-left: 6.32911%; }
  .pull-1-16-g-l {
    margin-right: 6.32911%; }
  .push-2-16-g-l {
    margin-left: 12.65823%; }
  .pull-2-16-g-l {
    margin-right: 12.65823%; }
  .push-3-16-g-l {
    margin-left: 18.98734%; }
  .pull-3-16-g-l {
    margin-right: 18.98734%; }
  .push-4-16-g-l {
    margin-left: 25.31646%; }
  .pull-4-16-g-l {
    margin-right: 25.31646%; }
  .push-5-16-g-l {
    margin-left: 31.64557%; }
  .pull-5-16-g-l {
    margin-right: 31.64557%; }
  .push-6-16-g-l {
    margin-left: 37.97468%; }
  .pull-6-16-g-l {
    margin-right: 37.97468%; }
  .push-7-16-g-l {
    margin-left: 44.3038%; }
  .pull-7-16-g-l {
    margin-right: 44.3038%; }
  .push-8-16-g-l {
    margin-left: 50.63291%; }
  .pull-8-16-g-l {
    margin-right: 50.63291%; }
  .push-9-16-g-l {
    margin-left: 56.96203%; }
  .pull-9-16-g-l {
    margin-right: 56.96203%; }
  .push-10-16-g-l {
    margin-left: 63.29114%; }
  .pull-10-16-g-l {
    margin-right: 63.29114%; }
  .push-11-16-g-l {
    margin-left: 69.62025%; }
  .pull-11-16-g-l {
    margin-right: 69.62025%; }
  .push-12-16-g-l {
    margin-left: 75.94937%; }
  .pull-12-16-g-l {
    margin-right: 75.94937%; }
  .push-13-16-g-l {
    margin-left: 82.27848%; }
  .pull-13-16-g-l {
    margin-right: 82.27848%; }
  .push-14-16-g-l {
    margin-left: 88.60759%; }
  .pull-14-16-g-l {
    margin-right: 88.60759%; }
  .push-15-16-g-l {
    margin-left: 94.93671%; }
  .pull-15-16-g-l {
    margin-right: 94.93671%; }
  .push-16-16-g-l {
    margin-left: 101.26582%; }
  .pull-16-16-g-l {
    margin-right: 101.26582%; }
  .push-g-16-l {
    margin-left: 2.53165%; }
  .pull-g-16-l {
    margin-right: 2.53165%; } }

@media screen and (min-width: 92.5em) {
  .col-1-9-xl {
    width: 9.09091%; }
  .col-1-9-g-xl {
    width: 11.36364%; }
  .push-1-9-xl {
    margin-left: 9.09091%; }
  .pull-1-9-xl {
    margin-right: 9.09091%; }
  .col-2-9-xl {
    width: 20.45455%; }
  .col-2-9-g-xl {
    width: 22.72727%; }
  .push-2-9-xl {
    margin-left: 20.45455%; }
  .pull-2-9-xl {
    margin-right: 20.45455%; }
  .col-3-9-xl {
    width: 31.81818%; }
  .col-3-9-g-xl {
    width: 34.09091%; }
  .push-3-9-xl {
    margin-left: 31.81818%; }
  .pull-3-9-xl {
    margin-right: 31.81818%; }
  .col-4-9-xl {
    width: 43.18182%; }
  .col-4-9-g-xl {
    width: 45.45455%; }
  .push-4-9-xl {
    margin-left: 43.18182%; }
  .pull-4-9-xl {
    margin-right: 43.18182%; }
  .col-5-9-xl {
    width: 54.54545%; }
  .col-5-9-g-xl {
    width: 56.81818%; }
  .push-5-9-xl {
    margin-left: 54.54545%; }
  .pull-5-9-xl {
    margin-right: 54.54545%; }
  .col-6-9-xl {
    width: 65.90909%; }
  .col-6-9-g-xl {
    width: 68.18182%; }
  .push-6-9-xl {
    margin-left: 65.90909%; }
  .pull-6-9-xl {
    margin-right: 65.90909%; }
  .col-7-9-xl {
    width: 77.27273%; }
  .col-7-9-g-xl {
    width: 79.54545%; }
  .push-7-9-xl {
    margin-left: 77.27273%; }
  .pull-7-9-xl {
    margin-right: 77.27273%; }
  .col-8-9-xl {
    width: 88.63636%; }
  .col-8-9-g-xl {
    width: 90.90909%; }
  .push-8-9-xl {
    margin-left: 88.63636%; }
  .pull-8-9-xl {
    margin-right: 88.63636%; }
  .col-9-9-xl {
    width: 100%; }
  .col-9-9-g-xl {
    width: 102.27273%; }
  .push-9-9-xl {
    margin-left: 100%; }
  .pull-9-9-xl {
    margin-right: 100%; }
  .push-0-9-g-xl {
    margin-left: 2.27273%; }
  .pull-0-9-g-xl {
    margin-right: 2.27273%; }
  .push-1-9-g-xl {
    margin-left: 11.36364%; }
  .pull-1-9-g-xl {
    margin-right: 11.36364%; }
  .push-2-9-g-xl {
    margin-left: 22.72727%; }
  .pull-2-9-g-xl {
    margin-right: 22.72727%; }
  .push-3-9-g-xl {
    margin-left: 34.09091%; }
  .pull-3-9-g-xl {
    margin-right: 34.09091%; }
  .push-4-9-g-xl {
    margin-left: 45.45455%; }
  .pull-4-9-g-xl {
    margin-right: 45.45455%; }
  .push-5-9-g-xl {
    margin-left: 56.81818%; }
  .pull-5-9-g-xl {
    margin-right: 56.81818%; }
  .push-6-9-g-xl {
    margin-left: 68.18182%; }
  .pull-6-9-g-xl {
    margin-right: 68.18182%; }
  .push-7-9-g-xl {
    margin-left: 79.54545%; }
  .pull-7-9-g-xl {
    margin-right: 79.54545%; }
  .push-8-9-g-xl {
    margin-left: 90.90909%; }
  .pull-8-9-g-xl {
    margin-right: 90.90909%; }
  .push-9-9-g-xl {
    margin-left: 102.27273%; }
  .pull-9-9-g-xl {
    margin-right: 102.27273%; }
  .push-g-9-xl {
    margin-left: 4.54545%; }
  .pull-g-9-xl {
    margin-right: 4.54545%; }
  .col-1-12-xl {
    width: 6.77966%; }
  .col-1-12-g-xl {
    width: 8.47458%; }
  .push-1-12-xl {
    margin-left: 6.77966%; }
  .pull-1-12-xl {
    margin-right: 6.77966%; }
  .col-2-12-xl {
    width: 15.25424%; }
  .col-2-12-g-xl {
    width: 16.94915%; }
  .push-2-12-xl {
    margin-left: 15.25424%; }
  .pull-2-12-xl {
    margin-right: 15.25424%; }
  .col-3-12-xl {
    width: 23.72881%; }
  .col-3-12-g-xl {
    width: 25.42373%; }
  .push-3-12-xl {
    margin-left: 23.72881%; }
  .pull-3-12-xl {
    margin-right: 23.72881%; }
  .col-4-12-xl {
    width: 32.20339%; }
  .col-4-12-g-xl {
    width: 33.89831%; }
  .push-4-12-xl {
    margin-left: 32.20339%; }
  .pull-4-12-xl {
    margin-right: 32.20339%; }
  .col-5-12-xl {
    width: 40.67797%; }
  .col-5-12-g-xl {
    width: 42.37288%; }
  .push-5-12-xl {
    margin-left: 40.67797%; }
  .pull-5-12-xl {
    margin-right: 40.67797%; }
  .col-6-12-xl {
    width: 49.15254%; }
  .col-6-12-g-xl {
    width: 50.84746%; }
  .push-6-12-xl {
    margin-left: 49.15254%; }
  .pull-6-12-xl {
    margin-right: 49.15254%; }
  .col-7-12-xl {
    width: 57.62712%; }
  .col-7-12-g-xl {
    width: 59.32203%; }
  .push-7-12-xl {
    margin-left: 57.62712%; }
  .pull-7-12-xl {
    margin-right: 57.62712%; }
  .col-8-12-xl {
    width: 66.10169%; }
  .col-8-12-g-xl {
    width: 67.79661%; }
  .push-8-12-xl {
    margin-left: 66.10169%; }
  .pull-8-12-xl {
    margin-right: 66.10169%; }
  .col-9-12-xl {
    width: 74.57627%; }
  .col-9-12-g-xl {
    width: 76.27119%; }
  .push-9-12-xl {
    margin-left: 74.57627%; }
  .pull-9-12-xl {
    margin-right: 74.57627%; }
  .col-10-12-xl {
    width: 83.05085%; }
  .col-10-12-g-xl {
    width: 84.74576%; }
  .push-10-12-xl {
    margin-left: 83.05085%; }
  .pull-10-12-xl {
    margin-right: 83.05085%; }
  .col-11-12-xl {
    width: 91.52542%; }
  .col-11-12-g-xl {
    width: 93.22034%; }
  .push-11-12-xl {
    margin-left: 91.52542%; }
  .pull-11-12-xl {
    margin-right: 91.52542%; }
  .col-12-12-xl {
    width: 100%; }
  .col-12-12-g-xl {
    width: 101.69492%; }
  .push-12-12-xl {
    margin-left: 100%; }
  .pull-12-12-xl {
    margin-right: 100%; }
  .push-0-12-g-xl {
    margin-left: 1.69492%; }
  .pull-0-12-g-xl {
    margin-right: 1.69492%; }
  .push-1-12-g-xl {
    margin-left: 8.47458%; }
  .pull-1-12-g-xl {
    margin-right: 8.47458%; }
  .push-2-12-g-xl {
    margin-left: 16.94915%; }
  .pull-2-12-g-xl {
    margin-right: 16.94915%; }
  .push-3-12-g-xl {
    margin-left: 25.42373%; }
  .pull-3-12-g-xl {
    margin-right: 25.42373%; }
  .push-4-12-g-xl {
    margin-left: 33.89831%; }
  .pull-4-12-g-xl {
    margin-right: 33.89831%; }
  .push-5-12-g-xl {
    margin-left: 42.37288%; }
  .pull-5-12-g-xl {
    margin-right: 42.37288%; }
  .push-6-12-g-xl {
    margin-left: 50.84746%; }
  .pull-6-12-g-xl {
    margin-right: 50.84746%; }
  .push-7-12-g-xl {
    margin-left: 59.32203%; }
  .pull-7-12-g-xl {
    margin-right: 59.32203%; }
  .push-8-12-g-xl {
    margin-left: 67.79661%; }
  .pull-8-12-g-xl {
    margin-right: 67.79661%; }
  .push-9-12-g-xl {
    margin-left: 76.27119%; }
  .pull-9-12-g-xl {
    margin-right: 76.27119%; }
  .push-10-12-g-xl {
    margin-left: 84.74576%; }
  .pull-10-12-g-xl {
    margin-right: 84.74576%; }
  .push-11-12-g-xl {
    margin-left: 93.22034%; }
  .pull-11-12-g-xl {
    margin-right: 93.22034%; }
  .push-12-12-g-xl {
    margin-left: 101.69492%; }
  .pull-12-12-g-xl {
    margin-right: 101.69492%; }
  .push-g-12-xl {
    margin-left: 3.38983%; }
  .pull-g-12-xl {
    margin-right: 3.38983%; }
  .col-1-16-xl {
    width: 5.06329%; }
  .col-1-16-g-xl {
    width: 6.32911%; }
  .push-1-16-xl {
    margin-left: 5.06329%; }
  .pull-1-16-xl {
    margin-right: 5.06329%; }
  .col-2-16-xl {
    width: 11.39241%; }
  .col-2-16-g-xl {
    width: 12.65823%; }
  .push-2-16-xl {
    margin-left: 11.39241%; }
  .pull-2-16-xl {
    margin-right: 11.39241%; }
  .col-3-16-xl {
    width: 17.72152%; }
  .col-3-16-g-xl {
    width: 18.98734%; }
  .push-3-16-xl {
    margin-left: 17.72152%; }
  .pull-3-16-xl {
    margin-right: 17.72152%; }
  .col-4-16-xl {
    width: 24.05063%; }
  .col-4-16-g-xl {
    width: 25.31646%; }
  .push-4-16-xl {
    margin-left: 24.05063%; }
  .pull-4-16-xl {
    margin-right: 24.05063%; }
  .col-5-16-xl {
    width: 30.37975%; }
  .col-5-16-g-xl {
    width: 31.64557%; }
  .push-5-16-xl {
    margin-left: 30.37975%; }
  .pull-5-16-xl {
    margin-right: 30.37975%; }
  .col-6-16-xl {
    width: 36.70886%; }
  .col-6-16-g-xl {
    width: 37.97468%; }
  .push-6-16-xl {
    margin-left: 36.70886%; }
  .pull-6-16-xl {
    margin-right: 36.70886%; }
  .col-7-16-xl {
    width: 43.03797%; }
  .col-7-16-g-xl {
    width: 44.3038%; }
  .push-7-16-xl {
    margin-left: 43.03797%; }
  .pull-7-16-xl {
    margin-right: 43.03797%; }
  .col-8-16-xl {
    width: 49.36709%; }
  .col-8-16-g-xl {
    width: 50.63291%; }
  .push-8-16-xl {
    margin-left: 49.36709%; }
  .pull-8-16-xl {
    margin-right: 49.36709%; }
  .col-9-16-xl {
    width: 55.6962%; }
  .col-9-16-g-xl {
    width: 56.96203%; }
  .push-9-16-xl {
    margin-left: 55.6962%; }
  .pull-9-16-xl {
    margin-right: 55.6962%; }
  .col-10-16-xl {
    width: 62.02532%; }
  .col-10-16-g-xl {
    width: 63.29114%; }
  .push-10-16-xl {
    margin-left: 62.02532%; }
  .pull-10-16-xl {
    margin-right: 62.02532%; }
  .col-11-16-xl {
    width: 68.35443%; }
  .col-11-16-g-xl {
    width: 69.62025%; }
  .push-11-16-xl {
    margin-left: 68.35443%; }
  .pull-11-16-xl {
    margin-right: 68.35443%; }
  .col-12-16-xl {
    width: 74.68354%; }
  .col-12-16-g-xl {
    width: 75.94937%; }
  .push-12-16-xl {
    margin-left: 74.68354%; }
  .pull-12-16-xl {
    margin-right: 74.68354%; }
  .col-13-16-xl {
    width: 81.01266%; }
  .col-13-16-g-xl {
    width: 82.27848%; }
  .push-13-16-xl {
    margin-left: 81.01266%; }
  .pull-13-16-xl {
    margin-right: 81.01266%; }
  .col-14-16-xl {
    width: 87.34177%; }
  .col-14-16-g-xl {
    width: 88.60759%; }
  .push-14-16-xl {
    margin-left: 87.34177%; }
  .pull-14-16-xl {
    margin-right: 87.34177%; }
  .col-15-16-xl {
    width: 93.67089%; }
  .col-15-16-g-xl {
    width: 94.93671%; }
  .push-15-16-xl {
    margin-left: 93.67089%; }
  .pull-15-16-xl {
    margin-right: 93.67089%; }
  .col-16-16-xl {
    width: 100%; }
  .col-16-16-g-xl {
    width: 101.26582%; }
  .push-16-16-xl {
    margin-left: 100%; }
  .pull-16-16-xl {
    margin-right: 100%; }
  .push-0-16-g-xl {
    margin-left: 1.26582%; }
  .pull-0-16-g-xl {
    margin-right: 1.26582%; }
  .push-1-16-g-xl {
    margin-left: 6.32911%; }
  .pull-1-16-g-xl {
    margin-right: 6.32911%; }
  .push-2-16-g-xl {
    margin-left: 12.65823%; }
  .pull-2-16-g-xl {
    margin-right: 12.65823%; }
  .push-3-16-g-xl {
    margin-left: 18.98734%; }
  .pull-3-16-g-xl {
    margin-right: 18.98734%; }
  .push-4-16-g-xl {
    margin-left: 25.31646%; }
  .pull-4-16-g-xl {
    margin-right: 25.31646%; }
  .push-5-16-g-xl {
    margin-left: 31.64557%; }
  .pull-5-16-g-xl {
    margin-right: 31.64557%; }
  .push-6-16-g-xl {
    margin-left: 37.97468%; }
  .pull-6-16-g-xl {
    margin-right: 37.97468%; }
  .push-7-16-g-xl {
    margin-left: 44.3038%; }
  .pull-7-16-g-xl {
    margin-right: 44.3038%; }
  .push-8-16-g-xl {
    margin-left: 50.63291%; }
  .pull-8-16-g-xl {
    margin-right: 50.63291%; }
  .push-9-16-g-xl {
    margin-left: 56.96203%; }
  .pull-9-16-g-xl {
    margin-right: 56.96203%; }
  .push-10-16-g-xl {
    margin-left: 63.29114%; }
  .pull-10-16-g-xl {
    margin-right: 63.29114%; }
  .push-11-16-g-xl {
    margin-left: 69.62025%; }
  .pull-11-16-g-xl {
    margin-right: 69.62025%; }
  .push-12-16-g-xl {
    margin-left: 75.94937%; }
  .pull-12-16-g-xl {
    margin-right: 75.94937%; }
  .push-13-16-g-xl {
    margin-left: 82.27848%; }
  .pull-13-16-g-xl {
    margin-right: 82.27848%; }
  .push-14-16-g-xl {
    margin-left: 88.60759%; }
  .pull-14-16-g-xl {
    margin-right: 88.60759%; }
  .push-15-16-g-xl {
    margin-left: 94.93671%; }
  .pull-15-16-g-xl {
    margin-right: 94.93671%; }
  .push-16-16-g-xl {
    margin-left: 101.26582%; }
  .pull-16-16-g-xl {
    margin-right: 101.26582%; }
  .push-g-16-xl {
    margin-left: 2.53165%; }
  .pull-g-16-xl {
    margin-right: 2.53165%; } }

/*

   HEIGHTS
   Docs: http://tachyons.io/docs/layout/heights/

   Base:
     h = height
*/
.h-38 {
  height: 36rem; }

.h-42 {
  height: 42rem; }

.h-44 {
  height: 44rem; }

.h-46 {
  height: 46rem; }

@media screen and (min-width: 48em) {
  .h-38-ns {
    height: 38rem; }
  .h-42-ns {
    height: 42rem; }
  .h-44-ns {
    height: 44rem; }
  .h-46-ns {
    height: 46rem; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .h-38-m {
    height: 38rem; }
  .h-42-m {
    height: 42rem; }
  .h-44-m {
    height: 44rem; }
  .h-46-m {
    height: 46rem; } }

@media screen and (min-width: 70em) {
  .h-38-l {
    height: 38rem; }
  .h-42-l {
    height: 42rem; }
  .h-44-l {
    height: 44rem; }
  .h-46-l {
    height: 46rem; } }

/*

   MAX WIDTHS
   Docs: http://tachyons.io/docs/layout/max-widths/

   Base:
     mw = max-width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     6 = 6st step in width scale
     7 = 7nd step in width scale
     8 = 8rd step in width scale
     9 = 9th step in width scale

     -100 = literal value 100%

     -none  = string value none


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/* Max Width Percentages */
.mw-100 {
  max-width: 100%; }

/* Max Width Scale */
.mw1 {
  max-width: 1rem; }

.mw2 {
  max-width: 2rem; }

.mw3 {
  max-width: 4rem; }

.mw4 {
  max-width: 8rem; }

.mw5 {
  max-width: 16rem; }

.mw6 {
  max-width: 32rem; }

.mw7 {
  max-width: 48rem; }

.mw8 {
  max-width: 64rem; }

.mw9 {
  max-width: 96rem; }

/* Max Width String Properties */
.mw-none {
  max-width: none; }

@media screen and (min-width: 48em) {
  .mw-100-ns {
    max-width: 100%; }
  .mw1-ns {
    max-width: 1rem; }
  .mw2-ns {
    max-width: 2rem; }
  .mw3-ns {
    max-width: 4rem; }
  .mw4-ns {
    max-width: 8rem; }
  .mw5-ns {
    max-width: 16rem; }
  .mw6-ns {
    max-width: 32rem; }
  .mw7-ns {
    max-width: 48rem; }
  .mw8-ns {
    max-width: 64rem; }
  .mw9-ns {
    max-width: 96rem; }
  .mw-none-ns {
    max-width: none; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .mw-100-m {
    max-width: 100%; }
  .mw1-m {
    max-width: 1rem; }
  .mw2-m {
    max-width: 2rem; }
  .mw3-m {
    max-width: 4rem; }
  .mw4-m {
    max-width: 8rem; }
  .mw5-m {
    max-width: 16rem; }
  .mw6-m {
    max-width: 32rem; }
  .mw7-m {
    max-width: 48rem; }
  .mw8-m {
    max-width: 64rem; }
  .mw9-m {
    max-width: 96rem; }
  .mw-none-m {
    max-width: none; } }

@media screen and (min-width: 70em) {
  .mw-100-l {
    max-width: 100%; }
  .mw1-l {
    max-width: 1rem; }
  .mw2-l {
    max-width: 2rem; }
  .mw3-l {
    max-width: 4rem; }
  .mw4-l {
    max-width: 8rem; }
  .mw5-l {
    max-width: 16rem; }
  .mw6-l {
    max-width: 32rem; }
  .mw7-l {
    max-width: 48rem; }
  .mw8-l {
    max-width: 64rem; }
  .mw9-l {
    max-width: 96rem; }
  .mw-none-l {
    max-width: none; } }

/*

   POSITIONING
   Docs: http://tachyons.io/docs/layout/position/

*/
.static {
  position: static; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.fixed {
  position: fixed; }

@media screen and (min-width: 48em) {
  .static-ns {
    position: static; }
  .relative-ns {
    position: relative; }
  .absolute-ns {
    position: absolute; }
  .fixed-ns {
    position: fixed; } }

.pa--8 {
  padding: 0.4096rem; }

.pa--6 {
  padding: 0.512rem; }

.pa--4 {
  padding: 0.64rem; }

.pa--2 {
  padding: 0.8rem; }

.pa-2 {
  padding: 1.25rem; }

.pa-3 {
  padding: 1.375rem; }

.pv--10 {
  padding-bottom: 0.32768rem;
  padding-top: 0.32768rem; }

.pv--8 {
  padding-bottom: 0.4096rem;
  padding-top: 0.4096rem; }

.pv--6 {
  padding-bottom: 0.512rem;
  padding-top: 0.512rem; }

.pv--4 {
  padding-bottom: 0.64rem;
  padding-top: 0.64rem; }

.pv--2 {
  padding-bottom: 0.8rem;
  padding-top: 0.8rem; }

.pv-1 {
  padding-bottom: 1.1rem;
  padding-top: 1.1rem; }

.pv-2 {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem; }

.pv-4 {
  padding-bottom: 1.5625rem;
  padding-top: 1.5625rem; }

.pv-6 {
  padding-bottom: 1.95312rem;
  padding-top: 1.95312rem; }

.pv-8 {
  padding-bottom: 2.44141rem;
  padding-top: 2.44141rem; }

.pv-10 {
  padding-bottom: 3.05176rem;
  padding-top: 3.05176rem; }

.pv-12 {
  padding-bottom: 3.8147rem;
  padding-top: 3.8147rem; }

.pv-16 {
  padding-bottom: 5.96046rem;
  padding-top: 5.96046rem; }

.pv-20 {
  padding-bottom: 9.31323rem;
  padding-top: 9.31323rem; }

.pv-22 {
  padding-bottom: 11.64153rem;
  padding-top: 11.64153rem; }

.ph--16 {
  padding-left: 0.16777rem;
  padding-right: 0.16777rem; }

.ph--10 {
  padding-left: 0.32768rem;
  padding-right: 0.32768rem; }

.ph--4 {
  padding-left: 0.64rem;
  padding-right: 0.64rem; }

.ph--2 {
  padding-left: 0.8rem;
  padding-right: 0.8rem; }

.ph-1 {
  padding-left: 1.1rem;
  padding-right: 1.1rem; }

.ph-2 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.ph-4 {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem; }

.ph-6 {
  padding-left: 1.95312rem;
  padding-right: 1.95312rem; }

.ph-8 {
  padding-left: 2.44141rem;
  padding-right: 2.44141rem; }

.ph-10 {
  padding-left: 3.05176rem;
  padding-right: 3.05176rem; }

.ph-12 {
  padding-left: 3.8147rem;
  padding-right: 3.8147rem; }

.ph-15 {
  padding-left: 5.24521rem;
  padding-right: 5.24521rem; }

.ph-g {
  padding-left: 1.69492%;
  padding-right: 1.69492%; }

.pl-none {
  padding-left: 0; }

.pl-0 {
  padding-left: 1rem; }

.pl-4 {
  padding-left: 1.5625rem; }

.pr--2 {
  padding-right: 0.8rem; }

.pr-2 {
  padding-right: 1.25rem; }

.pr-4 {
  padding-right: 1.5625rem; }

.pr-12 {
  padding-right: 3.8147rem; }

.pt--12 {
  padding-top: 0.26214rem; }

.pt--8 {
  padding-top: 0.4096rem; }

.pt--6 {
  padding-top: 0.512rem; }

.pt--2 {
  padding-top: 0.8rem; }

.pt-0 {
  padding-top: 1rem; }

.pt-2 {
  padding-top: 1.25rem; }

.pt-6 {
  padding-top: 1.5625rem; }

.pt-6 {
  padding-top: 1.95312rem; }

.pt-8 {
  padding-top: 2.44141rem; }

.pt-10 {
  padding-top: 3.05176rem; }

.pt-12 {
  padding-top: 3.8147rem; }

.pt-14 {
  padding-top: 4.76837rem; }

.pt-15 {
  padding-top: 5.24521rem; }

.pt-20 {
  padding-top: 9.31323rem; }

.pt-22 {
  padding-top: 11.64153rem; }

.pb--8 {
  padding-bottom: 0.4096rem; }

.pb--6 {
  padding-bottom: 0.512rem; }

.pb--4 {
  padding-bottom: 0.64rem; }

.pb--2 {
  padding-bottom: 0.8rem; }

.pb--1 {
  padding-bottom: 0.88rem; }

.pb-0 {
  padding-bottom: 1rem; }

.pb-1 {
  padding-bottom: 1.1rem; }

.pb-2 {
  padding-bottom: 1.25rem; }

.pb-4 {
  padding-bottom: 1.5625rem; }

.pb-6 {
  padding-bottom: 1.95312rem; }

.pb-8 {
  padding-bottom: 2.44141rem; }

.pb-10 {
  padding-bottom: 3.05176rem; }

.pb-12 {
  padding-bottom: 3.8147rem; }

.pb-15 {
  padding-bottom: 5.24521rem; }

.pb-22 {
  padding-bottom: 11.64153rem; }

.mr--10 {
  margin-right: 0.32768rem; }

.mr--8 {
  margin-right: 0.4096rem; }

.mr--4 {
  margin-right: 0.64rem; }

.mr--2 {
  margin-right: 0.8rem; }

.mr-0 {
  margin-right: 1rem; }

.mr-2 {
  margin-right: 1.25rem; }

.mr-4 {
  margin-right: 1.5625rem; }

.mr-g {
  margin-right: 1.69492%; }

.ml--0 {
  margin-left: -1rem; }

.ml--4 {
  margin-left: -1.5625rem; }

.ml--6 {
  margin-left: -1.95312rem; }

.ml--8 {
  margin-left: -2.44141rem; }

.ml--4 {
  margin-left: 0.64rem; }

.ml--2 {
  margin-left: 0.8rem; }

.ml-0 {
  margin-left: 1rem; }

.ml-2 {
  margin-left: 1.25rem; }

.ml-4 {
  margin-left: 1.5625rem; }

.ml-6 {
  margin-left: 1.95312rem; }

.ml-8 {
  margin-left: 2.44141rem; }

.ml-g {
  margin-left: 1.69492%; }

.mt--16 {
  margin-top: 0.16777rem; }

.mt--12 {
  margin-top: 0.26214rem; }

.mt--10 {
  margin-top: 0.32768rem; }

.mt--8 {
  margin-top: 0.4096rem; }

.mt--6 {
  margin-top: 0.512rem; }

.mt--4 {
  margin-top: 0.64rem; }

.mt--2 {
  margin-top: 0.8rem; }

.mt-1 {
  margin-top: 1.1rem; }

.mt-2 {
  margin-top: 1.25rem; }

.mt-4 {
  margin-top: 1.5625rem; }

.mt-5 {
  margin-top: 1.71875rem; }

.mt-6 {
  margin-top: 1.95312rem; }

.mt-7 {
  margin-top: 2.14844rem; }

.mt-8 {
  margin-top: 2.44141rem; }

.mt-9 {
  margin-top: 2.68555rem; }

.mt-10 {
  margin-top: 3.05176rem; }

.mt-12 {
  margin-top: 3.8147rem; }

.mt-14 {
  margin-top: 4.76837rem; }

.mt-16 {
  margin-top: 5.96046rem; }

.mt-g {
  margin-top: 1.69492%; }

.mb--16 {
  margin-bottom: 0.16777rem; }

.mb--12 {
  margin-bottom: 0.26214rem; }

.mb--10 {
  margin-bottom: 0.32768rem; }

.mb--8 {
  margin-bottom: 0.4096rem; }

.mb--6 {
  margin-bottom: 0.512rem; }

.mb--4 {
  margin-bottom: 0.64rem; }

.mb--2 {
  margin-bottom: 0.8rem; }

.mb-2 {
  margin-bottom: 1.25rem; }

.mb-4 {
  margin-bottom: 1.5625rem; }

.mb-6 {
  margin-bottom: 1.95312rem; }

.mb-8 {
  margin-bottom: 2.44141rem; }

.mb-9 {
  margin-bottom: 2.68555rem; }

.mb-10 {
  margin-bottom: 3.05176rem; }

.mb-12 {
  margin-bottom: 3.8147rem; }

.mb-14 {
  margin-bottom: 4.76837rem; }

.mb-16 {
  margin-bottom: 5.96046rem; }

.mb-g {
  margin-bottom: 1.69492%; }

.mv-8 {
  margin-top: 2.44141rem;
  margin-bottom: 2.44141rem; }

.mv-10 {
  margin-top: 3.05176rem;
  margin-bottom: 3.05176rem; }

.mh-n--16 {
  margin-left: 0.16777rem;
  margin-right: 0.16777rem; }

.mh-n--10 {
  margin-left: 0.32768rem;
  margin-right: 0.32768rem; }

@media screen and (min-width: 48em) {
  .pv-20-ns {
    padding-bottom: 9.31323rem;
    padding-top: 9.31323rem; }
  .pv-22-ns {
    padding-bottom: 11.64153rem;
    padding-top: 11.64153rem; }
  .pt-20-ns {
    padding-top: 9.31323rem; }
  .pt-22-ns {
    padding-top: 11.64153rem; }
  .pb-22-ns {
    padding-bottom: 11.64153rem; }
  .pr-4-ns {
    padding-right: 1.5625rem; }
  .ml-g-ns {
    margin-left: 1.69492%; }
  .mr-4-ns {
    margin-right: 1.5625rem; }
  .mr-g-ns {
    margin-right: 1.69492%; }
  .mb--10-ns {
    margin-bottom: 0.32768rem; }
  .mb--4-ns {
    margin-bottom: 0.64rem; }
  .mb--2-ns {
    margin-bottom: 0.8rem; }
  .mb-4-ns {
    margin-bottom: 1.5625rem; }
  .mb-g-ns {
    margin-bottom: 1.69492%; }
  .mt-g-ns {
    margin-top: 1.69492%; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .pv-18-m {
    padding-bottom: 7.45058rem;
    padding-top: 7.45058rem; }
  .pt-18-m {
    padding-top: 7.45058rem; }
  .pb-18-m {
    padding-bottom: 7.45058rem; } }

@media screen and (min-width: 70em) {
  .mt-6-l {
    margin-top: 1.95312rem; }
  .pv-12-l {
    padding-bottom: 3.8147rem;
    padding-top: 3.8147rem; } }

/*
   SPACING
   Docs: http://tachyons.io/docs/layout/spacing/

   An eight step powers of two scale ranging from 0 to 16rem.

   Base:
     p = padding
     m = margin

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left

     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.pa0 {
  padding: 0; }

.pa1 {
  padding: 1rem; }

.pa2 {
  padding: 1.25rem; }

.pa3 {
  padding: 1.375rem; }

.pa4 {
  padding: 1.5625rem; }

.pa5 {
  padding: 1.95312rem; }

.pa6 {
  padding: 3.8147rem; }

.pa7 {
  padding: 4.76837rem; }

.pl0 {
  padding-left: 0; }

.pl1 {
  padding-left: 1rem; }

.pl2 {
  padding-left: 1.25rem; }

.pl3 {
  padding-left: 1.375rem; }

.pl4 {
  padding-left: 1.5625rem; }

.pl5 {
  padding-left: 1.95312rem; }

.pl6 {
  padding-left: 3.8147rem; }

.pl7 {
  padding-left: 4.76837rem; }

.pr0 {
  padding-right: 0; }

.pr1 {
  padding-right: 1rem; }

.pr2 {
  padding-right: 1.25rem; }

.pr3 {
  padding-right: 1.375rem; }

.pr4 {
  padding-right: 1.5625rem; }

.pr5 {
  padding-right: 1.95312rem; }

.pr6 {
  padding-right: 3.8147rem; }

.pr7 {
  padding-right: 4.76837rem; }

.pb0 {
  padding-bottom: 0; }

.pb1 {
  padding-bottom: 1rem; }

.pb2 {
  padding-bottom: 1.25rem; }

.pb3 {
  padding-bottom: 1.375rem; }

.pb4 {
  padding-bottom: 1.5625rem; }

.pb5 {
  padding-bottom: 1.95312rem; }

.pb6 {
  padding-bottom: 3.8147rem; }

.pb7 {
  padding-bottom: 4.76837rem; }

.pt0 {
  padding-top: 0; }

.pt1 {
  padding-top: 1rem; }

.pt2 {
  padding-top: 1.25rem; }

.pt3 {
  padding-top: 1.375rem; }

.pt4 {
  padding-top: 1.5625rem; }

.pt5 {
  padding-top: 1.95312rem; }

.pt6 {
  padding-top: 3.8147rem; }

.pt7 {
  padding-top: 4.76837rem; }

.pv0 {
  padding-top: 0;
  padding-bottom: 0; }

.pv1 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.pv2 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.pv3 {
  padding-top: 1.375rem;
  padding-bottom: 1.375rem; }

.pv4 {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem; }

.pv5 {
  padding-top: 1.95312rem;
  padding-bottom: 1.95312rem; }

.pv6 {
  padding-top: 3.8147rem;
  padding-bottom: 3.8147rem; }

.pv7 {
  padding-top: 4.76837rem;
  padding-bottom: 4.76837rem; }

.ph0 {
  padding-left: 0;
  padding-right: 0; }

.ph1 {
  padding-left: 1rem;
  padding-right: 1rem; }

.ph2 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.ph3 {
  padding-left: 1.375rem;
  padding-right: 1.375rem; }

.ph4 {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem; }

.ph5 {
  padding-left: 1.95312rem;
  padding-right: 1.95312rem; }

.ph6 {
  padding-left: 3.8147rem;
  padding-right: 3.8147rem; }

.ph7 {
  padding-left: 4.76837rem;
  padding-right: 4.76837rem; }

.ma0 {
  margin: 0; }

.ma1 {
  margin: 1rem; }

.ma2 {
  margin: 1.25rem; }

.ma3 {
  margin: 1.375rem; }

.ma4 {
  margin: 1.5625rem; }

.ma5 {
  margin: 1.95312rem; }

.ma6 {
  margin: 3.8147rem; }

.ma7 {
  margin: 4.76837rem; }

.ml0 {
  margin-left: 0; }

.ml1 {
  margin-left: 1rem; }

.ml2 {
  margin-left: 1.25rem; }

.ml3 {
  margin-left: 1.375rem; }

.ml4 {
  margin-left: 1.5625rem; }

.ml5 {
  margin-left: 1.95312rem; }

.ml6 {
  margin-left: 3.8147rem; }

.ml7 {
  margin-left: 4.76837rem; }

.mr0 {
  margin-right: 0; }

.mr1 {
  margin-right: 1rem; }

.mr2 {
  margin-right: 1.25rem; }

.mr3 {
  margin-right: 1.375rem; }

.mr4 {
  margin-right: 1.5625rem; }

.mr5 {
  margin-right: 1.95312rem; }

.mr6 {
  margin-right: 3.8147rem; }

.mr7 {
  margin-right: 4.76837rem; }

.mb0 {
  margin-bottom: 0; }

.mb1 {
  margin-bottom: 1rem; }

.mb2 {
  margin-bottom: 1.25rem; }

.mb3 {
  margin-bottom: 1.375rem; }

.mb4 {
  margin-bottom: 1.5625rem; }

.mb5 {
  margin-bottom: 1.95312rem; }

.mb6 {
  margin-bottom: 3.8147rem; }

.mb7 {
  margin-bottom: 4.76837rem; }

.mt0 {
  margin-top: 0; }

.mt1 {
  margin-top: 1rem; }

.mt2 {
  margin-top: 1.25rem; }

.mt3 {
  margin-top: 1.375rem; }

.mt4 {
  margin-top: 1.5625rem; }

.mt5 {
  margin-top: 1.95312rem; }

.mt6 {
  margin-top: 3.8147rem; }

.mt7 {
  margin-top: 4.76837rem; }

.mv0 {
  margin-top: 0;
  margin-bottom: 0; }

.mv1 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mv2 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.mv3 {
  margin-top: 1.375rem;
  margin-bottom: 1.375rem; }

.mv4 {
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem; }

.mv5 {
  margin-top: 1.95312rem;
  margin-bottom: 1.95312rem; }

.mv6 {
  margin-top: 3.8147rem;
  margin-bottom: 3.8147rem; }

.mv7 {
  margin-top: 4.76837rem;
  margin-bottom: 4.76837rem; }

.mh0 {
  margin-left: 0;
  margin-right: 0; }

.mh1 {
  margin-left: 1rem;
  margin-right: 1rem; }

.mh2 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.mh3 {
  margin-left: 1.375rem;
  margin-right: 1.375rem; }

.mh4 {
  margin-left: 1.5625rem;
  margin-right: 1.5625rem; }

.mh5 {
  margin-left: 1.95312rem;
  margin-right: 1.95312rem; }

.mh6 {
  margin-left: 3.8147rem;
  margin-right: 3.8147rem; }

.mh7 {
  margin-left: 4.76837rem;
  margin-right: 4.76837rem; }

@media screen and (min-width: 48em) {
  .pa0-ns {
    padding: 0; }
  .pa1-ns {
    padding: 1rem; }
  .pa2-ns {
    padding: 1.25rem; }
  .pa3-ns {
    padding: 1.375rem; }
  .pa4-ns {
    padding: 1.5625rem; }
  .pa5-ns {
    padding: 1.95312rem; }
  .pa6-ns {
    padding: 3.8147rem; }
  .pa7-ns {
    padding: 4.76837rem; }
  .pl0-ns {
    padding-left: 0; }
  .pl1-ns {
    padding-left: 1rem; }
  .pl2-ns {
    padding-left: 1.25rem; }
  .pl3-ns {
    padding-left: 1.375rem; }
  .pl4-ns {
    padding-left: 1.5625rem; }
  .pl5-ns {
    padding-left: 1.95312rem; }
  .pl6-ns {
    padding-left: 3.8147rem; }
  .pl7-ns {
    padding-left: 4.76837rem; }
  .pr0-ns {
    padding-right: 0; }
  .pr1-ns {
    padding-right: 1rem; }
  .pr2-ns {
    padding-right: 1.25rem; }
  .pr3-ns {
    padding-right: 1.375rem; }
  .pr4-ns {
    padding-right: 1.5625rem; }
  .pr5-ns {
    padding-right: 1.95312rem; }
  .pr6-ns {
    padding-right: 3.8147rem; }
  .pr7-ns {
    padding-right: 4.76837rem; }
  .pb0-ns {
    padding-bottom: 0; }
  .pb1-ns {
    padding-bottom: 1rem; }
  .pb2-ns {
    padding-bottom: 1.25rem; }
  .pb3-ns {
    padding-bottom: 1.375rem; }
  .pb4-ns {
    padding-bottom: 1.5625rem; }
  .pb5-ns {
    padding-bottom: 1.95312rem; }
  .pb6-ns {
    padding-bottom: 3.8147rem; }
  .pb7-ns {
    padding-bottom: 4.76837rem; }
  .pt0-ns {
    padding-top: 0; }
  .pt1-ns {
    padding-top: 1rem; }
  .pt2-ns {
    padding-top: 1.25rem; }
  .pt3-ns {
    padding-top: 1.375rem; }
  .pt4-ns {
    padding-top: 1.5625rem; }
  .pt5-ns {
    padding-top: 1.95312rem; }
  .pt6-ns {
    padding-top: 3.8147rem; }
  .pt7-ns {
    padding-top: 4.76837rem; }
  .pv0-ns {
    padding-top: 0;
    padding-bottom: 0; }
  .pv1-ns {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .pv2-ns {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .pv3-ns {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem; }
  .pv4-ns {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem; }
  .pv5-ns {
    padding-top: 1.95312rem;
    padding-bottom: 1.95312rem; }
  .pv6-ns {
    padding-top: 3.8147rem;
    padding-bottom: 3.8147rem; }
  .pv7-ns {
    padding-top: 4.76837rem;
    padding-bottom: 4.76837rem; }
  .ph0-ns {
    padding-left: 0;
    padding-right: 0; }
  .ph1-ns {
    padding-left: 1rem;
    padding-right: 1rem; }
  .ph2-ns {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .ph3-ns {
    padding-left: 1.375rem;
    padding-right: 1.375rem; }
  .ph4-ns {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem; }
  .ph5-ns {
    padding-left: 1.95312rem;
    padding-right: 1.95312rem; }
  .ph6-ns {
    padding-left: 3.8147rem;
    padding-right: 3.8147rem; }
  .ph7-ns {
    padding-left: 4.76837rem;
    padding-right: 4.76837rem; }
  .ma0-ns {
    margin: 0; }
  .ma1-ns {
    margin: 1rem; }
  .ma2-ns {
    margin: 1.25rem; }
  .ma3-ns {
    margin: 1.375rem; }
  .ma4-ns {
    margin: 1.5625rem; }
  .ma5-ns {
    margin: 1.95312rem; }
  .ma6-ns {
    margin: 3.8147rem; }
  .ma7-ns {
    margin: 4.76837rem; }
  .ml0-ns {
    margin-left: 0; }
  .ml1-ns {
    margin-left: 1rem; }
  .ml2-ns {
    margin-left: 1.25rem; }
  .ml3-ns {
    margin-left: 1.375rem; }
  .ml4-ns {
    margin-left: 1.5625rem; }
  .ml5-ns {
    margin-left: 1.95312rem; }
  .ml6-ns {
    margin-left: 3.8147rem; }
  .ml7-ns {
    margin-left: 4.76837rem; }
  .mr0-ns {
    margin-right: 0; }
  .mr1-ns {
    margin-right: 1rem; }
  .mr2-ns {
    margin-right: 1.25rem; }
  .mr3-ns {
    margin-right: 1.375rem; }
  .mr4-ns {
    margin-right: 1.5625rem; }
  .mr5-ns {
    margin-right: 1.95312rem; }
  .mr6-ns {
    margin-right: 3.8147rem; }
  .mr7-ns {
    margin-right: 4.76837rem; }
  .mb0-ns {
    margin-bottom: 0; }
  .mb1-ns {
    margin-bottom: 1rem; }
  .mb2-ns {
    margin-bottom: 1.25rem; }
  .mb3-ns {
    margin-bottom: 1.375rem; }
  .mb4-ns {
    margin-bottom: 1.5625rem; }
  .mb5-ns {
    margin-bottom: 1.95312rem; }
  .mb6-ns {
    margin-bottom: 3.8147rem; }
  .mb7-ns {
    margin-bottom: 4.76837rem; }
  .mt0-ns {
    margin-top: 0; }
  .mt1-ns {
    margin-top: 1rem; }
  .mt2-ns {
    margin-top: 1.25rem; }
  .mt3-ns {
    margin-top: 1.375rem; }
  .mt4-ns {
    margin-top: 1.5625rem; }
  .mt5-ns {
    margin-top: 1.95312rem; }
  .mt6-ns {
    margin-top: 3.8147rem; }
  .mt7-ns {
    margin-top: 4.76837rem; }
  .mv0-ns {
    margin-top: 0;
    margin-bottom: 0; }
  .mv1-ns {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .mv2-ns {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .mv3-ns {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem; }
  .mv4-ns {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem; }
  .mv5-ns {
    margin-top: 1.95312rem;
    margin-bottom: 1.95312rem; }
  .mv6-ns {
    margin-top: 3.8147rem;
    margin-bottom: 3.8147rem; }
  .mv7-ns {
    margin-top: 4.76837rem;
    margin-bottom: 4.76837rem; }
  .mh0-ns {
    margin-left: 0;
    margin-right: 0; }
  .mh1-ns {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mh2-ns {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mh3-ns {
    margin-left: 1.375rem;
    margin-right: 1.375rem; }
  .mh4-ns {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem; }
  .mh5-ns {
    margin-left: 1.95312rem;
    margin-right: 1.95312rem; }
  .mh6-ns {
    margin-left: 3.8147rem;
    margin-right: 3.8147rem; }
  .mh7-ns {
    margin-left: 4.76837rem;
    margin-right: 4.76837rem; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .pa0-m {
    padding: 0; }
  .pa1-m {
    padding: 1rem; }
  .pa2-m {
    padding: 1.25rem; }
  .pa3-m {
    padding: 1.375rem; }
  .pa4-m {
    padding: 1.5625rem; }
  .pa5-m {
    padding: 1.95312rem; }
  .pa6-m {
    padding: 3.8147rem; }
  .pa7-m {
    padding: 4.76837rem; }
  .pl0-m {
    padding-left: 0; }
  .pl1-m {
    padding-left: 1rem; }
  .pl2-m {
    padding-left: 1.25rem; }
  .pl3-m {
    padding-left: 1.375rem; }
  .pl4-m {
    padding-left: 1.5625rem; }
  .pl5-m {
    padding-left: 1.95312rem; }
  .pl6-m {
    padding-left: 3.8147rem; }
  .pl7-m {
    padding-left: 4.76837rem; }
  .pr0-m {
    padding-right: 0; }
  .pr1-m {
    padding-right: 1rem; }
  .pr2-m {
    padding-right: 1.25rem; }
  .pr3-m {
    padding-right: 1.375rem; }
  .pr4-m {
    padding-right: 1.5625rem; }
  .pr5-m {
    padding-right: 1.95312rem; }
  .pr6-m {
    padding-right: 3.8147rem; }
  .pr7-m {
    padding-right: 4.76837rem; }
  .pb0-m {
    padding-bottom: 0; }
  .pb1-m {
    padding-bottom: 1rem; }
  .pb2-m {
    padding-bottom: 1.25rem; }
  .pb3-m {
    padding-bottom: 1.375rem; }
  .pb4-m {
    padding-bottom: 1.5625rem; }
  .pb5-m {
    padding-bottom: 1.95312rem; }
  .pb6-m {
    padding-bottom: 3.8147rem; }
  .pb7-m {
    padding-bottom: 4.76837rem; }
  .pt0-m {
    padding-top: 0; }
  .pt1-m {
    padding-top: 1rem; }
  .pt2-m {
    padding-top: 1.25rem; }
  .pt3-m {
    padding-top: 1.375rem; }
  .pt4-m {
    padding-top: 1.5625rem; }
  .pt5-m {
    padding-top: 1.95312rem; }
  .pt6-m {
    padding-top: 3.8147rem; }
  .pt7-m {
    padding-top: 4.76837rem; }
  .pv0-m {
    padding-top: 0;
    padding-bottom: 0; }
  .pv1-m {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .pv2-m {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .pv3-m {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem; }
  .pv4-m {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem; }
  .pv5-m {
    padding-top: 1.95312rem;
    padding-bottom: 1.95312rem; }
  .pv6-m {
    padding-top: 3.8147rem;
    padding-bottom: 3.8147rem; }
  .pv7-m {
    padding-top: 4.76837rem;
    padding-bottom: 4.76837rem; }
  .ph0-m {
    padding-left: 0;
    padding-right: 0; }
  .ph1-m {
    padding-left: 1rem;
    padding-right: 1rem; }
  .ph2-m {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .ph3-m {
    padding-left: 1.375rem;
    padding-right: 1.375rem; }
  .ph4-m {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem; }
  .ph5-m {
    padding-left: 1.95312rem;
    padding-right: 1.95312rem; }
  .ph6-m {
    padding-left: 3.8147rem;
    padding-right: 3.8147rem; }
  .ph7-m {
    padding-left: 4.76837rem;
    padding-right: 4.76837rem; }
  .ma0-m {
    margin: 0; }
  .ma1-m {
    margin: 1rem; }
  .ma2-m {
    margin: 1.25rem; }
  .ma3-m {
    margin: 1.375rem; }
  .ma4-m {
    margin: 1.5625rem; }
  .ma5-m {
    margin: 1.95312rem; }
  .ma6-m {
    margin: 3.8147rem; }
  .ma7-m {
    margin: 4.76837rem; }
  .ml0-m {
    margin-left: 0; }
  .ml1-m {
    margin-left: 1rem; }
  .ml2-m {
    margin-left: 1.25rem; }
  .ml3-m {
    margin-left: 1.375rem; }
  .ml4-m {
    margin-left: 1.5625rem; }
  .ml5-m {
    margin-left: 1.95312rem; }
  .ml6-m {
    margin-left: 3.8147rem; }
  .ml7-m {
    margin-left: 4.76837rem; }
  .mr0-m {
    margin-right: 0; }
  .mr1-m {
    margin-right: 1rem; }
  .mr2-m {
    margin-right: 1.25rem; }
  .mr3-m {
    margin-right: 1.375rem; }
  .mr4-m {
    margin-right: 1.5625rem; }
  .mr5-m {
    margin-right: 1.95312rem; }
  .mr6-m {
    margin-right: 3.8147rem; }
  .mr7-m {
    margin-right: 4.76837rem; }
  .mb0-m {
    margin-bottom: 0; }
  .mb1-m {
    margin-bottom: 1rem; }
  .mb2-m {
    margin-bottom: 1.25rem; }
  .mb3-m {
    margin-bottom: 1.375rem; }
  .mb4-m {
    margin-bottom: 1.5625rem; }
  .mb5-m {
    margin-bottom: 1.95312rem; }
  .mb6-m {
    margin-bottom: 3.8147rem; }
  .mb7-m {
    margin-bottom: 4.76837rem; }
  .mt0-m {
    margin-top: 0; }
  .mt1-m {
    margin-top: 1rem; }
  .mt2-m {
    margin-top: 1.25rem; }
  .mt3-m {
    margin-top: 1.375rem; }
  .mt4-m {
    margin-top: 1.5625rem; }
  .mt5-m {
    margin-top: 1.95312rem; }
  .mt6-m {
    margin-top: 3.8147rem; }
  .mt7-m {
    margin-top: 4.76837rem; }
  .mv0-m {
    margin-top: 0;
    margin-bottom: 0; }
  .mv1-m {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .mv2-m {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .mv3-m {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem; }
  .mv4-m {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem; }
  .mv5-m {
    margin-top: 1.95312rem;
    margin-bottom: 1.95312rem; }
  .mv6-m {
    margin-top: 3.8147rem;
    margin-bottom: 3.8147rem; }
  .mv7-m {
    margin-top: 4.76837rem;
    margin-bottom: 4.76837rem; }
  .mh0-m {
    margin-left: 0;
    margin-right: 0; }
  .mh1-m {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mh2-m {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mh3-m {
    margin-left: 1.375rem;
    margin-right: 1.375rem; }
  .mh4-m {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem; }
  .mh5-m {
    margin-left: 1.95312rem;
    margin-right: 1.95312rem; }
  .mh6-m {
    margin-left: 3.8147rem;
    margin-right: 3.8147rem; }
  .mh7-m {
    margin-left: 4.76837rem;
    margin-right: 4.76837rem; } }

@media screen and (min-width: 70em) {
  .pa0-l {
    padding: 0; }
  .pa1-l {
    padding: 1rem; }
  .pa2-l {
    padding: 1.25rem; }
  .pa3-l {
    padding: 1.375rem; }
  .pa4-l {
    padding: 1.5625rem; }
  .pa5-l {
    padding: 1.95312rem; }
  .pa6-l {
    padding: 3.8147rem; }
  .pa7-l {
    padding: 4.76837rem; }
  .pl0-l {
    padding-left: 0; }
  .pl1-l {
    padding-left: 1rem; }
  .pl2-l {
    padding-left: 1.25rem; }
  .pl3-l {
    padding-left: 1.375rem; }
  .pl4-l {
    padding-left: 1.5625rem; }
  .pl5-l {
    padding-left: 1.95312rem; }
  .pl6-l {
    padding-left: 3.8147rem; }
  .pl7-l {
    padding-left: 4.76837rem; }
  .pr0-l {
    padding-right: 0; }
  .pr1-l {
    padding-right: 1rem; }
  .pr2-l {
    padding-right: 1.25rem; }
  .pr3-l {
    padding-right: 1.375rem; }
  .pr4-l {
    padding-right: 1.5625rem; }
  .pr5-l {
    padding-right: 1.95312rem; }
  .pr6-l {
    padding-right: 3.8147rem; }
  .pr7-l {
    padding-right: 4.76837rem; }
  .pb0-l {
    padding-bottom: 0; }
  .pb1-l {
    padding-bottom: 1rem; }
  .pb2-l {
    padding-bottom: 1.25rem; }
  .pb3-l {
    padding-bottom: 1.375rem; }
  .pb4-l {
    padding-bottom: 1.5625rem; }
  .pb5-l {
    padding-bottom: 1.95312rem; }
  .pb6-l {
    padding-bottom: 3.8147rem; }
  .pb7-l {
    padding-bottom: 4.76837rem; }
  .pt0-l {
    padding-top: 0; }
  .pt1-l {
    padding-top: 1rem; }
  .pt2-l {
    padding-top: 1.25rem; }
  .pt3-l {
    padding-top: 1.375rem; }
  .pt4-l {
    padding-top: 1.5625rem; }
  .pt5-l {
    padding-top: 1.95312rem; }
  .pt6-l {
    padding-top: 3.8147rem; }
  .pt7-l {
    padding-top: 4.76837rem; }
  .pv0-l {
    padding-top: 0;
    padding-bottom: 0; }
  .pv1-l {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .pv2-l {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .pv3-l {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem; }
  .pv4-l {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem; }
  .pv5-l {
    padding-top: 1.95312rem;
    padding-bottom: 1.95312rem; }
  .pv6-l {
    padding-top: 3.8147rem;
    padding-bottom: 3.8147rem; }
  .pv7-l {
    padding-top: 4.76837rem;
    padding-bottom: 4.76837rem; }
  .ph0-l {
    padding-left: 0;
    padding-right: 0; }
  .ph1-l {
    padding-left: 1rem;
    padding-right: 1rem; }
  .ph2-l {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .ph3-l {
    padding-left: 1.375rem;
    padding-right: 1.375rem; }
  .ph4-l {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem; }
  .ph5-l {
    padding-left: 1.95312rem;
    padding-right: 1.95312rem; }
  .ph6-l {
    padding-left: 3.8147rem;
    padding-right: 3.8147rem; }
  .ph7-l {
    padding-left: 4.76837rem;
    padding-right: 4.76837rem; }
  .ma0-l {
    margin: 0; }
  .ma1-l {
    margin: 1rem; }
  .ma2-l {
    margin: 1.25rem; }
  .ma3-l {
    margin: 1.375rem; }
  .ma4-l {
    margin: 1.5625rem; }
  .ma5-l {
    margin: 1.95312rem; }
  .ma6-l {
    margin: 3.8147rem; }
  .ma7-l {
    margin: 4.76837rem; }
  .ml0-l {
    margin-left: 0; }
  .ml1-l {
    margin-left: 1rem; }
  .ml2-l {
    margin-left: 1.25rem; }
  .ml3-l {
    margin-left: 1.375rem; }
  .ml4-l {
    margin-left: 1.5625rem; }
  .ml5-l {
    margin-left: 1.95312rem; }
  .ml6-l {
    margin-left: 3.8147rem; }
  .ml7-l {
    margin-left: 4.76837rem; }
  .mr0-l {
    margin-right: 0; }
  .mr1-l {
    margin-right: 1rem; }
  .mr2-l {
    margin-right: 1.25rem; }
  .mr3-l {
    margin-right: 1.375rem; }
  .mr4-l {
    margin-right: 1.5625rem; }
  .mr5-l {
    margin-right: 1.95312rem; }
  .mr6-l {
    margin-right: 3.8147rem; }
  .mr7-l {
    margin-right: 4.76837rem; }
  .mb0-l {
    margin-bottom: 0; }
  .mb1-l {
    margin-bottom: 1rem; }
  .mb2-l {
    margin-bottom: 1.25rem; }
  .mb3-l {
    margin-bottom: 1.375rem; }
  .mb4-l {
    margin-bottom: 1.5625rem; }
  .mb5-l {
    margin-bottom: 1.95312rem; }
  .mb6-l {
    margin-bottom: 3.8147rem; }
  .mb7-l {
    margin-bottom: 4.76837rem; }
  .mt0-l {
    margin-top: 0; }
  .mt1-l {
    margin-top: 1rem; }
  .mt2-l {
    margin-top: 1.25rem; }
  .mt3-l {
    margin-top: 1.375rem; }
  .mt4-l {
    margin-top: 1.5625rem; }
  .mt5-l {
    margin-top: 1.95312rem; }
  .mt6-l {
    margin-top: 3.8147rem; }
  .mt7-l {
    margin-top: 4.76837rem; }
  .mv0-l {
    margin-top: 0;
    margin-bottom: 0; }
  .mv1-l {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .mv2-l {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .mv3-l {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem; }
  .mv4-l {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem; }
  .mv5-l {
    margin-top: 1.95312rem;
    margin-bottom: 1.95312rem; }
  .mv6-l {
    margin-top: 3.8147rem;
    margin-bottom: 3.8147rem; }
  .mv7-l {
    margin-top: 4.76837rem;
    margin-bottom: 4.76837rem; }
  .mh0-l {
    margin-left: 0;
    margin-right: 0; }
  .mh1-l {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mh2-l {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mh3-l {
    margin-left: 1.375rem;
    margin-right: 1.375rem; }
  .mh4-l {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem; }
  .mh5-l {
    margin-left: 1.95312rem;
    margin-right: 1.95312rem; }
  .mh6-l {
    margin-left: 3.8147rem;
    margin-right: 3.8147rem; }
  .mh7-l {
    margin-left: 4.76837rem;
    margin-right: 4.76837rem; } }

@media screen and (min-width: 92.5em) {
  .pt0-xl {
    padding-top: 0; }
  .ml0-xl {
    margin-left: 0; } }

/*

  TEXT ALIGN
  Docs: http://tachyons.io/docs/typography/text-align/

  Base
    t = text-align

  Modifiers
    l = left
    r = right
    c = center
    j = justify

  Media Query Extensions:
    -ns = not-small
    -m  = medium
    -l  = large

*/
.tl {
  text-align: left; }

.tr {
  text-align: right; }

.tc {
  text-align: center; }

.tj {
  text-align: justify; }

@media screen and (min-width: 48em) {
  .tl-ns {
    text-align: left; }
  .tr-ns {
    text-align: right; }
  .tc-ns {
    text-align: center; }
  .tj-ns {
    text-align: justify; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .tl-m {
    text-align: left; }
  .tr-m {
    text-align: right; }
  .tc-m {
    text-align: center; }
  .tj-m {
    text-align: justify; } }

@media screen and (min-width: 70em) {
  .tl-l {
    text-align: left; }
  .tr-l {
    text-align: right; }
  .tc-l {
    text-align: center; }
  .tj-l {
    text-align: justify; } }

/*

   TEXT DECORATION
   Docs: http://tachyons.io/docs/typography/text-decoration/

*/
.strike {
  text-decoration: line-through; }

.underline {
  text-decoration: underline; }

.no-underline {
  text-decoration: none; }

/**
 * TYPE SCALE ------------------------- */
.f-14 {
  font-size: 4.76837rem; }

.f-12 {
  font-size: 3.8147rem; }

.f-10 {
  font-size: 3.05176rem; }

.f-8 {
  font-size: 2.44141rem; }

.f-6 {
  font-size: 1.95312rem; }

.f-5 {
  font-size: 1.71875rem; }

.f-4 {
  font-size: 1.5625rem; }

.f-3 {
  font-size: 1.375rem; }

.f-2 {
  font-size: 1.25rem; }

.f-1 {
  font-size: 1.1rem; }

.f-0 {
  font-size: 1rem; }

.f--1 {
  font-size: 0.88rem; }

.f--2 {
  font-size: 0.8rem; }

/**
 * LINE HEIGHTS ----------------------- */
.lh-solid {
  line-height: 1; }

.lh-title-small {
  line-height: 1.3; }

.lh-title {
  line-height: 1.4; }

.lh-copy {
  line-height: 1.5; }

@media screen and (min-width: 48em) {
  .f-14-ns {
    font-size: 4.76837rem; }
  .f-12-ns {
    font-size: 3.8147rem; }
  .f-10-ns {
    font-size: 3.05176rem; }
  .f-8-ns {
    font-size: 2.44141rem; }
  .f-6-ns {
    font-size: 1.95312rem; }
  .f-5-ns {
    font-size: 1.71875rem; }
  .f-4-ns {
    font-size: 1.5625rem; }
  .f-3-ns {
    font-size: 1.375rem; }
  .f-2-ns {
    font-size: 1.25rem; }
  .f-1-ns {
    font-size: 1.1rem; }
  .f-0-ns {
    font-size: 1rem; }
  .f--1-ns {
    font-size: 0.88rem; }
  .f--2-ns {
    font-size: 0.8rem; } }

@media screen and (min-width: 70em) {
  .f-2-l {
    font-size: 1.25rem; }
  .f-1-l {
    font-size: 1.1rem; } }

.serif {
  font-family: "adelle", serif; }

.sans-serif {
  font-family: "adelle-sans", sans-serif; }

.measure-extra-wide {
  max-width: 64em; }

.tracked {
  letter-spacing: .1em; }

.small-caps {
  font-feature-settings: "c2sc", "smcp";
  font-variant-caps: all-small-caps;
  letter-spacing: 0.05em; }

.faux-small-caps {
  letter-spacing: 0.1em;
  text-transform: uppercase; }

.link {
  cursor: pointer;
  transition: color .15s ease-in, opacity .15s ease-in; }

.link-black {
  color: #19181a !important; }

.link-red {
  color: #bd3236 !important; }

.link-teal {
  color: #017a73 !important; }

.link:link,
.link:visited,
.link:hover,
.link:active {
  transition: color .15s ease-in, opacity .15s ease-in; }

.link:focus {
  outline: 1px dotted currentColor;
  transition: color .15s ease-in, opacity .15s ease-in; }

/**
 * WRAP AND TRUNCATE ------------------ */
.nowrap {
  white-space: nowrap; }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@media screen and (min-width: 48em) {
  .truncate-ns {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

/**
 * FONT WEIGHT ------------------------ */
.normal {
  font-weight: normal; }

.b {
  font-weight: bold; }

.fw4 {
  font-weight: 400; }

.fw7 {
  font-weight: 700; }

/**
 * FONT STYLE ------------------------- */
.i {
  font-style: italic; }

.fs-normal {
  font-style: normal; }

/**
 * TEXT TRANSFORM --------------------- */
.ttc {
  text-transform: capitalize; }

.ttl {
  text-transform: lowercase; }

.ttu {
  text-transform: uppercase; }

.ttn {
  text-transform: none; }

/**
 * VERTICAL ALIGN --------------------- */
.v-base {
  vertical-align: baseline; }

.v-mid {
  vertical-align: middle; }

.v-top {
  vertical-align: top; }

.v-btm {
  vertical-align: bottom; }

.no-scroll {
  overflow: hidden; }

.center {
  margin-right: auto;
  margin-left: auto; }

.center-children > * {
  margin: 0 auto; }

/*

   WIDTHS
   Docs: http://tachyons.io/docs/layout/widths/

   Base:
     w = width

   Modifiers
     -10  = literal value 10%
     -20  = literal value 20%
     -25  = literal value 25%
     -30  = literal value 30%
     -33  = literal value 33%
     -34  = literal value 34%
     -40  = literal value 40%
     -50  = literal value 50%
     -60  = literal value 60%
     -70  = literal value 70%
     -75  = literal value 75%
     -80  = literal value 80%
     -90  = literal value 90%
     -100 = literal value 100%

     -auto       = string value auto


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.w-10 {
  width: 10%; }

.w-20 {
  width: 20%; }

.w-25 {
  width: 25%; }

.w-30 {
  width: 30%; }

.w-33 {
  width: 33%; }

.w-34 {
  width: 34%; }

.w-40 {
  width: 40%; }

.w-50 {
  width: 50%; }

.w-60 {
  width: 60%; }

.w-70 {
  width: 70%; }

.w-75 {
  width: 75%; }

.w-80 {
  width: 80%; }

.w-90 {
  width: 90%; }

.w-100 {
  width: 100%; }

.w-auto {
  width: auto; }

@media screen and (min-width: 48em) {
  .w-10-ns {
    width: 10%; }
  .w-20-ns {
    width: 20%; }
  .w-25-ns {
    width: 25%; }
  .w-30-ns {
    width: 30%; }
  .w-33-ns {
    width: 33%; }
  .w-34-ns {
    width: 34%; }
  .w-40-ns {
    width: 40%; }
  .w-50-ns {
    width: 50%; }
  .w-60-ns {
    width: 60%; }
  .w-70-ns {
    width: 70%; }
  .w-75-ns {
    width: 75%; }
  .w-80-ns {
    width: 80%; }
  .w-90-ns {
    width: 90%; }
  .w-100-ns {
    width: 100%; }
  .w-auto-ns {
    width: auto; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .w-10-m {
    width: 10%; }
  .w-20-m {
    width: 20%; }
  .w-25-m {
    width: 25%; }
  .w-30-m {
    width: 30%; }
  .w-33-m {
    width: 33%; }
  .w-34-m {
    width: 34%; }
  .w-40-m {
    width: 40%; }
  .w-50-m {
    width: 50%; }
  .w-60-m {
    width: 60%; }
  .w-70-m {
    width: 70%; }
  .w-75-m {
    width: 75%; }
  .w-80-m {
    width: 80%; }
  .w-90-m {
    width: 90%; }
  .w-100-m {
    width: 100%; }
  .w-auto-m {
    width: auto; } }

@media screen and (min-width: 70em) {
  .w1-l {
    width: 1rem; }
  .w2-l {
    width: 2rem; }
  .w3-l {
    width: 4rem; }
  .w4-l {
    width: 8rem; }
  .w5-l {
    width: 16rem; }
  .w-10-l {
    width: 10%; }
  .w-20-l {
    width: 20%; }
  .w-25-l {
    width: 25%; }
  .w-30-l {
    width: 30%; }
  .w-33-l {
    width: 33%; }
  .w-34-l {
    width: 34%; }
  .w-40-l {
    width: 40%; }
  .w-50-l {
    width: 50%; }
  .w-60-l {
    width: 60%; }
  .w-70-l {
    width: 70%; }
  .w-75-l {
    width: 75%; }
  .w-80-l {
    width: 80%; }
  .w-90-l {
    width: 90%; }
  .w-100-l {
    width: 100%; }
  .w-auto-l {
    width: auto; } }

@media screen and (min-width: 92.5em) {
  .w-80-xl {
    width: 80%; }
  .w-90-xl {
    width: 90%; }
  .w-100-xl {
    width: 100%; } }

[data-accordion] [data-tab] {
  overflow: hidden; }

[data-accordion] input[type="checkbox"] {
  position: absolute;
  opacity: 0; }

[data-accordion] label {
  cursor: pointer;
  position: relative; }

[data-accordion] .plus-minus {
  display: inline-block;
  width: 1.5625rem;
  height: 1.5625rem;
  border: 0;
  position: relative; }

[data-accordion] .plus-minus span {
  position: absolute;
  transition: 0.25s;
  background: #b7b8ae; }

[data-accordion] .plus-minus span:first-of-type {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%; }

[data-accordion] .plus-minus span:last-of-type {
  top: 45%;
  height: 10%;
  right: 25%;
  left: 25%; }

[data-accordion] [data-content] {
  max-height: 0;
  overflow: hidden;
  transition: max-height .25s ease; }

[data-accordion] input[type="checkbox"]:checked ~ label {
  background-color: #f4f5f5; }

[data-accordion] input[type="checkbox"]:checked ~ [data-content] {
  max-height: 25em; }

[data-accordion] input[type="checkbox"]:checked ~ label .plus-minus span:first-of-type,
[data-accordion] input[type="checkbox"]:checked ~ label .plus-minus span:last-of-type {
  transform: rotate(90deg); }

[data-accordion] input[type="checkbox"]:checked ~ label .plus-minus span:last-of-type {
  left: 50%;
  right: 50%; }

.avatar-wrapper,
.avatar-dropdown-wrapper {
  position: relative; }

.avatar-wrapper {
  display: table; }

.avatar {
  border-radius: 50%;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase; }
  label .avatar {
    font-feature-settings: normal; }

.avatar-large {
  font-size: 2.44141rem;
  height: 5.5rem;
  letter-spacing: 0.025em;
  line-height: 5.5rem;
  width: 5.5rem; }

.avatar-small {
  font-size: 1.375rem;
  height: 3.35693rem;
  line-height: 3.35693rem;
  width: 3.35693rem;
  letter-spacing: 0.025em; }

.avatar[data-color='1'] {
  background: #f1a9a0; }

.avatar[data-color='2'] {
  background: #fde3a7; }

.avatar[data-color='3'] {
  background: #ffe11a; }

.avatar[data-color='4'] {
  background: #fbfb90; }

.avatar[data-color='5'] {
  background: #c8f7c5; }

.avatar[data-color='6'] {
  background: #57d1d7; }

.avatar[data-color='7'] {
  background: #c5eff7; }

.avatar[data-color='8'] {
  background: #dcc6e0; }

.avatar[data-color='9'] {
  background: #ff880c;
  color: #fff; }

.avatar[data-color='10'] {
  background: #09bc68;
  color: #fff; }

.avatar[data-color='11'] {
  background: #64048b;
  color: #fff; }

.avatar[data-color='12'] {
  background: #921646;
  color: #fff; }

@media screen and (min-width: 48em) {
  .avatar-large {
    font-size: 3.05176rem;
    height: 7.5rem;
    line-height: 7.5rem;
    width: 7.5rem; } }

.crossword-filter-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms cubic-bezier(0, 0.95, 0.25, 1); }
  .crossword-filter-wrapper.crossword-filter-shown {
    max-height: 800px;
    transition: max-height 840ms ease-out; }

.crossword-filter input[type="checkbox"] {
  display: none; }

.crossword-filter input[type="checkbox"] ~ label {
  background-color: #fff;
  border-radius: 4px; }

.crossword-filter input[type="checkbox"]:checked ~ label {
  background-color: #ffd34e; }

.dropdown-wrapper {
  position: relative; }
  .dropdown-wrapper .dropdown {
    display: none;
    box-shadow: 0 0px 7px rgba(0, 0, 0, 0.2);
    border: 1px solid #f4f5f5;
    border-radius: 3px;
    left: 0;
    position: absolute;
    z-index: 9; }
    .dropdown-wrapper .dropdown li {
      width: 100%; }
    .dropdown-wrapper .dropdown:after {
      content: '';
      height: 1.1rem;
      width: 1.1rem;
      position: absolute;
      background-color: #fff;
      top: -0.6rem;
      left: 45%;
      border-top: #f4f5f5 solid 1px;
      border-left: #f4f5f5 solid 1px;
      transform: rotate(45deg); }
  .dropdown-wrapper.dropdown-show .dropdown {
    display: block; }
  .dropdown-wrapper.dropdown-show .dropdown-toggle-show-opacity {
    opacity: 0.5; }
  .dropdown-wrapper.dropdown-show .dropdown-toggle-show-bg-gray {
    background-color: #b7b8ae; }

.filter-select-input, .filter-select-wrapper {
  position: relative; }

.filter-select-dropdown {
  background: #fff;
  border: 1px solid #b7b8ae;
  border-top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: block;
  left: 0;
  margin: 0;
  position: absolute;
  top: 4.15rem;
  visibility: visible;
  width: 100%; }
  .filter-select-dropdown .fs-label {
    font-size: 1.1rem; }
  .filter-select-dropdown .fs-option,
  .filter-select-dropdown .fs-message {
    padding: .5rem .75rem; }
  .filter-select-dropdown-content {
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto; }
    .filter-select-dropdown-content .fs-active,
    .filter-select-dropdown-content > div:not(.fs-message):hover {
      background: #ffecb4; }
  .filter-select-dropdown-content > div {
    cursor: pointer; }

.forum-shortcuts {
  margin-top: 2em;
  background: #fff;
  bottom: 0;
  display: none;
  text-align: right;
  z-index: 50; }
  .forum-shortcuts-stuck {
    width: 90%;
    left: 0;
    bottom: 0;
    position: fixed;
    padding: 0 5%; }
  .forum-shortcuts li {
    float: right;
    margin: 0; }
    .forum-shortcuts li:first-of-type {
      margin-right: -.5em; }

@media (min-width: 60em) {
  .forum-shortcuts {
    background: none;
    width: 20.45455%;
    padding: 0;
    float: right;
    margin-right: -22.72727%;
    margin-top: -7rem; }
    .forum-shortcuts-stuck {
      left: auto;
      bottom: 1em;
      right: 2.5%;
      width: 15.25424%;
      margin-right: 0; }
    .forum-shortcuts a {
      padding: 0.5rem; }
    .forum-shortcuts li,
    .forum-shortcuts li:first-of-type {
      margin: 0 -0.5rem;
      float: none; } }

@media screen and (min-width: 70em) {
  .forum-shortcuts-stuck {
    right: 10%; } }

.pending-crossword .icon-submission {
  height: 2.14844rem;
  width: 2.14844rem; }
  .pending-crossword .icon-submission-pending, .pending-crossword .icon-submission-submitted-pending {
    background-image: svg-load("../icons/eye-hidden.svg", fill=#b7b8ae) !important; }
  .pending-crossword .icon-submission-pending:hover, .pending-crossword .icon-submission-submitted-pending:hover {
    background-image: svg-load("../icons/eye-hidden.svg", fill=#bd3236) !important; }

.catcher-is-next + div.rye {
  display: none; }

.message-left + .message-left,
.message-right + .message-right {
  margin-top: 0.64rem; }

.message-left .avatar-wrapper {
  float: left; }

.message-right .avatar-wrapper {
  float: right; }

.message-left + .message-left .avatar-wrapper,
.message-right + .message-right .avatar-wrapper {
  visibility: hidden; }

.message-body {
  border-radius: 4px; }

.message-left .message-body {
  background-color: rgba(255, 236, 180, 0.2);
  border-color: #ffecb4;
  float: left;
  margin-left: 1rem; }

.message-right .message-body {
  background-color: rgba(119, 208, 178, 0.2);
  border-color: #77d0b2;
  float: right;
  margin-right: 1rem;
  text-align: right; }

.message-header {
  top: 0.8rem;
  position: absolute; }

.message-left .message-header {
  left: 0.8rem; }

.message-right .message-header {
  right: 0.8rem; }

.message-body time,
.message-body h4 {
  margin-left: .2rem; }

.message-body p {
  line-height: 1.5;
  margin-bottom: 0; }
  .message-body p + p {
    margin-top: 1.5em; }

.schive-mobile-menu {
  background-color: #19181a;
  bottom: 0;
  display: block;
  left: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transform: translate3d(-300px, 0, 0);
  transition: transform 0.3s ease;
  width: 300px;
  z-index: 500; }

.schive-mobile-menu ul > li {
  cursor: pointer;
  display: block; }

.schive-mobile-menu ul > li:hover,
.schive-mobile-menu ul > li:active {
  background-color: #017a73; }

.schive-mobile-menu-show {
  transform: translate3d(0, 0, 0); }

.modal {
  height: 0;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  width: 0; }

.modal:target,
.modal-visible {
  height: auto;
  opacity: 1;
  visibility: visible;
  width: auto; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.modal-inner {
  background-color: #fff;
  box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.32), 0 0.375rem 0.625rem rgba(0, 0, 0, 0.36);
  margin: 4% auto;
  min-width: 28rem;
  overflow: visible;
  position: relative;
  z-index: 20; }

.modal-inner header {
  padding-top: 2.44141rem; }

.modal-narrow {
  padding: 0 1.95312rem 1.5625rem;
  max-width: 28rem; }

.modal-medium {
  padding: 0 2.44141rem 1.95312rem;
  max-width: 34rem; }

.modal-wide {
  padding: 0 2.44141rem 1.95312rem;
  max-width: 38rem; }

.modal-content {
  position: relative; }

.modal-close {
  position: absolute;
  right: 1.5em;
  top: 1.5em; }

.modal-icon {
  display: block !important;
  height: 11.64153rem;
  margin: 0 auto;
  width: 11.64153rem; }

.progress-circle,
.progress-circle svg {
  display: block; }

.progress-circle circle {
  stroke-width: .15rem; }

.progress-circle-max-height .progress-circle {
  max-height: 4rem; }

.progress-circle-bg-white circle:nth-of-type(2n-1) {
  stroke: #fff; }

.progress-circle-wrapper {
  position: relative; }
  .progress-circle-wrapper .progress {
    color: #b7b8ae;
    line-height: 1;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    width: 100%; }

.spinner {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative; }
  .spinner .spinner-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative; }
    .spinner .spinner-cube::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(25, 24, 26, 0.6);
      animation: fold-spinner 2.4s infinite linear both;
      transform-origin: 100% 100%; }
  .spinner--light .spinner-cube::before {
    background-color: #f4f5f5; }
  .spinner .spinner-cube-2 {
    transform: rotateZ(90deg); }
    .spinner .spinner-cube-2::before {
      animation-delay: 0.3s; }
  .spinner .spinner-cube-3 {
    transform: rotateZ(180deg); }
    .spinner .spinner-cube-3::before {
      animation-delay: 0.6s; }
  .spinner .spinner-cube-4 {
    transform: rotateZ(270deg); }
    .spinner .spinner-cube-4::before {
      animation-delay: 0.9s; }

@keyframes fold-spinner {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }

.stripe-iframe-input,
.stripe-input > input[type=text] {
  background: #f4f5f5;
  border: 1px solid #b7b8ae;
  border-radius: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 1;
  min-height: 2rem;
  outline: none;
  padding: .625rem .75rem;
  vertical-align: middle;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none; }

.stripe-confirm-payment-box-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  top: -1rem; }

.stripe-confirm-payment-box {
  background: white;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  margin: 0 auto;
  max-width: 34rem;
  overflow: visible;
  position: relative;
  z-index: 20; }

@media screen and (min-width: 1120px) {
  .stripe-confirm-payment-box-wrapper {
    top: -2rem; } }

.subscription-plan {
  background-color: #f4f5f5;
  border: 3px solid transparent;
  transition: transform .2s ease-in-out; }
  .subscription-plan:hover {
    transform: scale(1.05); }
  .subscription-plan--selected {
    border: 3px solid #017a73;
    transform: scale(1.05);
    z-index: 5; }
  .subscription-plan .icon-subscription-type {
    height: 7.45058rem;
    width: 7.45058rem; }

.subscription-header .icon-subscription-type {
  height: 9.31323rem;
  width: 9.31323rem; }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .subscription-plan {
    padding-left: 11.36364%;
    padding-right: 11.36364%;
    width: 100%; }
    .subscription-plan .icon-subscription-type {
      height: 11.64153rem;
      width: 11.64153rem; }
  .choose-subscription-plan {
    flex-direction: column;
    align-items: center; } }

@media screen and (min-width: 760px) {
  .subscription-header .icon-subscription-type {
    height: 11.64153rem;
    width: 11.64153rem; } }

@media screen and (min-width: 960px) and (max-width: 1540px) {
  .subscription-header .icon-subscription-type {
    height: 9.31323rem;
    width: 9.31323rem; } }

@media screen and (min-width: 1400px) {
  .choose-subscription-plan {
    flex-direction: row; }
  .subscription-plan {
    margin-bottom: 0;
    width: 49.15254%; }
  .subscription-plan + .subscription-plan {
    margin-left: 1.69492%; } }

@media screen and (min-width: 1650px) {
  .subscription-plan .icon-subscription-type {
    height: 11.64153rem;
    width: 11.64153rem; } }

.tooltip {
  display: inline-block;
  position: relative;
  text-align: center; }
  .tooltip-top, .tooltip-bottom {
    background-color: #19181a;
    border-radius: 4px;
    color: #fff;
    display: none;
    font-size: 0.88rem;
    font-family: "adelle-sans", sans-serif;
    font-style: normal;
    left: 50%;
    min-width: 145px;
    padding: 0.512rem 0.64rem;
    position: absolute;
    text-decoration: none;
    z-index: 9; }
    .tooltip-top:after, .tooltip-bottom:after {
      content: '';
      height: 0.9rem;
      width: 0.9rem;
      position: absolute;
      background-color: #19181a;
      left: 45%;
      border-top: #19181a solid 1px;
      border-left: #19181a solid 1px;
      transform: rotate(45deg); }
  .tooltip-top {
    top: -20px;
    transform: translate(-50%, -100%); }
    .tooltip-top:after {
      bottom: -0.45rem; }
  .tooltip-bottom {
    top: 40px;
    transform: translate(-50%, 0); }
    .tooltip-bottom:after {
      top: -0.45rem; }
  .tooltip:hover .tooltip-top,
  .tooltip:hover .tooltip-bottom {
    display: block; }

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -0.16777rem;
  margin-right: -0.16777rem; }

.tabs .tab {
  display: block;
  padding: 0 0.16777rem; }

.tabs .tab > a {
  display: block;
  padding: 0.64rem 1rem;
  text-align: center;
  text-decoration: none; }

@media screen and (min-width: 48em) {
  .tabs {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .tabs .tab {
    width: auto; } }

.tos-nav {
  list-style: decimal inside none; }
  .tos-nav li {
    list-style: inherit; }
    .tos-nav li > a {
      color: inherit;
      text-decoration: none; }

@media screen and (min-width: 48em) {
  .tos-nav {
    list-style: decimal outside none;
    margin-left: -0.2rem; }
    .tos-nav li {
      padding-left: 0.2rem; } }

/**
 * REGULAR ICONS ---------------------- */
.icon {
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: middle; }

.icon--large {
  height: 32px;
  width: 32px; }

.icon--medium {
  height: 24px;
  width: 24px; }

.icon--small {
  height: 16px;
  width: 16px; }

.icon-error {
  background-image: url(../img/error.svg); }

.icon-featured {
  display: block;
  height: 16.00711rem;
  margin: 0 auto;
  width: 16.00711rem; }
  .icon-featured-creative {
    background-image: url(../img/featured_creative.svg); }
  .icon-featured-discuss {
    background-image: url(../img/featured_discuss.svg); }
  .icon-featured-improve {
    background-image: url(../img/featured_improve.svg); }

.icon-facebook-logo {
  background-image: url(../img/facebook_white_f.svg);
  height: 24px;
  margin-right: 12px;
  margin-top: -4px;
  width: 24px; }

.icon-submission {
  height: 2.14844rem;
  width: 2.14844rem; }
  .icon-submission-error {
    background-image: svg-load("../icons/submission_error.svg"); }
  .icon-submission-correct {
    background-image: svg-load("../icons/submission_correct.svg"); }
  .icon-submission-pending {
    background-image: svg-load("../icons/submission_pending.svg"); }
  .icon-submission-submitted-error {
    background-image: svg-load("../icons/submitted_error.svg"); }
  .icon-submission-submitted-correct {
    background-image: svg-load("../icons/submitted_correct.svg"); }
  .icon-submission-submitted-pending {
    background-image: svg-load("../icons/submitted_pending.svg"); }

.icon-crossword-new {
  height: 2.14844rem;
  width: 2.14844rem;
  background-image: svg-load("../icons/crossword_new.svg"); }

.icon-shared-completed {
  display: block;
  height: 11.64153rem;
  margin: 0 auto;
  width: 11.64153rem; }

.icon-author {
  background-image: svg-load("../icons/forum_author.svg"); }

.icon-moderator {
  background-image: svg-load("../icons/forum_moderator.svg"); }

.icon-search {
  background-image: svg-load("../icons/search.svg", fill=#19181a); }

.icon-search--white {
  background-image: svg-load("../icons/search.svg", fill=#fff); }

.icon-last-active {
  background-image: svg-load("../icons/last-active.svg", fill=#19181a); }

.icon-last-active--red {
  background-image: svg-load("../icons/last-active.svg", fill=#bd3236); }

.icon-last-active--white {
  background-image: svg-load("../icons/last-active.svg", fill=#fff); }

.icon-pinned {
  background-image: svg-load("../icons/pinned.svg", fill=#bd3236); }

.icon-locked {
  background-image: svg-load("../icons/locked.svg", fill=#b7b8ae); }

.icon-pinned,
.icon-locked {
  height: 1.5625rem;
  width: 1.5625rem; }

.icon-pinned + .icon-locked {
  margin-left: -0.88rem; }

.icon-up {
  background-image: svg-load("../icons/up.svg", fill=#bd3236); }

.icon-down {
  background-image: svg-load("../icons/down.svg", fill=#bd3236); }

.icon-forum-shortcut {
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em; }

.icon-birthday {
  background-image: svg-load("../icons/birthday.svg", fill=#19181a); }

.icon-location {
  background-image: svg-load("../icons/location.svg", fill=#19181a); }

.icon-subscription-type {
  height: 11.64153rem;
  width: 11.64153rem; }
  .icon-subscription-type--free {
    background-image: url(../img/subscription_free.svg); }
  .icon-subscription-type--month {
    background-image: url(../img/subscription_monthly.svg); }
  .icon-subscription-type--annual {
    background-image: url(../img/subscription_annual.svg); }
  .icon-subscription-type--annual--white {
    background-image: url(../img/subscription_annual_white.svg); }
  .icon-subscription-type--small {
    height: 7.45058rem;
    width: 7.45058rem; }
  .icon-subscription-type--large {
    height: 14.55192rem;
    width: 14.55192rem; }

.icon-subscription-completed {
  display: block;
  height: 14.55192rem;
  margin: 0 auto;
  width: 14.55192rem; }

.icon-invoice {
  height: 24px;
  width: 24px; }
  .icon-invoice--ok {
    background-image: svg-load("../icons/submission_correct.svg"); }
  .icon-invoice--error {
    background-image: svg-load("../icons/submission_error.svg"); }

.icon-dropdown {
  background-image: svg-load("../icons/collapse.svg", fill=#19181a);
  height: 10px;
  width: 14px; }

.icon-close {
  background-image: svg-load("../icons/close.svg", fill=#19181a);
  height: 15px;
  width: 15px; }

.icon-close--white {
  background-image: svg-load("../icons/close.svg", fill=#fff);
  height: 15px;
  width: 15px; }

.icon-expand {
  background-image: svg-load("../icons/expand.svg", fill=#bd3236);
  height: 0.8rem;
  width: 0.704rem; }

.icon-copy {
  background-image: svg-load("../icons/copy.svg", fill=#bd3236);
  height: 20px;
  width: 20px; }

/**
 * SPRITE ICONS ----------------------- */
svg {
  display: block; }

.s-icon-menu {
  display: inline-block;
  color: #19181a;
  height: 35px;
  width: 36px; }

.s-icon-star-full,
.s-icon-star-empty {
  display: inline-block;
  height: 1.1rem;
  margin-right: 0.0859rem;
  width: 1.1rem; }

.s-icon-star--medium {
  height: 1.25rem;
  width: 1.25rem; }

.s-icon-star--large {
  height: 1.5625rem;
  width: 1.5625rem; }

.s-icon-star-white .s-icon-star-full,
.s-icon-star-white .s-icon-star-empty {
  color: #fff; }

.s-icon-star-gray .s-icon-star-full,
.s-icon-star-gray .s-icon-star-empty {
  color: #b7b8ae; }

.s-icon-notification {
  height: 2.44141rem;
  width: 2.44141rem; }
  .s-icon-notification-messaged {
    fill: rgba(119, 208, 178, 0.2);
    color: #bb6c67; }
  .s-icon-notification-subscription {
    color: #77d0b2; }
  .s-icon-notification--small {
    height: 1.25rem;
    width: 1.25rem; }

.s-icon-category {
  color: #19181a;
  height: 1rem;
  width: 1rem; }

.s-icon-category-white {
  color: #fff; }

.s-icon-category-gray {
  color: #b7b8ae; }

/* DUMPSTER */
.icon-button-hide {
  background-image: svg-load("../icons/button_hide.svg"); }

.icon-button-show {
  background-image: svg-load("../icons/button_show.svg"); }

.icon-button-back {
  background-image: svg-load("../icons/button_back.svg"); }

.icon-button-forward {
  background-image: svg-load("../icons/button_forward.svg"); }

.icon-discuss {
  background-image: svg-load("../icons/discuss.svg", fill=#19181a);
  height: 16px;
  width: 20px; }

.icon-share {
  background-image: svg-load("../icons/share.svg", fill=#19181a);
  height: 16px;
  width: 20px; }

.icon-submissions-list {
  background-image: svg-load("../icons/submissions.svg", fill=#19181a);
  height: 16px;
  width: 24px; }

.icon-download {
  background-image: svg-load("../icons/download.svg", fill=#19181a); }

.icon-help {
  background-image: svg-load("../icons/help.svg", fill=#19181a);
  height: 16px;
  width: 16px; }

.icon-hide {
  background-image: svg-load("../icons/eye-hidden.svg", fill=#19181a);
  height: 28px;
  width: 28px; }

.icon-messages {
  background-image: svg-load("../icons/messaged.svg", fill=#19181a); }

.icon-messages--white {
  background-image: svg-load("../icons/messaged.svg", fill=#fff); }

.icon-notifications {
  background-image: svg-load("../icons/notifications.svg", fill=#19181a); }

.icon-notifications--white {
  background-image: svg-load("../icons/notifications.svg", fill=#fff); }

.icon-settings {
  background-image: svg-load("../icons/settings.svg", fill=#19181a); }

.icon-settings--white {
  background-image: svg-load("../icons/settings.svg", fill=#fff); }

.icon-subscription {
  background-image: svg-load("../icons/subscription.svg", fill=#19181a); }

.icon-subscription--white {
  background-image: svg-load("../icons/subscription.svg", fill=#fff); }

.icon-clear {
  background-image: svg-load("../icons/borders_clear.svg", fill=#19181a);
  height: 20px;
  width: 20px; }

.icon-errors {
  background-image: svg-load("../icons/errors.svg", fill=#19181a);
  height: 20px;
  width: 21px; }

/** --------- **/
.icon-discuss-list,
.icon-discuss-title {
  display: inline-block;
  fill: currentColor;
  vertical-align: middle; }

.icon-avatar-decoration {
  height: 1.71875rem;
  position: absolute;
  right: -0.85938rem;
  top: -0.16777rem;
  width: 1.71875rem; }

.wc-square {
  border: 1px solid #19181a;
  box-shadow: none;
  float: left;
  margin: -1px 0 0 -1px;
  position: relative;
  text-align: center;
  text-transform: uppercase; }

/**
 * Square types */
.wc-blank {
  border-color: rgba(255, 255, 255, 0); }

.wc-block,
.wc-block:hover {
  background-color: #19181a;
  cursor: not-allowed; }

.wc-clues > div:not(:first-child) {
  border-top: 1px solid #19181a; }

.wc-clue {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1.1;
  text-transform: uppercase;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto; }

.wc-text {
  padding: 0 2px; }

.wc-clue-square:hover {
  cursor: pointer; }

.wc-crossword-grid {
  margin-bottom: 1.5625rem;
  margin-right: 1.5625rem; }

.wc-crossword-grid [data-number]:before {
  color: #19181a;
  content: attr(data-number);
  font-size: 0.88rem;
  left: 8%;
  line-height: 1;
  position: absolute;
  top: 8%; }

/**
 * Active squares and words */
.wc-active-word {
  background-color: rgba(255, 211, 78, 0.6); }

.wc-active-square {
  background-color: #ffd34e; }

.wc-active-word.wc-reversed {
  background-color: rgba(119, 208, 178, 0.2); }

.wc-active-square.wc-reversed {
  background-color: #77d0b2; }

.wc-blink {
  background-color: rgba(1, 122, 115, 0.6);
  box-shadow: 0 0 30px rgba(1, 122, 115, 0.4); }

.wc-blink-error {
  background-color: rgba(189, 50, 54, 0.6);
  box-shadow: 0 0 30px rgba(189, 50, 54, 0.4); }

.wc-fade {
  transition: background-color .25s ease, box-shadow .25s ease; }

/**
 * Clue list */
.wc-clue-list-wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%; }
  .wc-clue-list-wrapper.wc-clue-list-inline {
    flex-flow: column wrap; }

.wc-clue-list-wrapper h2 {
  font-size: 0.8rem;
  margin-bottom: 0.26214rem; }

.wc-clue-list-horizontal,
.wc-clue-list-vertical {
  flex: 1 1 20rem;
  flex-flow: row wrap;
  max-width: 20rem;
  min-width: 15rem; }

.wc-clue-list-inline .wc-clue-list-horizontal,
.wc-clue-list-inline .wc-clue-list-vertical {
  flex: 1 1 auto;
  max-width: none;
  min-width: auto; }

.wc-clue-list-inline .wc-clue-list li {
  display: inline-block; }

.wc-clue-list {
  margin-bottom: 1.375rem;
  margin-left: -0.2em; }

.wc-clue-list li {
  cursor: pointer;
  display: block;
  padding: 0.2em 0.4em; }

.wc-clue-list li:before {
  content: attr(data-number) ". ";
  font-weight: 700; }

.wc-clue-list li:after {
  content: " (" attr(data-length) ")"; }

.wc-clue-list li.selected {
  background-color: #ffd34e; }

/**
 * Input listener */
.wc-crossword-grid {
  position: relative; }

#wordcross .wc-input-listener {
  height: 0;
  max-height: 0;
  max-width: 0;
  min-height: 0;
  min-width: 0;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
  will-change: left, top;
  z-index: 9; }

/**
 * Arrows */
.wc-crossword-grid [class*=arrow-] {
  background-image: url(../img/atlas.svg);
  background-repeat: no-repeat; }

.wc-crossword-grid .arrow-down {
  background-position: -54px 0; }

.wc-crossword-grid .arrow-down-bend-left {
  background-position: -108px -108px; }

.wc-crossword-grid .arrow-down-bend-right {
  background-position: 0 -54px; }

.wc-crossword-grid .arrow-left {
  background-position: -54px -54px; }

.wc-crossword-grid .arrow-left-bend-down {
  background-position: -108px 0; }

.wc-crossword-grid .arrow-left-bend-up {
  background-position: -108px -54px; }

.wc-crossword-grid .arrow-left-high {
  background-position: 0 -108px; }

.wc-crossword-grid .arrow-left-low {
  background-position: -54px -108px; }

.wc-crossword-grid .arrow-right {
  background-position: 0 0; }

.wc-crossword-grid .arrow-right-bend-down {
  background-position: -162px 0; }

.wc-crossword-grid .arrow-right-bend-up {
  background-position: -162px -54px; }

.wc-crossword-grid .arrow-right-high {
  background-position: -162px -108px; }

.wc-crossword-grid .arrow-right-low {
  background-position: 0 -162px; }

.wc-crossword-grid .arrow-up {
  background-position: -54px -162px; }

.wc-crossword-grid .arrow-up-bend-left {
  background-position: -108px -162px; }

.wc-crossword-grid .arrow-up-bend-right {
  background-position: -162px -162px; }

/**
 * SOLVERBAR -------------------------- */
.wc-solverbar {
  background: #ffecb4;
  bottom: 0;
  display: none;
  left: 0;
  padding: 1.375rem 0;
  position: fixed;
  width: 100%;
  z-index: 500; }

.wc-solverbar .wc-solverbar-clue {
  width: 74.57627%;
  float: left;
  margin-right: 1.69492%; }

.wc-solverbar .wc-solverbar-actions {
  width: 23.72881%;
  float: left;
  margin-right: 1.69492%;
  float: right;
  margin-right: 0;
  text-align: right; }

.wc-solverbar .wc-solverbar-clue-id {
  font-weight: 700; }

.wc-solverbar .wc-solverbar-clue-length {
  color: #bd3236; }

.wc-solverbar .wc-solverbar-action {
  background: #ffecb4;
  border: none;
  color: #bd3236;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  zoom: 1; }
  .wc-solverbar .wc-solverbar-action:visited {
    background-color: inherit;
    color: inherit; }

/**
 * GRID SETTINGS ---------------------- */
/**
 * PLACEHOLDERS ----------------------- */
.wc-row:after, .form-group:after {
  content: '';
  display: table;
  clear: both; }

.tooltip-top, .tooltip-bottom, .keyboard-shortcut-list dt, .button, .button-action {
  font-feature-settings: "c2sc", "smcp";
  font-variant-caps: all-small-caps;
  letter-spacing: 0.05em; }

.wc-clue-list-wrapper h2, .main-nav-item a {
  letter-spacing: 0.1em;
  text-transform: uppercase; }

.filter-select-dropdown .fs-label {
  font-family: "adelle", serif;
  font-weight: 700; }

.wc-clue-list-wrapper h2 {
  font-family: "adelle-sans", sans-serif;
  font-weight: 700; }

/**
 *
 * PAGE STYLES ------------------------ */
*,
*:after,
*:before {
  margin: 0;
  padding: 0; }

html,
body {
  width: 100%;
  height: 100%; }

body {
  background-color: #fff;
  color: #19181a; }

body.sticky-footer {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }
  body.sticky-footer .main-content {
    flex: 1 0 auto; }

ul,
li {
  list-style: none; }

label,
button,
.button {
  cursor: pointer; }

.overlay {
  background-color: rgba(25, 24, 26, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .2s ease;
  z-index: 500; }

.container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%; }

.img-as-bg {
  height: 100%;
  left: 0;
  object-fit: cover;
  top: 0;
  width: 100%;
  z-index: 0; }

.pointer:hover {
  cursor: pointer; }

/**
 * BUTTONS ---------------------------- */
.btn {
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  padding: 1rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  zoom: 1; }
  .btn:visited {
    background-color: inherit;
    color: inherit; }

.btn-default {
  background-color: #f4f5f5;
  color: inherit; }
  .btn-default:hover, .btn-default:active {
    background-color: #b7b8ae;
    color: inherit; }
  .btn-default.disabled, .btn-default:disabled {
    background-color: #f4f5f5 !important;
    color: #a4acac !important;
    cursor: not-allowed;
    pointer-events: none; }

.btn-primary {
  background-color: #ffd34e;
  color: #19181a; }
  .btn-primary:hover, .btn-primary:active {
    background-color: #ffecb4;
    color: inherit; }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #f4f5f5;
    color: #a4acac;
    cursor: not-allowed;
    pointer-events: none; }

.btn-secondary {
  background-color: #017a73;
  color: #fff; }
  .btn-secondary:hover, .btn-secondary:active {
    background-color: #77d0b2;
    color: #fff; }

.btn-warning {
  background-color: #bd3236;
  color: #fff;
  font-size: 1rem;
  text-decoration: none; }
  .btn-warning:visited {
    background-color: #bd3236;
    color: #fff; }
  .btn-warning:hover, .btn-warning:active {
    background-color: #bd3236;
    color: #fff;
    opacity: 0.5; }

.btn-black {
  background-color: #19181a;
  color: #fff; }
  .btn-black:visited {
    background-color: #19181a;
    color: #fff; }
  .btn-black:hover, .btn-black:active {
    background-color: #5a6161; }

.btn-facebook {
  background-color: #4267b2;
  border-radius: 50px;
  color: #fff;
  padding: 1rem 2.44141rem;
  white-space: nowrap; }
  .btn-facebook:visited, .btn-facebook:hover, .btn-facebook:focus {
    background-color: #365899;
    color: #fff; }

.btn-copy {
  background-color: #ffd34e;
  color: #19181a;
  transition: color .15s ease;
  width: 7.45058rem; }
  .btn-copy:hover {
    background-color: #ffecb4; }
  .btn-copy-faded {
    color: transparent; }

.btn-pricing-default {
  background-color: #19181a;
  color: #fff; }
  .btn-pricing-default:visited {
    background-color: #19181a;
    color: #fff; }
  .btn-pricing-default:hover, .btn-pricing-default:active {
    background-color: #5a6161; }

.btn-pricing-primary {
  background-color: #19181a;
  color: #fff;
  font-size: 1.25rem;
  padding: 1.25rem 1.75rem; }
  .btn-pricing-primary:visited {
    background-color: #19181a;
    color: #fff; }
  .btn-pricing-primary:hover, .btn-pricing-primary:active {
    background-color: #5a6161; }

/**
 * UTIL ------------------------------- */
.rounded {
  height: auto;
  border-radius: 50%;
  border: 0 none; }

.line-behind {
  position: relative;
  z-index: 1; }
  .line-behind:before {
    border-top: 1px solid #b7b8ae;
    bottom: 0;
    content: '';
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    z-index: -1; }
  .line-behind span {
    background: #fff;
    padding: 0 1.375rem; }

/**
 * SUBMISSIONS ------------------------ */
[data-submissions-complete] ul,
[data-submissions-incomplete] ul {
  display: block;
  float: left;
  width: 35.29412%; }

[data-submissions-complete] ul:last-child,
[data-submissions-incomplete] ul:last-child {
  width: 29.41176%; }

[data-submissions-complete] li,
[data-submissions-incomplete] li {
  font-size: 1rem; }

[data-submissions-incomplete] ul > li:first-child {
  line-height: 1;
  padding: .4rem 0 .1rem; }

/**
 * PAGINATION ------------------------- */
.pagination {
  margin-top: 1.375rem;
  text-align: center; }
  .pagination .icon {
    height: 20px;
    width: 20px; }
  .pagination > span:nth-child(2):before {
    color: #bd3236;
    content: '|';
    margin: 0 0.512rem; }

/**
 * TYPOGRAPHY ------------------------- */
body,
input,
select,
textarea,
button {
  font-family: "adelle-sans", sans-serif;
  font-size: medium; }

label {
  font-size: 0.8rem; }

p {
  font-size: 1rem;
  line-height: 1.45;
  margin-bottom: 1.45rem; }
  .title p {
    margin-bottom: 0; }

.help-text {
  color: #017a73;
  font-feature-settings: normal;
  font-size: 0.8rem;
  font-style: italic; }

.timestamp {
  color: #b7b8ae;
  font-family: "adelle", serif;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300; }
  .is-new .timestamp,
  .crossword-header .timestamp {
    color: #5a6161; }
    .is-new .timestamp.alert,
    .crossword-header .timestamp.alert {
      color: #bd3236; }

.alert {
  color: #bd3236; }

.bullet:before {
  content: '•';
  font-weight: 700; }

a.no-link,
a.no-link:visited,
a.no-link:hover,
a.no-link:active {
  color: inherit;
  text-decoration: inherit; }

/**
 * NAVIGATION ------------------------- */
.main-nav-item {
  border-top: .1875rem solid transparent;
  line-height: 3.54443rem;
  padding: 3.8147rem 0.5632rem 0.512rem; }
  .main-nav-item a {
    color: #19181a;
    font-weight: 700;
    text-decoration: none;
    font-size: 0.88rem;
    letter-spacing: 0.1em; }
    .main-nav-item a:hover {
      color: #77d0b2; }
  .main-nav-item.active {
    border-color: #77d0b2; }
    .main-nav-item.active > a {
      color: #77d0b2; }

.profile-dropdown-wrapper {
  position: relative;
  vertical-align: -1.3rem; }
  .profile-dropdown-wrapper > .avatar-dropdown-wrapper {
    cursor: pointer; }

.profile-dropdown {
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  display: none;
  min-height: 9.31323rem;
  position: absolute;
  right: -1.05347rem;
  text-align: left;
  top: 3.99693rem;
  width: 25.0111rem;
  z-index: 9; }
  .profile-dropdown.schive-nav-show {
    display: block; }

.profile-dropdown:after {
  border: solid transparent;
  border-bottom-color: #fff;
  border-width: 1.1rem;
  bottom: 100%;
  content: '';
  height: 0;
  margin-bottom: -0.16777rem;
  margin-left: -1.1rem;
  position: absolute;
  right: 1.71875rem;
  width: 0; }

.notification-bubble {
  background: #bd3236;
  border-radius: 50%;
  color: #fff;
  font-size: 0.704rem;
  font-weight: 400;
  height: 1.71875rem;
  line-height: 1.71875rem;
  position: absolute;
  right: -0.85938rem;
  text-align: center;
  top: -0.16777rem;
  width: 1.71875rem; }

/**
 * FRONT PAGE ----------------------------- */
.landing-page .banner img {
  max-height: 320px; }

.landing-page-header {
  z-index: 400;
  position: absolute;
  left: 0;
  right: 0; }

.landing-page-header .logo-container {
  justify-content: flex-start; }

.landing-page-header .logo-type {
  text-align: left !important; }

.landing-page-header .logo {
  color: #fff; }
  .landing-page-header .logo > br {
    display: inline-block !important; }

.landing-page-header .s-icon-menu {
  color: #fff; }

.landing-page-section {
  padding-top: 11.64153rem;
  padding-bottom: 11.64153rem; }

.landing-page-inset {
  height: 22.73737rem;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1; }

.landing-page-illustration {
  align-self: center;
  flex: 0 0 auto;
  max-width: 100%; }
  .landing-page-illustration img {
    display: block;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    max-height: 400px;
    width: auto; }

.landing-rounded {
  border-radius: .5rem; }

.landing-shadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); }

@media screen and (min-width: 48em) {
  .landing-page .banner-container,
  .landing-page .banner {
    min-height: 95vh; }
  .landing-page .banner {
    left: 0;
    top: 0;
    height: 95%; }
  .landing-page .banner img {
    max-height: 100%; } }

@media screen and (min-width: 48em) and (max-width: 70em) {
  .landing-page-illustration img {
    max-height: 638px; } }

@media screen and (min-width: 70em) {
  .landing-page-illustration {
    flex: 0 0 45%;
    max-width: none; }
    .landing-page-illustration img {
      max-height: 400px; } }

@media screen and (min-width: 92.5em) {
  .landing-page-illustration {
    flex: 0 0 45%;
    max-width: none; }
    .landing-page-illustration img {
      max-height: 638px; } }

/**
 * LISTS ------------------------------ */
.list-item {
  border-bottom: 1px solid #f4f5f5;
  padding-bottom: 1.1rem;
  padding-left: 0;
  padding-top: 1.1rem; }

.list-item:last-child {
  border-bottom: 0; }

.list-item--small {
  padding: 1.1rem 1.25rem; }

.list-item--highlight {
  background-color: rgba(255, 236, 180, 0.2);
  border-bottom: 2px solid #fff;
  margin-right: -2.44141rem;
  padding-right: 2.44141rem; }

.list-item a,
.list-item a:visited,
.list-item a:hover,
.list-item a:active {
  color: inherit;
  text-decoration: inherit; }

.keyboard-shortcut-list dt {
  color: #5a6161;
  float: left; }
  .keyboard-shortcut-list dt:after {
    content: "–";
    margin: 0 .25em; }

/**
 * FILTERS ---------------------------- */
.filters input[type="radio"],
.filters input[type="checkbox"] {
  display: none; }

.filters input[type="radio"]:checked + label,
.filters input[type="checkbox"]:checked + label {
  color: #bd3236;
  fill: #bd3236; }

.filters label {
  color: #5a6161;
  fill: #b7b8ae;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.428;
  margin: 0;
  text-transform: none; }

/**
 * BUTTONS ---------------------------- */
.button {
  border: 0;
  background-color: #f4f5f5;
  color: #19181a;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  padding: 1em 1.5em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  zoom: 1;
  cursor: pointer; }
  .button:hover, .button:active {
    background-color: #b7b8ae; }
  .button.disabled, .button:disabled {
    background-color: #f4f5f5 !important;
    color: #a4acac !important;
    pointer-events: none; }
  .button:-moz-focus-inner {
    border: 0;
    padding: 0; }

.button-featured {
  background: none;
  border: 2px solid #fff;
  color: #fff !important; }
  .button-featured:hover {
    border-color: #ffd34e;
    background: #ffd34e;
    color: #19181a !important; }

.button-primary {
  background-color: #ffd34e;
  color: #19181a; }
  .button-primary:hover {
    background-color: #ffecb4; }

.button-filter {
  background-color: #bd3236;
  color: #fff; }
  .button-filter:hover, .button-filter:active {
    background-color: #bb6c67; }

.button-action {
  color: #bd3236;
  font-size: 1rem;
  text-decoration: none; }

/**
 * PROFILE ---------------------------- */
.profile-meta,
.profile-about {
  padding-left: 5.5rem; }

@media screen and (min-width: 48em) {
  .profile-meta {
    padding-left: 0; }
  .profile-about {
    padding-left: 7.5rem; } }

/**
 * FORUM ------------------------------ */
.forum-post {
  padding: 3.05176rem 0;
  position: relative; }
  .forum-post a,
  .forum-post a:visited {
    color: #bd3236;
    transition: color .15s ease-in; }
  .forum-post a:hover,
  .forum-post a:active {
    color: #bb6c67; }
  .forum-post-avatar {
    left: 0;
    position: absolute;
    top: 3.05176rem; }
    .forum-post-avatar .avatar-wrapper {
      margin: 0 auto; }
  .forum-post-title {
    height: 3.35693rem; }
    .forum-post-title h2 {
      font-size: 1.25rem;
      line-height: 3.35693rem; }
  .forum-post-sidebar {
    width: 5.88235%;
    float: right;
    margin-right: 0;
    color: #b7b8ae; }
  .forum-post[data-level='0'], .forum-post[data-level='1'] {
    margin-left: 0;
    padding-left: 11.76471%; }
    .forum-post[data-level='0'] .forum-post-title,
    .forum-post[data-level='0'] .forum-post-content, .forum-post[data-level='1'] .forum-post-title,
    .forum-post[data-level='1'] .forum-post-content {
      width: 88.23529%; }
    .forum-post[data-level='0'] .comment-icon,
    .forum-post[data-level='0'] .forum-post-avatar, .forum-post[data-level='1'] .comment-icon,
    .forum-post[data-level='1'] .forum-post-avatar {
      width: 11.76471%; }
    .forum-post[data-level='0'] .reply-form-wrapper, .forum-post[data-level='1'] .reply-form-wrapper {
      padding-right: 5.88235%; }
  .forum-post[data-level='2'], .forum-post[data-level='3'], .forum-post[data-level='4'], .forum-post[data-level='5'], .forum-post[data-level='6'] {
    padding-left: 5.88235%; }
  .forum-post[data-level='2'] {
    margin-left: 11.76471%; }
  .forum-post[data-level='3'] {
    margin-left: 17.64706%; }
  .forum-post[data-level='4'] {
    margin-left: 23.52941%; }
  .forum-post[data-level='5'] {
    margin-left: 29.41176%; }
  .forum-post[data-level='6'] {
    margin-left: 35.29412%; }

.comment-icon {
  left: 0;
  position: absolute; }

article > .reply-form-wrapper {
  position: relative;
  margin-left: 0;
  padding-left: 11.76471%; }
  article > .reply-form-wrapper .comment-icon {
    width: 11.76471%; }

.parent-reply-button {
  bottom: 3.05176rem;
  position: absolute;
  right: 0; }

/**
 * FORMS ------------------------------ */
.form-group {
  margin-bottom: 1.5625rem; }

.form-field {
  width: 46.66667%;
  float: left;
  margin-right: 6.66667%; }
  .form-field-narrow {
    width: 29.41176%;
    float: left;
    margin-right: 5.88235%; }
  .form-field-narrowest {
    width: 20%;
    float: left;
    margin-right: 6.66667%; }
  .form-field-wide {
    width: 73.33333%;
    float: left;
    margin-right: 6.66667%; }
  .form-field-last {
    float: right;
    margin-right: 0; }

.form-notification {
  margin-bottom: 1.5625rem;
  padding: 1.375rem 1.95312rem 1.375rem 1.5625rem; }
  .form-notification p:last-child {
    margin-bottom: 0; }

.form-notification--success {
  background-color: rgba(119, 208, 178, 0.2);
  color: #017a73; }

.form-notification--info {
  background-color: rgba(255, 211, 78, 0.6);
  color: #19181a; }

.form-notification--error {
  background-color: rgba(187, 108, 103, 0.2);
  color: #bd3236; }

label {
  display: block;
  font-weight: 400;
  line-height: 1.5625rem; }

form .error input,
form .error select,
form .error textarea {
  border: 1px solid #bd3236; }

form .errorlist {
  color: #bd3236;
  margin-top: 0.512rem; }
  form .errorlist li.hide-next ~ li {
    display: none; }

form .errorlist.nonfield {
  color: #bd3236;
  margin-top: 0;
  margin-bottom: 0.64rem; }

.input,
select,
textarea,
input[type="url"],
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  background: #f4f5f5;
  border: 1px solid #b7b8ae;
  font-family: "adelle-sans", sans-serif;
  font-size: 1rem;
  min-height: 2rem;
  outline: none;
  padding: .5rem .75rem;
  vertical-align: middle; }

.input,
select,
textarea,
input[type="url"],
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="submit"],
input[type="button"],
button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.input,
textarea,
input[type="url"],
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%; }

.input:disabled,
textarea:disabled,
input[type="url"]:disabled,
input[type="tel"]:disabled,
input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="password"]:disabled {
  background-color: #e7e8e4;
  background-image: url(../img/locked.svg);
  background-position: center right 1rem;
  background-repeat: no-repeat;
  border-color: #e7e8e4; }

textarea {
  line-height: 1.428;
  padding-bottom: .75rem;
  padding-top: .75rem;
  resize: vertical; }

.forum-search input[type="search"] {
  height: 3em; }

.forum-search button {
  border: 1px solid #ffd34e;
  height: 3em;
  max-width: 6em;
  padding: 0; }
  .forum-search button:hover, .forum-search button:active {
    border-color: #ffecb4; }

.radio > label {
  display: inline-block;
  font-size: 1rem;
  letter-spacing: 0;
  text-transform: none; }
  .radio > label > * {
    vertical-align: middle; }

.radio-icon-icon,
.radio-icon-text,
.radio-icon input[type="radio"] {
  display: inline-block;
  margin-right: 0.64rem; }

.radio-icon input[type="radio"] {
  height: 1.25rem;
  width: 1.25rem; }

.search .button {
  background: #bd3236;
  color: #fff;
  display: inline-block;
  height: 100%;
  outline: none;
  padding: 0 1rem;
  width: 100%; }

select {
  background-image: url("../img/collapse.svg");
  background-position: center right .3em;
  background-repeat: no-repeat; }

#id_account-birthday_day {
  width: 22.5%;
  margin-right: 2.5%; }

#id_account-birthday_month {
  width: 45%;
  margin-right: 2.5%;
  margin-left: -.275em; }

#id_account-birthday_year {
  width: 27.5%;
  margin-left: -.275em; }

/**
 * RESPONSIVE ------------------------- */
@media screen and (max-width: 92.5em) {
  body {
    font-size: 90%; }
  .container {
    width: 90%; }
  .forum-post-avatar {
    margin-left: -.5em; }
  .forum-post[data-level='0'] .forum-post-avatar,
  .forum-post[data-level='1'] .forum-post-avatar {
    margin-left: 0; } }

@media screen and (min-width: 1760px) {
  body {
    font-size: 112.5%; } }
