.dropdown-wrapper {
  position: relative;

  .dropdown {
    display: none;
    box-shadow: 0 0px 7px rgba(0, 0, 0, .2);
    border: 1px solid $light-gray;
    border-radius: 3px;
    left: 0;
    position: absolute;
    z-index: 9;

    li {
      width: 100%;
    }

    &:after {
      content: '';
      height: ms(1);
      width: ms(1);
      position: absolute;
      background-color: #fff;
      top: -0.6rem;
      left: 45%;
      border-top: $light-gray solid 1px;
      border-left: $light-gray solid 1px;
      transform: rotate(45deg);
    }
  }

  &.dropdown-show {
    .dropdown {
      display: block;
    }

    .dropdown-toggle-show-opacity {
      opacity: 0.5;
    }

    .dropdown-toggle-show-bg-gray {
      background-color: $gray;
    }

  }
}
