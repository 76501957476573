.message-left + .message-left,
.message-right + .message-right { margin-top: ms(-4); }

.message-left .avatar-wrapper { float: left; }
.message-right .avatar-wrapper { float: right; }

.message-left + .message-left .avatar-wrapper,
.message-right + .message-right .avatar-wrapper { visibility: hidden; }

.message-body { border-radius: 4px; }

.message-left .message-body {
  background-color: $pale-yellow-20;
  border-color: $pale-yellow;
  float: left;
  margin-left: ms(0);
}

.message-right .message-body {
  background-color: $pale-teal-20;
  border-color: $pale-teal;
  float: right;
  margin-right: ms(0);
  text-align: right;
}

.message-header {
  top: ms(-2);
  position: absolute;
}

.message-left  .message-header { left: ms(-2); }
.message-right .message-header { right: ms(-2); }

.message-body time,
.message-body h4 {
  margin-left: .2rem
}

.message-body p {
  line-height: 1.5;
  margin-bottom: 0;

  & + p {
    margin-top: 1.5em;
  }
}
