$grids: 9 12 16;
@each $cols in $grids {
  @for $i from 1 through $cols {
    .col-#{$i}-#{$cols}   { width: span($i of $cols); }
    .col-#{$i}-#{$cols}-g { width: span($i of $cols) + gutter($cols); }
    .push-#{$i}-#{$cols}  { margin-left: span($i of $cols); }
    .pull-#{$i}-#{$cols}  { margin-right: span($i of $cols); }
  }

  @for $i from 0 through $cols {
    @if $i != 0 {
      .push-#{$i}-#{$cols}-g { margin-left: span($i of $cols) + gutter($cols); }
      .pull-#{$i}-#{$cols}-g { margin-right: span($i of $cols) + gutter($cols); }
    } @else {
      .push-#{$i}-#{$cols}-g { margin-left: gutter($cols); }
      .pull-#{$i}-#{$cols}-g { margin-right: gutter($cols); }
    }
  }

  .push-g-#{$cols} { margin-left: 2 * gutter($cols); }
  .pull-g-#{$cols} { margin-right: 2 * gutter($cols); }
}

@media #{$breakpoint-not-small} {
  @each $cols in $grids {
    @for $i from 1 through $cols {
      .col-#{$i}-#{$cols}-ns   { width: span($i of $cols); }
      .col-#{$i}-#{$cols}-g-ns { width: span($i of $cols) + gutter($cols); }
      .push-#{$i}-#{$cols}-ns  { margin-left: span($i of $cols); }
      .pull-#{$i}-#{$cols}-ns  { margin-right: span($i of $cols); }
    }

    @for $i from 0 through $cols {
      @if $i != 0 {
        .push-#{$i}-#{$cols}-g-ns { margin-left: span($i of $cols) + gutter($cols); }
        .pull-#{$i}-#{$cols}-g-ns { margin-right: span($i of $cols) + gutter($cols); }
      } @else {
        .push-#{$i}-#{$cols}-g-ns { margin-left: gutter($cols); }
        .pull-#{$i}-#{$cols}-g-ns { margin-right: gutter($cols); }
      }
    }

    .push-g-#{$cols}-ns { margin-left: 2 * gutter($cols); }
    .pull-g-#{$cols}-ns { margin-right: 2 * gutter($cols); }
  }
}

@media #{$breakpoint-medium} {
  @each $cols in $grids {
    @for $i from 1 through $cols {
      .col-#{$i}-#{$cols}-m   { width: span($i of $cols); }
      .col-#{$i}-#{$cols}-g-m { width: span($i of $cols) + gutter($cols); }
      .push-#{$i}-#{$cols}-m  { margin-left: span($i of $cols); }
      .pull-#{$i}-#{$cols}-m  { margin-right: span($i of $cols); }
    }

    @for $i from 0 through $cols {
      @if $i != 0 {
        .push-#{$i}-#{$cols}-g-m { margin-left: span($i of $cols) + gutter($cols); }
        .pull-#{$i}-#{$cols}-g-m { margin-right: span($i of $cols) + gutter($cols); }
      } @else {
        .push-#{$i}-#{$cols}-g-m { margin-left: gutter($cols); }
        .pull-#{$i}-#{$cols}-g-m { margin-right: gutter($cols); }
      }
    }

    .push-g-#{$cols}-m { margin-left: 2 * gutter($cols); }
    .pull-g-#{$cols}-m { margin-right: 2 * gutter($cols); }
  }
}

@media #{$breakpoint-large} {
  @each $cols in $grids {
    @for $i from 1 through $cols {
      .col-#{$i}-#{$cols}-l   { width: span($i of $cols); }
      .col-#{$i}-#{$cols}-g-l { width: span($i of $cols) + gutter($cols); }
      .push-#{$i}-#{$cols}-l  { margin-left: span($i of $cols); }
      .pull-#{$i}-#{$cols}-l  { margin-right: span($i of $cols); }
    }

    @for $i from 0 through $cols {
      @if $i != 0 {
        .push-#{$i}-#{$cols}-g-l { margin-left: span($i of $cols) + gutter($cols); }
        .pull-#{$i}-#{$cols}-g-l { margin-right: span($i of $cols) + gutter($cols); }
      } @else {
        .push-#{$i}-#{$cols}-g-l { margin-left: gutter($cols); }
        .pull-#{$i}-#{$cols}-g-l { margin-right: gutter($cols); }
      }
    }

    .push-g-#{$cols}-l { margin-left: 2 * gutter($cols); }
    .pull-g-#{$cols}-l { margin-right: 2 * gutter($cols); }
  }
}

@media #{$breakpoint-extra-large} {
  @each $cols in $grids {
    @for $i from 1 through $cols {
      .col-#{$i}-#{$cols}-xl   { width: span($i of $cols); }
      .col-#{$i}-#{$cols}-g-xl { width: span($i of $cols) + gutter($cols); }
      .push-#{$i}-#{$cols}-xl  { margin-left: span($i of $cols); }
      .pull-#{$i}-#{$cols}-xl  { margin-right: span($i of $cols); }
    }

    @for $i from 0 through $cols {
      @if $i != 0 {
        .push-#{$i}-#{$cols}-g-xl { margin-left: span($i of $cols) + gutter($cols); }
        .pull-#{$i}-#{$cols}-g-xl { margin-right: span($i of $cols) + gutter($cols); }
      } @else {
        .push-#{$i}-#{$cols}-g-xl { margin-left: gutter($cols); }
        .pull-#{$i}-#{$cols}-g-xl { margin-right: gutter($cols); }
      }
    }

    .push-g-#{$cols}-xl { margin-left: 2 * gutter($cols); }
    .pull-g-#{$cols}-xl { margin-right: 2 * gutter($cols); }
  }
}
