/* Text colors */

.black { color: $dark; }
.white { color: $white; }

.black-60 { color: $dark-60; }

.gray  { color: $gray; }
.dark-gray { color: $dark-gray; }
.light-gray { color: $light-gray; }

.gold { color: $gold; }

.red { color: $red; }
.pale-red { color: $pale-red; }

.teal { color: $teal; }
.pale-teal { color: $pale-teal; }
.pale-teal-20 { color: $pale-teal-20; }

.yellow { color: $yellow; }
.pale-yellow { color: $pale-yellow; }

.hover-opacity:hover,
.hover-opacity:focus {
  opacity: 0.5;
}

.hover-black:hover,
.hover-black:focus {
  color: $dark;
}

.hover-white:hover,
.hover-white:focus {
  color: $white;
}

.hover-light-gray:hover,
.hover-light-gray:focus {
  color: $light-gray;
}

.hover-red:hover,
.hover-red:focus {
  color: $red;
}

.hover-pale-red:hover,
.hover-pale-red:focus {
  color: $pale-red;
}

.link.red:hover { color: $pale-red; }

/* Background colors */

.bg-black          { background-color: $dark; }
.bg-black-60       { background-color: $dark-60; }

.bg-white          { background-color: $white; }

.bg-gray           { background-color: $gray; }
.bg-dark-gray      { background-color: $dark-gray; }
.bg-light-gray     { background-color: $light-gray; }

.bg-gold           { background-color: $gold; }

.bg-teal           { background-color: $teal; }
.bg-pale-teal      { background-color: $pale-teal; }
.bg-pale-teal-20   { background-color: $pale-teal-20; }

.bg-red            { background-color: $red; }
.bg-yellow         { background-color: $yellow; }
.bg-pale-yellow    { background-color: $pale-yellow; }

.bg-fb-blue        { background-color: $facebook-blue; }

.bg-hover-teal:hover { background-color: $teal; }

/* Border colors */

.b--black      { border-color: $dark; }
.b--dark-gray  { border-color: $dark-gray; }
.b--gray       { border-color: $gray; }
.b--light-gray { border-color: $light-gray; }
.b--pale-red   { border-color: $pale-red; }
.b--red        { border-color: $red; }
.b--teal       { border-color: $teal; }

/* Landing */

.bg-landing-teal         { background-color: #297b8e; }
.bg-landing-pale-teal    { background-color: #48aab7; }
.bg-landing-pale-teal-20 { background-color: #88c8d0; }
.bg-landing-red          { background-color: #ca5b39; }
.bg-landing-yellow       { background-color: #ff7550; }
.bg-landing-pale-yellow  { background-color: #fff5d9; }

.landing-teal         { color: #297b8e; }
.landing-pale-teal    { color: #48aab7; }
.landing-pale-teal-20 { color: #88c8d0; }
.landing-red          { color: #ca5b39; }
.landing-yellow       { color: #ff7550; }
.landing-pale-yellow  { color: #fff5d9; }

.b--landing-teal         { border-color: #297b8e; }
.b--landing-pale-teal    { border-color: #48aab7; }
.b--landing-pale-teal-20 { border-color: #88c8d0; }
.b--landing-red          { border-color: #ca5b39; }
.b--landing-yellow       { border-color: #ff7550; }
.b--landing-pale-yellow  { border-color: #fff5d9; }
