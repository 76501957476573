.tooltip {
  display: inline-block;
  position: relative;
  text-align: center;


  &-top,
  &-bottom {
    @extend %all-small-caps;

    background-color: $dark;
    border-radius: 4px;
    color: $white;
    display: none;
    font-size: ms(-1);
    font-family: $font-stack-sans;
    font-style: normal;
    left: 50%;
    min-width: 145px;
    padding: ms(-6) ms(-4);
    position: absolute;
    text-decoration: none;
    z-index: 9;

    &:after {
      content: '';
      height: 0.9rem;
      width: 0.9rem;
      position: absolute;
      background-color: $dark;
      left: 45%;
      border-top: $dark solid 1px;
      border-left: $dark solid 1px;
      transform: rotate(45deg);
    }
  }

  &-top {
    top: -20px;
    transform: translate(-50%, -100%);

    &:after {
      bottom: -0.45rem;
    }
  }

  &-bottom {
    top: 40px;
    transform: translate(-50%, 0);

    &:after {
      top: -0.45rem;
    }
  }

  &:hover &-top,
  &:hover &-bottom {
    display: block;
  }
}
