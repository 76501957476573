.avatar-wrapper,
.avatar-dropdown-wrapper {
  position: relative;
}

.avatar-wrapper {
  display: table;
}

.avatar {
  border-radius: 50%;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  label & {
    font-feature-settings: normal;
  }
}

.avatar-large {
  font-size: ms(8);
  height: 5.5rem;
  letter-spacing: 0.025em;
  line-height: 5.5rem;
  width: 5.5rem;
}

.avatar-small {
  font-size: ms(3);
  height: ms(11);
  line-height: ms(11);
  width: ms(11);
  letter-spacing: 0.025em;
}

.avatar[data-color='1']  { background: #f1a9a0; }
.avatar[data-color='2']  { background: #fde3a7; }
.avatar[data-color='3']  { background: #ffe11a; }
.avatar[data-color='4']  { background: #fbfb90; }
.avatar[data-color='5']  { background: #c8f7c5; }
.avatar[data-color='6']  { background: #57d1d7; }
.avatar[data-color='7']  { background: #c5eff7; }
.avatar[data-color='8']  { background: #dcc6e0; }
.avatar[data-color='9']  { background: #ff880c; color: $white; }
.avatar[data-color='10'] { background: #09bc68; color: $white; }
.avatar[data-color='11'] { background: #64048b; color: $white; }
.avatar[data-color='12'] { background: #921646; color: $white; }

@media #{$breakpoint-not-small} {
  .avatar-large {
    font-size: ms(10);
    height: 7.5rem;
    line-height: 7.5rem;
    width: 7.5rem;
  }
}
