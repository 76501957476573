.stripe-iframe-input,
.stripe-input > input[type=text] {
  background: $light-gray;
  border: 1px solid $gray;
  border-radius: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 1;
  min-height: 2rem;
  outline: none;
  padding: .625rem .75rem;
  vertical-align: middle;
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
}

.stripe-confirm-payment-box-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  top: -1rem;
}

.stripe-confirm-payment-box {
  background: white;
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  margin: 0 auto;
  max-width: 34rem;
  overflow: visible;
  position: relative;
  z-index: 20;
}

@media screen and (min-width: 1120px) {
  .stripe-confirm-payment-box-wrapper {
    top: -2rem;
  }
}
