.modal {
  height: 0;
  opacity: 0;
  overflow: auto;
  visibility: hidden;
  width: 0;
}

.modal:target,
.modal-visible {
  height: auto;
  opacity: 1;
  visibility: visible;
  width: auto;
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-inner {
  background-color: $white;
  box-shadow: 0 .625rem 1.875rem rgba(0, 0, 0, .32),
              0 .375rem  .625rem rgba(0, 0, 0, .36);
  margin: 4% auto;
  min-width: 28rem;
  overflow: visible;
  position: relative;
  z-index: 20;
}

.modal-inner header {
  padding-top: ms(8);
}

.modal-narrow {
  padding: 0 ms(6) ms(4);
  max-width: 28rem;
}

.modal-medium {
  padding: 0 ms(8) ms(6);
  max-width: 34rem;
}

.modal-wide {
  padding: 0 ms(8) ms(6);
  max-width: 38rem;
}

.modal-content {
  position: relative;
}

.modal-close {
  position: absolute;
  right: 1.5em;
  top: 1.5em;
}

.modal-icon {
  display: block !important;
  height: ms(22);
  margin: 0 auto;
  width: ms(22);
}
