.wc-row:after {
    @extend %clearfix;
}

.wc-square {
  border: 1px solid $dark;
  box-shadow: none;
  float: left;
  margin: -1px 0 0 -1px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

/**
 * Square types */

.wc-blank {
  border-color: rgba($white, 0);
}

.wc-block,
.wc-block:hover {
  background-color: $dark;
  cursor: not-allowed;
}

.wc-clues > div:not(:first-child) {
  border-top: 1px solid $dark;
}

.wc-clue {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1.1;
  text-transform: uppercase;

  -webkit-hyphens: auto;
     -moz-hyphens: auto;
          hyphens: auto;
}

.wc-text {
  padding: 0 2px;
}

.wc-clue-square:hover {
  cursor: pointer;
}

.wc-crossword-grid {
  margin-bottom: ms(4);
  margin-right: ms(4);
}

.wc-crossword-grid [data-number]:before {
  color: $dark;
  content: attr(data-number);
  font-size: ms(-1);
  left: 8%;
  line-height: 1;
  position: absolute;
  top: 8%;
}

/**
 * Active squares and words */

.wc-active-word { background-color: $yellow-60; }
.wc-active-square { background-color: $yellow; }
.wc-active-word.wc-reversed { background-color: $pale-teal-20; }
.wc-active-square.wc-reversed { background-color: $pale-teal; }

.wc-blink {
  background-color: rgba(1, 122, 115, .6);
  box-shadow: 0 0 30px rgba(1, 122, 115, .4);
}

.wc-blink-error {
  background-color: rgba(189, 50, 54, .6);
  box-shadow: 0 0 30px rgba(189, 50, 54, .4);
}

.wc-fade {
  transition: background-color .25s ease,
              box-shadow .25s ease;
}

/**
 * Clue list */

.wc-clue-list-wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;

  &.wc-clue-list-inline {
    flex-flow: column wrap;
  }
}

.wc-clue-list-wrapper h2 {
  @extend %sans-bold;
  @extend %faux-small-caps;

  font-size: ms(-2);
  margin-bottom: ms(-12);
}

.wc-clue-list-horizontal,
.wc-clue-list-vertical {
  flex: 1 1 20rem;
  flex-flow: row wrap;
  max-width: 20rem;
  min-width: 15rem;
}

.wc-clue-list-inline {
  .wc-clue-list-horizontal,
  .wc-clue-list-vertical {
    flex: 1 1 auto;
    max-width: none;
    min-width: auto;
  }

  .wc-clue-list li {
    display: inline-block;
  }
}

.wc-clue-list {
  margin-bottom: ms(3);
  margin-left: -0.2em;
}

.wc-clue-list li {
  cursor: pointer;
  display: block;
  padding: 0.2em 0.4em;
}

.wc-clue-list li:before {
  content: attr(data-number) '. ';
  font-weight: 700;
}

.wc-clue-list li:after {
  content: ' (' attr(data-length) ')';
}

.wc-clue-list li.selected {
  background-color: $yellow;
}

/**
 * Input listener */

.wc-crossword-grid { position: relative; }

#wordcross .wc-input-listener {
  height: 0;
  max-height: 0;
  max-width: 0;
  min-height: 0;
  min-width: 0;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
  will-change: left, top;
  z-index: 9;
}

/**
 * Arrows */

.wc-crossword-grid [class*=arrow-] {
  background-image: url(../img/atlas.svg);
  background-repeat: no-repeat;
}

.wc-crossword-grid .arrow-down            { background-position: -54px 0 }
.wc-crossword-grid .arrow-down-bend-left  { background-position: -108px -108px }
.wc-crossword-grid .arrow-down-bend-right { background-position: 0 -54px }
.wc-crossword-grid .arrow-left            { background-position: -54px -54px }
.wc-crossword-grid .arrow-left-bend-down  { background-position: -108px 0 }
.wc-crossword-grid .arrow-left-bend-up    { background-position: -108px -54px }
.wc-crossword-grid .arrow-left-high       { background-position: 0 -108px }
.wc-crossword-grid .arrow-left-low        { background-position: -54px -108px }
.wc-crossword-grid .arrow-right           { background-position: 0 0 }
.wc-crossword-grid .arrow-right-bend-down { background-position: -162px 0 }
.wc-crossword-grid .arrow-right-bend-up   { background-position: -162px -54px }
.wc-crossword-grid .arrow-right-high      { background-position: -162px -108px }
.wc-crossword-grid .arrow-right-low       { background-position: 0 -162px }
.wc-crossword-grid .arrow-up              { background-position: -54px -162px }
.wc-crossword-grid .arrow-up-bend-left    { background-position: -108px -162px }
.wc-crossword-grid .arrow-up-bend-right   { background-position: -162px -162px }

/**
 * SOLVERBAR -------------------------- */

.wc-solverbar {
  background: $pale-yellow;
  bottom: 0;
  display: none;
  left: 0;
  padding: ms(3) 0;
  position: fixed;
  width: 100%;
  z-index: 500;
}

.wc-solverbar .wc-solverbar-clue{
  @include span(9 of 12);
}

.wc-solverbar .wc-solverbar-actions{
  @include span(3 of 12);
  @include last;
  text-align: right;
}

.wc-solverbar .wc-solverbar-clue-id {
  font-weight: 700;
}

.wc-solverbar .wc-solverbar-clue-length {
  color: $red;
}

.wc-solverbar .wc-solverbar-action {
  background: $pale-yellow;
  border: none;
  color: $red;
  cursor: pointer;
  display: inline-block;
  font-size: ms(0);
  line-height: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  zoom: 1;

  &:visited {
    background-color: inherit;
    color: inherit;
  }
}
