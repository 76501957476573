@import 'lib/normalize';

@import '../../node_modules/susy/sass/susy';
@import '../../node_modules/modularscale-sass/stylesheets/modular-scale';

@import 'variables';

@import 'lib/borders';
@import 'lib/box-sizing';
@import 'lib/colors';
@import 'lib/display';
@import 'lib/flexbox';
@import 'lib/floats';
@import 'lib/forms';
@import 'lib/grid';
@import 'lib/heights';
@import 'lib/max-widths';
@import 'lib/position';
@import 'lib/spacing';
@import 'lib/text-align';
@import 'lib/text-decoration';
@import 'lib/type-scale';
@import 'lib/typography';
@import 'lib/utilities';
@import 'lib/widths';

@import 'components/accordion';
@import 'components/avatar';
@import 'components/crossword-filters';
@import 'components/dropdown';
@import 'components/filter-select';
@import 'components/forum-shortcuts';
@import 'components/hide-pending-crossword.scss';
@import 'components/honeypot';
@import 'components/messages';
@import 'components/mobile-menu';
@import 'components/modal';
@import 'components/progress-circle';
@import 'components/spinner';
@import 'components/stripe-payment-form';
@import 'components/subscription-plan';
@import 'components/tooltip';
@import 'components/tabs';
@import 'components/tos-nav';

@import 'icons';
@import 'wordcross';

/**
 * GRID SETTINGS ---------------------- */

$page-width: 90%;

$susy: (
  columns: 16,
  gutters: 1,
  container: $page-width,
  debug: (
    image: hide,
    toggle: top right,
  )
);


/**
 * PLACEHOLDERS ----------------------- */

%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

%all-small-caps {
  font-feature-settings: "c2sc", "smcp";
  font-variant-caps: all-small-caps;
  letter-spacing: 0.05em;
}

%faux-small-caps {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

%slab-bold {
  font-family: $font-stack-serif;
  font-weight: 700;
}

%sans-regular {
  font-family: $font-stack-sans;
  font-weight: 400;
}

%sans-bold {
  font-family: $font-stack-sans;
  font-weight: 700;
}


/**
 *
 * PAGE STYLES ------------------------ */

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
}


html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: $white;
  color: $dark;
}

body.sticky-footer {
  // Stick footer to bottom of page even if the content does not fill the screen.
  // See: https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .main-content {
    flex: 1 0 auto;
  }
}

ul,
li {
  list-style: none;
}

label,
button,
.button {
  cursor: pointer;
}

.overlay {
  background-color: $dark-60;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .2s ease;
  z-index: 500;
}

.container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
}

.img-as-bg {
  height: 100%;
  left: 0;
  object-fit: cover;
  top: 0;
  width: 100%;
  z-index: 0;
}

.pointer:hover {
  cursor: pointer;
}

/**
 * BUTTONS ---------------------------- */

// TODO: Move all buttons here

.btn {
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: ms(0);
  line-height: 1;
  padding: 1rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  zoom: 1;

  &:visited {
    background-color: inherit;
    color: inherit;
  }
}

.btn-default {
  background-color: $light-gray;
  color: inherit;

  &:hover,
  &:active {
    background-color: $gray;
    color: inherit;
  }

  &.disabled,
  &:disabled {
    background-color: $light-gray  !important;
    color: darken($light-gray, 30) !important;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.btn-primary {
  background-color: $yellow;
  color: $dark;

  &:hover,
  &:active {
    background-color: $pale-yellow;
    color: inherit;
  }

  &.disabled,
  &:disabled {
    background-color: $light-gray;
    color: darken($light-gray, 30);
    cursor: not-allowed;
    pointer-events: none;
  }
}

.btn-secondary {
  background-color: $teal;
  color: $white;

  &:hover,
  &:active {
    background-color: $pale-teal;
    color: $white;
  }
}

.btn-warning {
  background-color: $red;
  color: $white;
  font-size: ms(0);
  text-decoration: none;

  &:visited {
    background-color: $red;
    color: $white;
  }

  &:hover,
  &:active {
    background-color: $red;
    color: $white;
    opacity: 0.5;
  }
}

.btn-black {
  background-color: $dark;
  color: $white;

  &:visited {
    background-color: $dark;
    color: $white;
  }

  &:hover,
  &:active {
    background-color: $dark-gray;
  }
}

.btn-facebook {
  background-color: $facebook-blue;
  border-radius: 50px;
  color: $white;
  padding: ms(0) ms(8);
  white-space: nowrap;

  &:visited,
  &:hover,
  &:focus {
    background-color: #365899;
    color: $white;
  }
}

.btn-copy {
  background-color: $yellow;
  color: $dark;
  transition: color .15s ease;
  width: ms(18);

  &:hover {
    background-color: $pale-yellow;
  }

  &-faded {
    color: transparent;
  }
}

.btn-pricing-default {
  background-color: $dark;
  color: $white;

  &:visited {
    background-color: $dark;
    color: $white;
  }

  &:hover,
  &:active {
    background-color: $dark-gray;
  }
}

.btn-pricing-primary {
  background-color: $dark;
  color: $white;
  font-size: ms(2);
  padding: 1.25rem 1.75rem;

  &:visited {
    background-color: $dark;
    color: $white;
  }

  &:hover,
  &:active {
    background-color: $dark-gray;
  }
}

/**
 * UTIL ------------------------------- */

.rounded {
  height: auto;
  border-radius: 50%;
  border: 0 none;
}

.line-behind {
  position: relative;
  z-index: 1;

  &:before {
    border-top: 1px solid $gray;
    bottom: 0;
    content: '';
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    z-index: -1;
  }

  span {
    background: $white;
    padding: 0 ms(3);
  }
}


/**
 * SUBMISSIONS ------------------------ */

[data-submissions-complete],
[data-submissions-incomplete] {
  ul {
    display: block;
    float: left;
    width: span(3 of 9) + gutter(9);
  }

  ul:last-child {
    width: span(3 of 9);
  }

  li {
    font-size: ms(0);
  }
}

[data-submissions-incomplete] {
  ul > li:first-child {
    line-height: 1;
    padding: .4rem 0 .1rem;
  }
}


/**
 * PAGINATION ------------------------- */

.pagination {
  margin-top: ms(3);
  text-align: center;

  .icon {
    height: 20px;
    width: 20px;
  }

  > span:nth-child(2):before {
    color: $red;
    content: '|';
    margin: 0 ms(-6);
  }

}

/**
 * TYPOGRAPHY ------------------------- */

body,
input,
select,
textarea,
button {
  font-family: $font-stack-sans;
  font-size: medium;
}

label {
  font-size: ms(-2);
}

p {
  font-size: ms(0);
  line-height: 1.45;
  margin-bottom: ms(0) * 1.45;

  .title & {
    margin-bottom: 0;
  }
}

.help-text {
  color: $teal;
  font-feature-settings: normal;
  font-size: ms(-2);
  font-style: italic;
}

.timestamp {
  color: $gray;
  font-family: $font-stack-serif;
  font-size: ms(0);
  font-style: italic;
  font-weight: 300;

  .is-new &,
  .crossword-header & {
    color: $dark-gray;

    &.alert{
      color: $red;
    }
  }
}

.alert {
  color: $red;
}

.bullet {
  &:before {
    content: '•';
    font-weight: 700;
  }
}

a.no-link,
a.no-link:visited,
a.no-link:hover,
a.no-link:active {
  color: inherit;
  text-decoration: inherit;
}

/**
 * NAVIGATION ------------------------- */

.main-nav-item {
  border-top: .1875rem solid transparent;
  line-height: ms(11) + .1875rem; // avatar line-height + border
  padding: ms(12) ms(-5) ms(-6);

  a {
    color: $dark;
    font-weight: 700;
    text-decoration: none;
    @extend %faux-small-caps;
    font-size: ms(-1);
    letter-spacing: 0.1em;

    &:hover{
      color: $pale-teal;
    }
  }

  &.active {
    border-color: $pale-teal;

    > a {
      color: $pale-teal;
    }
  }
}

.profile-dropdown-wrapper {
  position: relative;
  vertical-align: -1.3rem;

  > .avatar-dropdown-wrapper {
    cursor: pointer;
  }
}

.profile-dropdown {
  background-color: $white;
  box-shadow: 0 2px 20px rgba(0, 0, 0, .2);
  display: none;
  min-height: ms(20);
  position: absolute;
  right: -((ms(11) - ms(2)) / 2);
  text-align: left;
  top: ms(11) + ms(-4); // avatar line-height + spacing
  width: ms(29);
  z-index: 9;

  &.schive-nav-show {
    display: block;
  }
}

.profile-dropdown:after {
  border: solid transparent;
  border-bottom-color: $white;
  border-width: ms(1);
  bottom: 100%;
  content: '';
  height: 0;
  margin-bottom: -(ms(-16));
  margin-left: -(ms(1));
  position: absolute;
  right: ms(5);
  width: 0;
}

.notification-bubble {
  background: $red;
  border-radius: 50%;
  color: $white;
  font-size: ms(-3);
  font-weight: 400;
  height: ms(5);
  line-height: ms(5);
  position: absolute;
  right: -(ms(5) / 2);
  text-align: center;
  top: -(ms(-16));
  width: ms(5);
}

/**
 * FRONT PAGE ----------------------------- */

.landing-page .banner img {
  max-height: 320px;
}

.landing-page-header {
  z-index: 400;
  position: absolute;
  left: 0;
  right: 0;
}

.landing-page-header .logo-container {
  justify-content: flex-start;
}

.landing-page-header .logo-type {
  text-align: left !important;
}

.landing-page-header .logo {
  color: $white;

  > br {
    display: inline-block !important;
  }
}

.landing-page-header .s-icon-menu {
  color: $white;
}

.landing-page-section {
  padding-top: ms(22);
  padding-bottom: ms(22);
}

.landing-page-inset {
  height: ms(28);
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}

.landing-page-illustration {
  align-self: center;
  flex: 0 0 auto;
  max-width: 100%;

  img {
    display: block;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    max-height: 400px;
    width: auto;
  }
}

.landing-rounded {
  border-radius: .5rem;
}

.landing-shadow  {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
}

@media #{$breakpoint-not-small} {
  .landing-page .banner-container,
  .landing-page .banner {
    min-height: 95vh;
  }

  .landing-page .banner {
    left: 0;
    top: 0;
    height: 95%;
  }

  .landing-page .banner img {
    max-height: 100%;
  }
}

@media #{$breakpoint-medium} {
  .landing-page-illustration {
    img {
      max-height: 638px;
    }
  }
}

@media #{$breakpoint-large} {
  .landing-page-illustration {
    flex: 0 0 45%;
    max-width: none;

    img {
      max-height: 400px;
    }
  }
}

@media #{$breakpoint-extra-large} {
  .landing-page-illustration {
    flex: 0 0 45%;
    max-width: none;

    img {
      max-height: 638px;
    }
  }
}


/**
 * LISTS ------------------------------ */

.list-item {
  border-bottom: 1px solid $light-gray;
  padding-bottom: ms(1);
  padding-left: 0;
  padding-top: ms(1);
}

.list-item:last-child {
  border-bottom: 0;
}

.list-item--small {
  padding: ms(1) ms(2);
}

.list-item--highlight {
  background-color: $pale-yellow-20;
  border-bottom: 2px solid $white;
  margin-right: -( ms(8) );
  padding-right: ms(8);
}

.list-item a,
.list-item a:visited,
.list-item a:hover,
.list-item a:active {
  color: inherit;
  text-decoration: inherit;
}

.keyboard-shortcut-list {
  dt {
    @extend %all-small-caps;
    color: $dark-gray;
    float: left;

    &:after {
      content: "–";
      margin: 0 .25em;
    }
  }
}

/**
 * FILTERS ---------------------------- */

.filters input[type="radio"],
.filters input[type="checkbox"] {
  display: none;
}

.filters input[type="radio"]:checked + label,
.filters input[type="checkbox"]:checked + label {
  color: $red;
  fill: $red;
}

.filters label { // TODO: Excessive overriding
  color: $dark-gray;
  fill: $gray;
  font-size: ms(0);
  letter-spacing: 0;
  line-height: 1.428;
  margin: 0;
  text-transform: none;
}

/**
 * BUTTONS ---------------------------- */

.button {
  border: 0;
  background-color: $light-gray;
  color: $dark;
  display: inline-block;
  font-size: ms(0);
  line-height: 1;
  padding: 1em 1.5em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  zoom: 1;
  cursor: pointer;
  @extend %all-small-caps;

  &:hover,
  &:active {
    background-color: $gray;
  }

  &.disabled,
  &:disabled {
    background-color: $light-gray !important;
    color: darken($light-gray, 30) !important;
    pointer-events: none;
  }

  &:-moz-focus-inner { border: 0; padding: 0; }
}

.button-featured {
  background: none;
  border: 2px solid $white;
  color: $white !important; // TODO fix

  &:hover {
    border-color: $yellow;
    background: $yellow;
    color: $dark !important; // TODO fix
  }
}

.button-primary {
  background-color: $yellow;
  color: $dark;

  &:hover {
    background-color: $pale-yellow;
  }
}

.button-filter {
  background-color: $red;
  color: $white;

  &:hover,
  &:active {
    background-color: $pale-red;
  }
}

.button-action {
  @extend %all-small-caps;
  color: $red;
  font-size: ms(0);
  text-decoration: none;
}

/**
 * PROFILE ---------------------------- */

.profile-meta,
.profile-about {
  padding-left: 5.5rem;
}

@media #{$breakpoint-not-small} {
  .profile-meta {
    padding-left: 0;
  }

  .profile-about {
    padding-left: 7.5rem;
  }
}

/**
 * FORUM ------------------------------ */

.forum-post {
  padding: ms(10) 0;
  position: relative;

  a,
  a:visited {
    color: $red;
    transition: color .15s ease-in;
  }

  a:hover,
  a:active {
    color: $pale-red;
  }

  &-avatar {
    left: 0;
    position: absolute;
    top: ms(10);

    .avatar-wrapper {
      margin: 0 auto;
    }
  }

  &-title {
    height: ms(11);

    h2 {
      font-size: ms(2);
      line-height: ms(11);
    }
  }

  &-sidebar {
    @include span(1 of 9 last);
    color: $gray;
  }

  &[data-level='0'],
  &[data-level='1'] {
    margin-left: 0;
    padding-left: span(1 of 9) + gutter(9);

    .forum-post-title,
    .forum-post-content {
      width: span(8 of 9);
    }

    .comment-icon,
    .forum-post-avatar {
      width: span(1 of 9) + gutter(9);
    }

    .reply-form-wrapper {
      padding-right: span(1 of 9);
    }
  }

  &[data-level='2'],
  &[data-level='3'],
  &[data-level='4'],
  &[data-level='5'],
  &[data-level='6'] {
    padding-left: span(1 of 9);
  }

  &[data-level='2'] { margin-left: 1 * span(1 of 9) + gutter(9); }
  &[data-level='3'] { margin-left: 2 * span(1 of 9) + gutter(9); }
  &[data-level='4'] { margin-left: 3 * span(1 of 9) + gutter(9); }
  &[data-level='5'] { margin-left: 4 * span(1 of 9) + gutter(9); }
  &[data-level='6'] { margin-left: 5 * span(1 of 9) + gutter(9); }
}

.comment-icon {
  left: 0;
  position: absolute;
}

article > .reply-form-wrapper {
  position: relative;
  margin-left: 0;
  padding-left: span(1 of 9) + gutter(9);

  .comment-icon {
    width: span(1 of 9) + gutter(9);
  }
}

.parent-reply-button {
  bottom: ms(10);
  position: absolute;
  right: 0;
}

/**
 * FORMS ------------------------------ */

.form-group {
  @extend %clearfix;
  margin-bottom: ms(4);
}

.form-field {
  @include span(4 of 8);

  &-narrow {
    @include span(3 of 9);
  }

  &-narrowest {
    @include span(2 of 8);
  }

  &-wide {
    @include span(6 of 8);
  }

  &-last {
    @include last;
  }
}

.form-notification {
  margin-bottom: ms(4);
  padding: ms(3) ms(6) ms(3) ms(4);

  p:last-child { margin-bottom: 0; }
}

.form-notification--success {
  background-color: $pale-teal-20;
  color: $teal;
}

.form-notification--info {
  background-color: $yellow-60;
  color: $dark;
}

.form-notification--error {
  background-color: $pale-red-20;
  color: $red;
}

label {
  display: block;
  font-weight: 400;
  line-height: ms(4);
}

form .error input,
form .error select,
form .error textarea {
  border: 1px solid $red;
}

form .errorlist {
  color: $red;
  margin-top: ms(-6);

  li.hide-next ~ li {
    display: none;
  }
}

form .errorlist.nonfield {
  color: $red;
  margin-top: 0;
  margin-bottom: ms(-4);
}

.input,
select,
textarea,
input[type="url"],
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  background: $light-gray;
  border: 1px solid $gray;
  font-family: $font-stack-sans;
  font-size: 1rem;
  min-height: 2rem;
  outline: none;
  padding: .5rem .75rem;
  vertical-align: middle;
}

.input,
select,
textarea,
input[type="url"],
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="submit"],
input[type="button"],
button {
  border-radius: 0;

  -webkit-appearance: none;
     -moz-appearance: none;
}

.input,
textarea,
input[type="url"],
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
}

.input:disabled,
textarea:disabled,
input[type="url"]:disabled,
input[type="tel"]:disabled,
input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="password"]:disabled {
  background-color: lighten($gray, 20);
  background-image: url(../img/locked.svg);
  background-position: center right ms(0);
  background-repeat: no-repeat;
  border-color: lighten($gray, 20);
}

textarea {
  line-height: 1.428;
  padding-bottom: .75rem;
  padding-top: .75rem;
  resize: vertical;
}

.forum-search {
  input[type="search"] {
    height: 3em;
  }

  button {
    border: 1px solid $yellow;
    height: 3em;
    max-width: 6em;
    padding: 0;

    &:hover,
    &:active {
      border-color: $pale-yellow;
    }
  }
}

.radio > label {
  display: inline-block;
  font-size: ms(0);
  letter-spacing: 0;
  text-transform: none;

  > * {
    vertical-align: middle;
  }
}

.radio-icon-icon,
.radio-icon-text,
.radio-icon input[type="radio"] {
  display: inline-block;
  margin-right: ms(-4);
}

.radio-icon input[type="radio"] {
  height: ms(2);
  width: ms(2);
}

.search .button {
  background: $red;
  color: $white;
  display: inline-block;
  height: 100%;
  outline: none;
  padding: 0 1rem;
  width: 100%;
}

select {
  background-image: url('../img/collapse.svg');
  background-position: center right .3em;
  background-repeat: no-repeat;
}

#id_account-birthday_day {
  width: 22.5%;
  margin-right: 2.5%;
}

#id_account-birthday_month {
  width: 45%;
  margin-right: 2.5%;
  margin-left: -.275em;
}

#id_account-birthday_year {
  width: 27.5%;
  margin-left: -.275em;
}

/**
 * RESPONSIVE ------------------------- */


@media screen and (max-width: 92.5em) {
  body {
    font-size: 90%;
  }

  .container {
    width: 90%;
  }

  .forum-post {
    &-avatar {
      margin-left: -.5em;
    }

    &[data-level='0'] .forum-post-avatar,
    &[data-level='1'] .forum-post-avatar {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 1760px) {
  body {
    font-size: 112.5%;
  }
}
