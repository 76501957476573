$ms-base: 1rem 1.375rem;
$ms-ratio: 1.25;

$breakpoint-not-small: 'screen and (min-width: 48em)';
$breakpoint-medium: 'screen and (min-width: 48em) and (max-width: 70em)';
$breakpoint-large: 'screen and (min-width: 70em)';
$breakpoint-extra-large: 'screen and (min-width: 92.5em)';

$font-stack-serif: 'adelle', serif;
$font-stack-sans: 'adelle-sans', sans-serif;

$white: #fff;

$dark: #19181a;
$dark-60: rgba($dark, 0.6);

$gray: #b7b8ae;
$dark-gray: #5a6161;
$light-gray: #f4f5f5;

$yellow: #ffd34e;
$yellow-60: rgba($yellow, 0.6);
$pale-yellow: lighten($yellow, 20);
$pale-yellow-20: rgba($pale-yellow, 0.2);

$red: #bd3236;
$pale-red: lighten(desaturate(adjust-hue($red, 5), 20), 10);
$pale-red-20: rgba($pale-red, 0.2);

$teal: #017a73;
$pale-teal: lighten(desaturate(adjust-hue($teal, -17), 50), 40);
$pale-teal-20: rgba($pale-teal, 0.2);

$gold: #db9e02;
$silver: $gray;
$bronze: #c3825b;

$facebook-blue: #4267b2;
