.crossword-filter-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms cubic-bezier(0.0, 0.95, 0.25, 1);

  &.crossword-filter-shown {
    max-height: 800px;
    transition: max-height 840ms ease-out;
  }
}

.crossword-filter {
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] ~ label {
    background-color: $white;
    border-radius: 4px;
  }

  input[type="checkbox"]:checked ~ label {
    background-color: $yellow;
  }
}
