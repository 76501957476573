.no-scroll {
  overflow: hidden;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

.center-children {
  > * { margin: 0 auto; }
}
