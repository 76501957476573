/**
 * TYPE SCALE ------------------------- */

.f-14 { font-size: ms(14); }
.f-12 { font-size: ms(12); }
.f-10 { font-size: ms(10); }
.f-8 { font-size:  ms(8);  }
.f-6  { font-size: ms(6);  }
.f-5  { font-size: ms(5);  }
.f-4  { font-size: ms(4);  }
.f-3  { font-size: ms(3);  }
.f-2  { font-size: ms(2);  }
.f-1  { font-size: ms(1);  }
.f-0  { font-size: ms(0);  }
.f--1 { font-size: ms(-1); }
.f--2 { font-size: ms(-2); }

/**
 * LINE HEIGHTS ----------------------- */

.lh-solid       { line-height: 1; }
.lh-title-small { line-height: 1.3; }
.lh-title       { line-height: 1.4; }
.lh-copy        { line-height: 1.5; }

@media #{$breakpoint-not-small} {
  .f-14-ns { font-size: ms(14); }
  .f-12-ns { font-size: ms(12); }
  .f-10-ns { font-size: ms(10); }
  .f-8-ns  { font-size: ms(8);  }
  .f-6-ns  { font-size: ms(6);  }
  .f-5-ns  { font-size: ms(5);  }
  .f-4-ns  { font-size: ms(4);  }
  .f-3-ns  { font-size: ms(3);  }
  .f-2-ns  { font-size: ms(2);  }
  .f-1-ns  { font-size: ms(1);  }
  .f-0-ns  { font-size: ms(0);  }
  .f--1-ns { font-size: ms(-1); }
  .f--2-ns { font-size: ms(-2); }
}

@media #{$breakpoint-large} {
  .f-2-l  { font-size: ms(2);  }
  .f-1-l  { font-size: ms(1);  }
}
