/*

   TEXT DECORATION
   Docs: http://tachyons.io/docs/typography/text-decoration/

*/

.strike       { text-decoration: line-through; }
.underline    { text-decoration: underline; }
.no-underline { text-decoration: none; }
