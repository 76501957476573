/*

   HEIGHTS
   Docs: http://tachyons.io/docs/layout/heights/

   Base:
     h = height
*/

.h-38 { height: 36rem; }
.h-42 { height: 42rem; }
.h-44 { height: 44rem; }
.h-46 { height: 46rem; }


@media #{$breakpoint-not-small} {
  .h-38-ns { height: 38rem; }
  .h-42-ns { height: 42rem; }
  .h-44-ns { height: 44rem; }
  .h-46-ns { height: 46rem; }
}

@media #{$breakpoint-medium} {
  .h-38-m { height: 38rem; }
  .h-42-m { height: 42rem; }
  .h-44-m { height: 44rem; }
  .h-46-m { height: 46rem; }
}

@media #{$breakpoint-large} {
  .h-38-l { height: 38rem; }
  .h-42-l { height: 42rem; }
  .h-44-l { height: 44rem; }
  .h-46-l { height: 46rem; }
}
