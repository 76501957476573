$spacing-none: 0 !default;
$spacing-extra-small: ms(0) !default;
$spacing-small: ms(2) !default;
$spacing-medium: ms(3) !default;
$spacing-large: ms(4) !default;
$spacing-extra-large: ms(6) !default;
$spacing-extra-extra-large: ms(12) !default;
$spacing-extra-extra-extra-large: ms(14) !default;

// TODO: Inconsistent naming
// use pt-x      for  ms(x)
//     pt--x     for  ms(-x)
//     pt-neg-x  for -ms(x)
//     pt-neg--x for -ms(-x)
//     pt-none   for 0;
//     pt-g      for gutter(12)
// and generate this file
// ack "pt[\d]"
// DONE: pl, pr, pa

.pa--8 { padding: ms(-8); }
.pa--6 { padding: ms(-6); }
.pa--4 { padding: ms(-4); }
.pa--2 { padding: ms(-2); }
.pa-2  { padding: ms(2);  }
.pa-3  { padding: ms(3);  }

.pv--10 { padding-bottom: ms(-10); padding-top: ms(-10); }
.pv--8  { padding-bottom: ms(-8);  padding-top: ms(-8);  }
.pv--6  { padding-bottom: ms(-6);  padding-top: ms(-6);  }
.pv--4  { padding-bottom: ms(-4);  padding-top: ms(-4);  }
.pv--2  { padding-bottom: ms(-2);  padding-top: ms(-2);  }
.pv-1   { padding-bottom: ms(1);   padding-top: ms(1);   }
.pv-2   { padding-bottom: ms(2);   padding-top: ms(2);   }
.pv-4   { padding-bottom: ms(4);   padding-top: ms(4);   }
.pv-6   { padding-bottom: ms(6);   padding-top: ms(6);   }
.pv-8   { padding-bottom: ms(8);   padding-top: ms(8);   }
.pv-10  { padding-bottom: ms(10);  padding-top: ms(10);  }
.pv-12  { padding-bottom: ms(12);  padding-top: ms(12);  }
.pv-16  { padding-bottom: ms(16);  padding-top: ms(16);  }
.pv-20  { padding-bottom: ms(20);  padding-top: ms(20);  }
.pv-22  { padding-bottom: ms(22);  padding-top: ms(22);  }

.ph--16 { padding-left: ms(-16); padding-right: ms(-16); }
.ph--10 { padding-left: ms(-10); padding-right: ms(-10); }
.ph--4  { padding-left: ms(-4);  padding-right: ms(-4);  }
.ph--2  { padding-left: ms(-2);  padding-right: ms(-2);  }
.ph-1   { padding-left: ms(1);   padding-right: ms(1);   }
.ph-2   { padding-left: ms(2);   padding-right: ms(2);   }
.ph-4   { padding-left: ms(4);   padding-right: ms(4);   }
.ph-6   { padding-left: ms(6);   padding-right: ms(6);   }
.ph-8   { padding-left: ms(8);   padding-right: ms(8);   }
.ph-10  { padding-left: ms(10);  padding-right: ms(10);  }
.ph-12  { padding-left: ms(12);  padding-right: ms(12);  }
.ph-15  { padding-left: ms(15);  padding-right: ms(15);  }

.ph-g   { padding-left: gutter(12); padding-right: gutter(12); }

.pl-none { padding-left: 0; }
.pl-0    { padding-left: ms(0); }
.pl-4    { padding-left: ms(4); }

.pr--2    { padding-right: ms(-2); }
.pr-2     { padding-right: ms(2); }
.pr-4     { padding-right: ms(4); }
.pr-12    { padding-right: ms(12); }

.pt--12  { padding-top: ms(-12); }
.pt--8   { padding-top: ms(-8);  }
.pt--6   { padding-top: ms(-6);  }
.pt--2   { padding-top: ms(-2);  }
.pt-0    { padding-top: ms(0);   }
.pt-2    { padding-top: ms(2);   }
.pt-6    { padding-top: ms(4);   }
.pt-6    { padding-top: ms(6);   }
.pt-8    { padding-top: ms(8);   }
.pt-10   { padding-top: ms(10);  }
.pt-12   { padding-top: ms(12);  }
.pt-14   { padding-top: ms(14);  }
.pt-15   { padding-top: ms(15);  }
.pt-20   { padding-top: ms(20);  }
.pt-22   { padding-top: ms(22);  }

.pb--8  { padding-bottom: ms(-8); }
.pb--6  { padding-bottom: ms(-6); }
.pb--4  { padding-bottom: ms(-4); }
.pb--2  { padding-bottom: ms(-2); }
.pb--1  { padding-bottom: ms(-1); }
.pb-0   { padding-bottom: ms(0);  }
.pb-1   { padding-bottom: ms(1);  }
.pb-2   { padding-bottom: ms(2);  }
.pb-4   { padding-bottom: ms(4);  }
.pb-6   { padding-bottom: ms(6);  }
.pb-8   { padding-bottom: ms(8);  }
.pb-10  { padding-bottom: ms(10); }
.pb-12  { padding-bottom: ms(12); }
.pb-15  { padding-bottom: ms(15); }
.pb-22  { padding-bottom: ms(22); }

.mr--10  { margin-right: ms(-10);    }
.mr--8   { margin-right: ms(-8);     }
.mr--4   { margin-right: ms(-4);     }
.mr--2   { margin-right: ms(-2);     }
.mr-0    { margin-right: ms(0);      }
.mr-2    { margin-right: ms(2);      }
.mr-4    { margin-right: ms(4);      }
.mr-g    { margin-right: gutter(12); }

.ml--0 { margin-left: -(ms(0));   }
.ml--4 { margin-left: -(ms(4));   }
.ml--6 { margin-left: -(ms(6));   }
.ml--8 { margin-left: -(ms(8));   }
.ml--4 { margin-left: ms(-4);     }
.ml--2 { margin-left: ms(-2);     }
.ml-0  { margin-left: ms(0);      }
.ml-2  { margin-left: ms(2);      }
.ml-4  { margin-left: ms(4);      }
.ml-6  { margin-left: ms(6);      }
.ml-8  { margin-left: ms(8);      }
.ml-g  { margin-left: gutter(12); }

.mt--16 { margin-top: ms(-16);    }
.mt--12 { margin-top: ms(-12);    }
.mt--10 { margin-top: ms(-10);    }
.mt--8  { margin-top: ms(-8);     }
.mt--6  { margin-top: ms(-6);     }
.mt--4  { margin-top: ms(-4);     }
.mt--2  { margin-top: ms(-2);     }
.mt-1   { margin-top: ms(1);      }
.mt-2   { margin-top: ms(2);      }
.mt-4   { margin-top: ms(4);      }
.mt-5   { margin-top: ms(5);      }
.mt-6   { margin-top: ms(6);      }
.mt-7   { margin-top: ms(7);      }
.mt-8   { margin-top: ms(8);      }
.mt-9   { margin-top: ms(9);      }
.mt-10  { margin-top: ms(10);     }
.mt-12  { margin-top: ms(12);     }
.mt-14  { margin-top: ms(14);     }
.mt-16  { margin-top: ms(16);     }
.mt-g   { margin-top: gutter(12); }

.mb--16 { margin-bottom: ms(-16);    }
.mb--12 { margin-bottom: ms(-12);    }
.mb--10 { margin-bottom: ms(-10);    }
.mb--8  { margin-bottom: ms(-8);     }
.mb--6  { margin-bottom: ms(-6);     }
.mb--4  { margin-bottom: ms(-4);     }
.mb--2  { margin-bottom: ms(-2);     }
.mb-2   { margin-bottom: ms(2);      }
.mb-4   { margin-bottom: ms(4);      }
.mb-6   { margin-bottom: ms(6);      }
.mb-8   { margin-bottom: ms(8);      }
.mb-9   { margin-bottom: ms(9);      }
.mb-10  { margin-bottom: ms(10);     }
.mb-12  { margin-bottom: ms(12);     }
.mb-14  { margin-bottom: ms(14);     }
.mb-16  { margin-bottom: ms(16);     }
.mb-g   { margin-bottom: gutter(12); }

.mv-8  {
  margin-top: ms(8);
  margin-bottom: ms(8);
}

.mv-10  {
  margin-top: ms(10);
  margin-bottom: ms(10);
}

.mh-n--16 {
  margin-left: ms(-16);
  margin-right: ms(-16);
}

.mh-n--10 {
  margin-left: ms(-10);
  margin-right: ms(-10);
}

@media #{$breakpoint-not-small} {
  .pv-20-ns  { padding-bottom: ms(20);  padding-top: ms(20);  }
  .pv-22-ns  { padding-bottom: ms(22);  padding-top: ms(22);  }

  .pt-20-ns { padding-top: ms(20);    }
  .pt-22-ns { padding-top: ms(22);    }

  .pb-22-ns { padding-bottom: ms(22); }

  .pr-4-ns { padding-right: ms(4);    }

  .ml-g-ns { margin-left: gutter(12); }

  .mr-4-ns { margin-right: ms(4);      }
  .mr-g-ns { margin-right: gutter(12); }

  .mb--10-ns { margin-bottom: ms(-10);    }
  .mb--4-ns  { margin-bottom: ms(-4);     }
  .mb--2-ns  { margin-bottom: ms(-2);     }
  .mb-4-ns   { margin-bottom: ms(4);      }
  .mb-g-ns   { margin-bottom: gutter(12); }

  .mt-g-ns { margin-top: gutter(12); }
}

@media #{$breakpoint-medium} {
  .pv-18-m  { padding-bottom: ms(18);  padding-top: ms(18);  }

  .pt-18-m  { padding-top: ms(18); }

  .pb-18-m  { padding-bottom: ms(18); }
}

@media #{$breakpoint-large} {
  .mt-6-l { margin-top: ms(6); }

  .pv-12-l  { padding-bottom: ms(12);  padding-top: ms(12);  }
}


/*
   SPACING
   Docs: http://tachyons.io/docs/layout/spacing/

   An eight step powers of two scale ranging from 0 to 16rem.

   Base:
     p = padding
     m = margin

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left

     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.pa0 { padding: $spacing-none; }
.pa1 { padding: $spacing-extra-small; }
.pa2 { padding: $spacing-small; }
.pa3 { padding: $spacing-medium; }
.pa4 { padding: $spacing-large; }
.pa5 { padding: $spacing-extra-large; }
.pa6 { padding: $spacing-extra-extra-large; }
.pa7 { padding: $spacing-extra-extra-extra-large; }

.pl0 { padding-left: $spacing-none; }
.pl1 { padding-left: $spacing-extra-small; }
.pl2 { padding-left: $spacing-small; }
.pl3 { padding-left: $spacing-medium; }
.pl4 { padding-left: $spacing-large; }
.pl5 { padding-left: $spacing-extra-large; }
.pl6 { padding-left: $spacing-extra-extra-large; }
.pl7 { padding-left: $spacing-extra-extra-extra-large; }

.pr0 { padding-right: $spacing-none; }
.pr1 { padding-right: $spacing-extra-small; }
.pr2 { padding-right: $spacing-small; }
.pr3 { padding-right: $spacing-medium; }
.pr4 { padding-right: $spacing-large; }
.pr5 { padding-right: $spacing-extra-large; }
.pr6 { padding-right: $spacing-extra-extra-large; }
.pr7 { padding-right: $spacing-extra-extra-extra-large; }

.pb0 { padding-bottom: $spacing-none; }
.pb1 { padding-bottom: $spacing-extra-small; }
.pb2 { padding-bottom: $spacing-small; }
.pb3 { padding-bottom: $spacing-medium; }
.pb4 { padding-bottom: $spacing-large; }
.pb5 { padding-bottom: $spacing-extra-large; }
.pb6 { padding-bottom: $spacing-extra-extra-large; }
.pb7 { padding-bottom: $spacing-extra-extra-extra-large; }

.pt0 { padding-top: $spacing-none; }
.pt1 { padding-top: $spacing-extra-small; }
.pt2 { padding-top: $spacing-small; }
.pt3 { padding-top: $spacing-medium; }
.pt4 { padding-top: $spacing-large; }
.pt5 { padding-top: $spacing-extra-large; }
.pt6 { padding-top: $spacing-extra-extra-large; }
.pt7 { padding-top: $spacing-extra-extra-extra-large; }

.pv0 {
  padding-top: $spacing-none;
  padding-bottom: $spacing-none;
}
.pv1 {
  padding-top: $spacing-extra-small;
  padding-bottom: $spacing-extra-small;
}
.pv2 {
  padding-top: $spacing-small;
  padding-bottom: $spacing-small;
}
.pv3 {
  padding-top: $spacing-medium;
  padding-bottom: $spacing-medium;
}
.pv4 {
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
}
.pv5 {
  padding-top: $spacing-extra-large;
  padding-bottom: $spacing-extra-large;
}
.pv6 {
  padding-top: $spacing-extra-extra-large;
  padding-bottom: $spacing-extra-extra-large;
}

.pv7 {
  padding-top: $spacing-extra-extra-extra-large;
  padding-bottom: $spacing-extra-extra-extra-large;
}

.ph0 {
  padding-left: $spacing-none;
  padding-right: $spacing-none;
}

.ph1 {
  padding-left: $spacing-extra-small;
  padding-right: $spacing-extra-small;
}

.ph2 {
  padding-left: $spacing-small;
  padding-right: $spacing-small;
}

.ph3 {
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
}

.ph4 {
  padding-left: $spacing-large;
  padding-right: $spacing-large;
}

.ph5 {
  padding-left: $spacing-extra-large;
  padding-right: $spacing-extra-large;
}

.ph6 {
  padding-left: $spacing-extra-extra-large;
  padding-right: $spacing-extra-extra-large;
}

.ph7 {
  padding-left: $spacing-extra-extra-extra-large;
  padding-right: $spacing-extra-extra-extra-large;
}

.ma0  {  margin: $spacing-none; }
.ma1 {  margin: $spacing-extra-small; }
.ma2  {  margin: $spacing-small; }
.ma3  {  margin: $spacing-medium; }
.ma4  {  margin: $spacing-large; }
.ma5  {  margin: $spacing-extra-large; }
.ma6 {  margin: $spacing-extra-extra-large; }
.ma7 { margin: $spacing-extra-extra-extra-large; }

.ml0  {  margin-left: $spacing-none; }
.ml1 {  margin-left: $spacing-extra-small; }
.ml2  {  margin-left: $spacing-small; }
.ml3  {  margin-left: $spacing-medium; }
.ml4  {  margin-left: $spacing-large; }
.ml5  {  margin-left: $spacing-extra-large; }
.ml6 {  margin-left: $spacing-extra-extra-large; }
.ml7 { margin-left: $spacing-extra-extra-extra-large; }

.mr0  {  margin-right: $spacing-none; }
.mr1 {  margin-right: $spacing-extra-small; }
.mr2  {  margin-right: $spacing-small; }
.mr3  {  margin-right: $spacing-medium; }
.mr4  {  margin-right: $spacing-large; }
.mr5  {  margin-right: $spacing-extra-large; }
.mr6 {  margin-right: $spacing-extra-extra-large; }
.mr7 { margin-right: $spacing-extra-extra-extra-large; }

.mb0  {  margin-bottom: $spacing-none; }
.mb1 {  margin-bottom: $spacing-extra-small; }
.mb2  {  margin-bottom: $spacing-small; }
.mb3  {  margin-bottom: $spacing-medium; }
.mb4  {  margin-bottom: $spacing-large; }
.mb5  {  margin-bottom: $spacing-extra-large; }
.mb6 {  margin-bottom: $spacing-extra-extra-large; }
.mb7 { margin-bottom: $spacing-extra-extra-extra-large; }

.mt0  {  margin-top: $spacing-none; }
.mt1 {  margin-top: $spacing-extra-small; }
.mt2  {  margin-top: $spacing-small; }
.mt3  {  margin-top: $spacing-medium; }
.mt4  {  margin-top: $spacing-large; }
.mt5  {  margin-top: $spacing-extra-large; }
.mt6 {  margin-top: $spacing-extra-extra-large; }
.mt7 { margin-top: $spacing-extra-extra-extra-large; }

.mv0   {
  margin-top: $spacing-none;
  margin-bottom: $spacing-none;
}
.mv1  {
  margin-top: $spacing-extra-small;
  margin-bottom: $spacing-extra-small;
}
.mv2   {
  margin-top: $spacing-small;
  margin-bottom: $spacing-small;
}
.mv3   {
  margin-top: $spacing-medium;
  margin-bottom: $spacing-medium;
}
.mv4   {
  margin-top: $spacing-large;
  margin-bottom: $spacing-large;
}
.mv5   {
  margin-top: $spacing-extra-large;
  margin-bottom: $spacing-extra-large;
}
.mv6  {
  margin-top: $spacing-extra-extra-large;
  margin-bottom: $spacing-extra-extra-large;
}
.mv7  {
  margin-top: $spacing-extra-extra-extra-large;
  margin-bottom: $spacing-extra-extra-extra-large;
}

.mh0   {
  margin-left: $spacing-none;
  margin-right: $spacing-none;
}
.mh1   {
  margin-left: $spacing-extra-small;
  margin-right: $spacing-extra-small;
}
.mh2   {
  margin-left: $spacing-small;
  margin-right: $spacing-small;
}
.mh3   {
  margin-left: $spacing-medium;
  margin-right: $spacing-medium;
}
.mh4   {
  margin-left: $spacing-large;
  margin-right: $spacing-large;
}
.mh5   {
  margin-left: $spacing-extra-large;
  margin-right: $spacing-extra-large;
}
.mh6  {
  margin-left: $spacing-extra-extra-large;
  margin-right: $spacing-extra-extra-large;
}
.mh7  {
  margin-left: $spacing-extra-extra-extra-large;
  margin-right: $spacing-extra-extra-extra-large;
}

@media #{$breakpoint-not-small} {
  .pa0-ns  {  padding: $spacing-none; }
  .pa1-ns {  padding: $spacing-extra-small; }
  .pa2-ns  {  padding: $spacing-small; }
  .pa3-ns  {  padding: $spacing-medium; }
  .pa4-ns  {  padding: $spacing-large; }
  .pa5-ns  {  padding: $spacing-extra-large; }
  .pa6-ns {  padding: $spacing-extra-extra-large; }
  .pa7-ns { padding: $spacing-extra-extra-extra-large; }

  .pl0-ns  {  padding-left: $spacing-none; }
  .pl1-ns {  padding-left: $spacing-extra-small; }
  .pl2-ns  {  padding-left: $spacing-small; }
  .pl3-ns  {  padding-left: $spacing-medium; }
  .pl4-ns  {  padding-left: $spacing-large; }
  .pl5-ns  {  padding-left: $spacing-extra-large; }
  .pl6-ns {  padding-left: $spacing-extra-extra-large; }
  .pl7-ns { padding-left: $spacing-extra-extra-extra-large; }

  .pr0-ns  {  padding-right: $spacing-none; }
  .pr1-ns {  padding-right: $spacing-extra-small; }
  .pr2-ns  {  padding-right: $spacing-small; }
  .pr3-ns  {  padding-right: $spacing-medium; }
  .pr4-ns  {  padding-right: $spacing-large; }
  .pr5-ns {   padding-right: $spacing-extra-large; }
  .pr6-ns {  padding-right: $spacing-extra-extra-large; }
  .pr7-ns { padding-right: $spacing-extra-extra-extra-large; }

  .pb0-ns  {  padding-bottom: $spacing-none; }
  .pb1-ns {  padding-bottom: $spacing-extra-small; }
  .pb2-ns  {  padding-bottom: $spacing-small; }
  .pb3-ns  {  padding-bottom: $spacing-medium; }
  .pb4-ns  {  padding-bottom: $spacing-large; }
  .pb5-ns  {  padding-bottom: $spacing-extra-large; }
  .pb6-ns {  padding-bottom: $spacing-extra-extra-large; }
  .pb7-ns { padding-bottom: $spacing-extra-extra-extra-large; }

  .pt0-ns  {  padding-top: $spacing-none; }
  .pt1-ns {  padding-top: $spacing-extra-small; }
  .pt2-ns  {  padding-top: $spacing-small; }
  .pt3-ns  {  padding-top: $spacing-medium; }
  .pt4-ns  {  padding-top: $spacing-large; }
  .pt5-ns  {  padding-top: $spacing-extra-large; }
  .pt6-ns {  padding-top: $spacing-extra-extra-large; }
  .pt7-ns { padding-top: $spacing-extra-extra-extra-large; }

  .pv0-ns {
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }
  .pv1-ns {
    padding-top: $spacing-extra-small;
    padding-bottom: $spacing-extra-small;
  }
  .pv2-ns {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  .pv3-ns {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
  .pv4-ns {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }
  .pv5-ns {
    padding-top: $spacing-extra-large;
    padding-bottom: $spacing-extra-large;
  }
  .pv6-ns {
    padding-top: $spacing-extra-extra-large;
    padding-bottom: $spacing-extra-extra-large;
  }
  .pv7-ns {
    padding-top: $spacing-extra-extra-extra-large;
    padding-bottom: $spacing-extra-extra-extra-large;
  }
  .ph0-ns {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
  .ph1-ns {
    padding-left: $spacing-extra-small;
    padding-right: $spacing-extra-small;
  }
  .ph2-ns {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
  .ph3-ns {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
  .ph4-ns {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
  .ph5-ns {
    padding-left: $spacing-extra-large;
    padding-right: $spacing-extra-large;
  }
  .ph6-ns {
    padding-left: $spacing-extra-extra-large;
    padding-right: $spacing-extra-extra-large;
  }
  .ph7-ns {
    padding-left: $spacing-extra-extra-extra-large;
    padding-right: $spacing-extra-extra-extra-large;
  }

  .ma0-ns  {  margin: $spacing-none; }
  .ma1-ns {  margin: $spacing-extra-small; }
  .ma2-ns  {  margin: $spacing-small; }
  .ma3-ns  {  margin: $spacing-medium; }
  .ma4-ns  {  margin: $spacing-large; }
  .ma5-ns  {  margin: $spacing-extra-large; }
  .ma6-ns {  margin: $spacing-extra-extra-large; }
  .ma7-ns { margin: $spacing-extra-extra-extra-large; }

  .ml0-ns  {  margin-left: $spacing-none; }
  .ml1-ns {  margin-left: $spacing-extra-small; }
  .ml2-ns  {  margin-left: $spacing-small; }
  .ml3-ns  {  margin-left: $spacing-medium; }
  .ml4-ns  {  margin-left: $spacing-large; }
  .ml5-ns  {  margin-left: $spacing-extra-large; }
  .ml6-ns {  margin-left: $spacing-extra-extra-large; }
  .ml7-ns { margin-left: $spacing-extra-extra-extra-large; }

  .mr0-ns  {  margin-right: $spacing-none; }
  .mr1-ns {  margin-right: $spacing-extra-small; }
  .mr2-ns  {  margin-right: $spacing-small; }
  .mr3-ns  {  margin-right: $spacing-medium; }
  .mr4-ns  {  margin-right: $spacing-large; }
  .mr5-ns  {  margin-right: $spacing-extra-large; }
  .mr6-ns {  margin-right: $spacing-extra-extra-large; }
  .mr7-ns { margin-right: $spacing-extra-extra-extra-large; }

  .mb0-ns  {  margin-bottom: $spacing-none; }
  .mb1-ns {  margin-bottom: $spacing-extra-small; }
  .mb2-ns  {  margin-bottom: $spacing-small; }
  .mb3-ns  {  margin-bottom: $spacing-medium; }
  .mb4-ns  {  margin-bottom: $spacing-large; }
  .mb5-ns  {  margin-bottom: $spacing-extra-large; }
  .mb6-ns {  margin-bottom: $spacing-extra-extra-large; }
  .mb7-ns { margin-bottom: $spacing-extra-extra-extra-large; }

  .mt0-ns  {  margin-top: $spacing-none; }
  .mt1-ns {  margin-top: $spacing-extra-small; }
  .mt2-ns  {  margin-top: $spacing-small; }
  .mt3-ns  {  margin-top: $spacing-medium; }
  .mt4-ns  {  margin-top: $spacing-large; }
  .mt5-ns  {  margin-top: $spacing-extra-large; }
  .mt6-ns {  margin-top: $spacing-extra-extra-large; }
  .mt7-ns { margin-top: $spacing-extra-extra-extra-large; }

  .mv0-ns   {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
  .mv1-ns  {
    margin-top: $spacing-extra-small;
    margin-bottom: $spacing-extra-small;
  }
  .mv2-ns   {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .mv3-ns   {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }
  .mv4-ns   {
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
  }
  .mv5-ns   {
    margin-top: $spacing-extra-large;
    margin-bottom: $spacing-extra-large;
  }
  .mv6-ns  {
    margin-top: $spacing-extra-extra-large;
    margin-bottom: $spacing-extra-extra-large;
  }
  .mv7-ns  {
    margin-top: $spacing-extra-extra-extra-large;
    margin-bottom: $spacing-extra-extra-extra-large;
  }

  .mh0-ns   {
    margin-left: $spacing-none;
    margin-right: $spacing-none;
  }
  .mh1-ns   {
    margin-left: $spacing-extra-small;
    margin-right: $spacing-extra-small;
  }
  .mh2-ns   {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }
  .mh3-ns   {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
  .mh4-ns   {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
  .mh5-ns   {
    margin-left: $spacing-extra-large;
    margin-right: $spacing-extra-large;
  }
  .mh6-ns  {
    margin-left: $spacing-extra-extra-large;
    margin-right: $spacing-extra-extra-large;
  }
  .mh7-ns  {
    margin-left: $spacing-extra-extra-extra-large;
    margin-right: $spacing-extra-extra-extra-large;
  }

}

@media #{$breakpoint-medium} {
  .pa0-m  {  padding: $spacing-none; }
  .pa1-m {  padding: $spacing-extra-small; }
  .pa2-m  {  padding: $spacing-small; }
  .pa3-m  {  padding: $spacing-medium; }
  .pa4-m  {  padding: $spacing-large; }
  .pa5-m  {  padding: $spacing-extra-large; }
  .pa6-m {  padding: $spacing-extra-extra-large; }
  .pa7-m { padding: $spacing-extra-extra-extra-large; }

  .pl0-m  {  padding-left: $spacing-none; }
  .pl1-m {  padding-left: $spacing-extra-small; }
  .pl2-m  {  padding-left: $spacing-small; }
  .pl3-m  {  padding-left: $spacing-medium; }
  .pl4-m  {  padding-left: $spacing-large; }
  .pl5-m  {  padding-left: $spacing-extra-large; }
  .pl6-m {  padding-left: $spacing-extra-extra-large; }
  .pl7-m { padding-left: $spacing-extra-extra-extra-large; }

  .pr0-m  {  padding-right: $spacing-none; }
  .pr1-m {  padding-right: $spacing-extra-small; }
  .pr2-m  {  padding-right: $spacing-small; }
  .pr3-m  {  padding-right: $spacing-medium; }
  .pr4-m  {  padding-right: $spacing-large; }
  .pr5-m  {  padding-right: $spacing-extra-large; }
  .pr6-m {  padding-right: $spacing-extra-extra-large; }
  .pr7-m { padding-right: $spacing-extra-extra-extra-large; }

  .pb0-m  {  padding-bottom: $spacing-none; }
  .pb1-m {  padding-bottom: $spacing-extra-small; }
  .pb2-m  {  padding-bottom: $spacing-small; }
  .pb3-m  {  padding-bottom: $spacing-medium; }
  .pb4-m  {  padding-bottom: $spacing-large; }
  .pb5-m  {  padding-bottom: $spacing-extra-large; }
  .pb6-m {  padding-bottom: $spacing-extra-extra-large; }
  .pb7-m { padding-bottom: $spacing-extra-extra-extra-large; }

  .pt0-m  {  padding-top: $spacing-none; }
  .pt1-m {  padding-top: $spacing-extra-small; }
  .pt2-m  {  padding-top: $spacing-small; }
  .pt3-m  {  padding-top: $spacing-medium; }
  .pt4-m  {  padding-top: $spacing-large; }
  .pt5-m  {  padding-top: $spacing-extra-large; }
  .pt6-m {  padding-top: $spacing-extra-extra-large; }
  .pt7-m { padding-top: $spacing-extra-extra-extra-large; }

  .pv0-m {
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }
  .pv1-m {
    padding-top: $spacing-extra-small;
    padding-bottom: $spacing-extra-small;
  }
  .pv2-m {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  .pv3-m {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
  .pv4-m {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }
  .pv5-m {
    padding-top: $spacing-extra-large;
    padding-bottom: $spacing-extra-large;
  }
  .pv6-m {
    padding-top: $spacing-extra-extra-large;
    padding-bottom: $spacing-extra-extra-large;
  }
  .pv7-m {
    padding-top: $spacing-extra-extra-extra-large;
    padding-bottom: $spacing-extra-extra-extra-large;
  }

  .ph0-m {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
  .ph1-m {
    padding-left: $spacing-extra-small;
    padding-right: $spacing-extra-small;
  }
  .ph2-m {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
  .ph3-m {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
  .ph4-m {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
  .ph5-m {
    padding-left: $spacing-extra-large;
    padding-right: $spacing-extra-large;
  }
  .ph6-m {
    padding-left: $spacing-extra-extra-large;
    padding-right: $spacing-extra-extra-large;
  }
  .ph7-m {
    padding-left: $spacing-extra-extra-extra-large;
    padding-right: $spacing-extra-extra-extra-large;
  }

  .ma0-m  {  margin: $spacing-none; }
  .ma1-m {  margin: $spacing-extra-small; }
  .ma2-m  {  margin: $spacing-small; }
  .ma3-m  {  margin: $spacing-medium; }
  .ma4-m  {  margin: $spacing-large; }
  .ma5-m  {  margin: $spacing-extra-large; }
  .ma6-m {  margin: $spacing-extra-extra-large; }
  .ma7-m { margin: $spacing-extra-extra-extra-large; }

  .ml0-m  {  margin-left: $spacing-none; }
  .ml1-m {  margin-left: $spacing-extra-small; }
  .ml2-m  {  margin-left: $spacing-small; }
  .ml3-m  {  margin-left: $spacing-medium; }
  .ml4-m  {  margin-left: $spacing-large; }
  .ml5-m  {  margin-left: $spacing-extra-large; }
  .ml6-m {  margin-left: $spacing-extra-extra-large; }
  .ml7-m { margin-left: $spacing-extra-extra-extra-large; }

  .mr0-m  {  margin-right: $spacing-none; }
  .mr1-m {  margin-right: $spacing-extra-small; }
  .mr2-m  {  margin-right: $spacing-small; }
  .mr3-m  {  margin-right: $spacing-medium; }
  .mr4-m  {  margin-right: $spacing-large; }
  .mr5-m  {  margin-right: $spacing-extra-large; }
  .mr6-m {  margin-right: $spacing-extra-extra-large; }
  .mr7-m { margin-right: $spacing-extra-extra-extra-large; }

  .mb0-m  {  margin-bottom: $spacing-none; }
  .mb1-m {  margin-bottom: $spacing-extra-small; }
  .mb2-m  {  margin-bottom: $spacing-small; }
  .mb3-m  {  margin-bottom: $spacing-medium; }
  .mb4-m  {  margin-bottom: $spacing-large; }
  .mb5-m  {  margin-bottom: $spacing-extra-large; }
  .mb6-m {  margin-bottom: $spacing-extra-extra-large; }
  .mb7-m { margin-bottom: $spacing-extra-extra-extra-large; }

  .mt0-m  {  margin-top: $spacing-none; }
  .mt1-m {  margin-top: $spacing-extra-small; }
  .mt2-m  {  margin-top: $spacing-small; }
  .mt3-m  {  margin-top: $spacing-medium; }
  .mt4-m  {  margin-top: $spacing-large; }
  .mt5-m  {  margin-top: $spacing-extra-large; }
  .mt6-m {  margin-top: $spacing-extra-extra-large; }
  .mt7-m { margin-top: $spacing-extra-extra-extra-large; }

  .mv0-m {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
  .mv1-m {
    margin-top: $spacing-extra-small;
    margin-bottom: $spacing-extra-small;
  }
  .mv2-m {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .mv3-m {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }
  .mv4-m {
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
  }
  .mv5-m {
    margin-top: $spacing-extra-large;
    margin-bottom: $spacing-extra-large;
  }
  .mv6-m {
    margin-top: $spacing-extra-extra-large;
    margin-bottom: $spacing-extra-extra-large;
  }
  .mv7-m {
    margin-top: $spacing-extra-extra-extra-large;
    margin-bottom: $spacing-extra-extra-extra-large;
  }

  .mh0-m {
    margin-left: $spacing-none;
    margin-right: $spacing-none;
  }
  .mh1-m {
    margin-left: $spacing-extra-small;
    margin-right: $spacing-extra-small;
  }
  .mh2-m {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }
  .mh3-m {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
  .mh4-m {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
  .mh5-m {
    margin-left: $spacing-extra-large;
    margin-right: $spacing-extra-large;
  }
  .mh6-m {
    margin-left: $spacing-extra-extra-large;
    margin-right: $spacing-extra-extra-large;
  }
  .mh7-m {
    margin-left: $spacing-extra-extra-extra-large;
    margin-right: $spacing-extra-extra-extra-large;
  }

}

@media #{$breakpoint-large} {
  .pa0-l  {  padding: $spacing-none; }
  .pa1-l {  padding: $spacing-extra-small; }
  .pa2-l  {  padding: $spacing-small; }
  .pa3-l  {  padding: $spacing-medium; }
  .pa4-l  {  padding: $spacing-large; }
  .pa5-l  {  padding: $spacing-extra-large; }
  .pa6-l {  padding: $spacing-extra-extra-large; }
  .pa7-l { padding: $spacing-extra-extra-extra-large; }

  .pl0-l  {  padding-left: $spacing-none; }
  .pl1-l {  padding-left: $spacing-extra-small; }
  .pl2-l  {  padding-left: $spacing-small; }
  .pl3-l  {  padding-left: $spacing-medium; }
  .pl4-l  {  padding-left: $spacing-large; }
  .pl5-l  {  padding-left: $spacing-extra-large; }
  .pl6-l {  padding-left: $spacing-extra-extra-large; }
  .pl7-l { padding-left: $spacing-extra-extra-extra-large; }

  .pr0-l  {  padding-right: $spacing-none; }
  .pr1-l {  padding-right: $spacing-extra-small; }
  .pr2-l  {  padding-right: $spacing-small; }
  .pr3-l  {  padding-right: $spacing-medium; }
  .pr4-l  {  padding-right: $spacing-large; }
  .pr5-l  {  padding-right: $spacing-extra-large; }
  .pr6-l {  padding-right: $spacing-extra-extra-large; }
  .pr7-l { padding-right: $spacing-extra-extra-extra-large; }

  .pb0-l  {  padding-bottom: $spacing-none; }
  .pb1-l {  padding-bottom: $spacing-extra-small; }
  .pb2-l  {  padding-bottom: $spacing-small; }
  .pb3-l  {  padding-bottom: $spacing-medium; }
  .pb4-l  {  padding-bottom: $spacing-large; }
  .pb5-l  {  padding-bottom: $spacing-extra-large; }
  .pb6-l {  padding-bottom: $spacing-extra-extra-large; }
  .pb7-l { padding-bottom: $spacing-extra-extra-extra-large; }

  .pt0-l  {  padding-top: $spacing-none; }
  .pt1-l {  padding-top: $spacing-extra-small; }
  .pt2-l  {  padding-top: $spacing-small; }
  .pt3-l  {  padding-top: $spacing-medium; }
  .pt4-l  {  padding-top: $spacing-large; }
  .pt5-l  {  padding-top: $spacing-extra-large; }
  .pt6-l {  padding-top: $spacing-extra-extra-large; }
  .pt7-l { padding-top: $spacing-extra-extra-extra-large; }

  .pv0-l {
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }
  .pv1-l {
    padding-top: $spacing-extra-small;
    padding-bottom: $spacing-extra-small;
  }
  .pv2-l {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  .pv3-l {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
  .pv4-l {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }
  .pv5-l {
    padding-top: $spacing-extra-large;
    padding-bottom: $spacing-extra-large;
  }
  .pv6-l {
    padding-top: $spacing-extra-extra-large;
    padding-bottom: $spacing-extra-extra-large;
  }
  .pv7-l {
    padding-top: $spacing-extra-extra-extra-large;
    padding-bottom: $spacing-extra-extra-extra-large;
  }

  .ph0-l {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
  .ph1-l {
    padding-left: $spacing-extra-small;
    padding-right: $spacing-extra-small;
  }
  .ph2-l {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
  .ph3-l {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
  .ph4-l {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
  .ph5-l {
    padding-left: $spacing-extra-large;
    padding-right: $spacing-extra-large;
  }
  .ph6-l {
    padding-left: $spacing-extra-extra-large;
    padding-right: $spacing-extra-extra-large;
  }
  .ph7-l {
    padding-left: $spacing-extra-extra-extra-large;
    padding-right: $spacing-extra-extra-extra-large;
  }

  .ma0-l  {  margin: $spacing-none; }
  .ma1-l {  margin: $spacing-extra-small; }
  .ma2-l  {  margin: $spacing-small; }
  .ma3-l  {  margin: $spacing-medium; }
  .ma4-l  {  margin: $spacing-large; }
  .ma5-l  {  margin: $spacing-extra-large; }
  .ma6-l {  margin: $spacing-extra-extra-large; }
  .ma7-l { margin: $spacing-extra-extra-extra-large; }

  .ml0-l  {  margin-left: $spacing-none; }
  .ml1-l {  margin-left: $spacing-extra-small; }
  .ml2-l  {  margin-left: $spacing-small; }
  .ml3-l  {  margin-left: $spacing-medium; }
  .ml4-l  {  margin-left: $spacing-large; }
  .ml5-l  {  margin-left: $spacing-extra-large; }
  .ml6-l {  margin-left: $spacing-extra-extra-large; }
  .ml7-l { margin-left: $spacing-extra-extra-extra-large; }

  .mr0-l  {  margin-right: $spacing-none; }
  .mr1-l {  margin-right: $spacing-extra-small; }
  .mr2-l  {  margin-right: $spacing-small; }
  .mr3-l  {  margin-right: $spacing-medium; }
  .mr4-l  {  margin-right: $spacing-large; }
  .mr5-l  {  margin-right: $spacing-extra-large; }
  .mr6-l {  margin-right: $spacing-extra-extra-large; }
  .mr7-l { margin-right: $spacing-extra-extra-extra-large; }

  .mb0-l  {  margin-bottom: $spacing-none; }
  .mb1-l {  margin-bottom: $spacing-extra-small; }
  .mb2-l  {  margin-bottom: $spacing-small; }
  .mb3-l  {  margin-bottom: $spacing-medium; }
  .mb4-l  {  margin-bottom: $spacing-large; }
  .mb5-l  {  margin-bottom: $spacing-extra-large; }
  .mb6-l {  margin-bottom: $spacing-extra-extra-large; }
  .mb7-l { margin-bottom: $spacing-extra-extra-extra-large; }

  .mt0-l  {  margin-top: $spacing-none; }
  .mt1-l {  margin-top: $spacing-extra-small; }
  .mt2-l  {  margin-top: $spacing-small; }
  .mt3-l  {  margin-top: $spacing-medium; }
  .mt4-l  {  margin-top: $spacing-large; }
  .mt5-l  {  margin-top: $spacing-extra-large; }
  .mt6-l {  margin-top: $spacing-extra-extra-large; }
  .mt7-l { margin-top: $spacing-extra-extra-extra-large; }

  .mv0-l {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
  .mv1-l {
    margin-top: $spacing-extra-small;
    margin-bottom: $spacing-extra-small;
  }
  .mv2-l {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .mv3-l {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }
  .mv4-l {
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
  }
  .mv5-l {
    margin-top: $spacing-extra-large;
    margin-bottom: $spacing-extra-large;
  }
  .mv6-l {
    margin-top: $spacing-extra-extra-large;
    margin-bottom: $spacing-extra-extra-large;
  }
  .mv7-l {
    margin-top: $spacing-extra-extra-extra-large;
    margin-bottom: $spacing-extra-extra-extra-large;
  }

  .mh0-l {
    margin-left: $spacing-none;
    margin-right: $spacing-none;
  }
  .mh1-l {
    margin-left: $spacing-extra-small;
    margin-right: $spacing-extra-small;
  }
  .mh2-l {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }
  .mh3-l {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
  .mh4-l {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
  .mh5-l {
    margin-left: $spacing-extra-large;
    margin-right: $spacing-extra-large;
  }
  .mh6-l {
    margin-left: $spacing-extra-extra-large;
    margin-right: $spacing-extra-extra-large;
  }
  .mh7-l {
    margin-left: $spacing-extra-extra-extra-large;
    margin-right: $spacing-extra-extra-extra-large;
  }
}

@media #{$breakpoint-extra-large} {
  .pt0-xl  {  padding-top: $spacing-none; }

  .ml0-xl  {  margin-left: $spacing-none; }
}
