/**
 * REGULAR ICONS ---------------------- */

.icon {
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}

.icon--large  { height: 32px; width: 32px; }
.icon--medium { height: 24px; width: 24px; }
.icon--small  { height: 16px; width: 16px; }

.icon-error { background-image: url(../img/error.svg); }

// Landing page icons

.icon-featured {
  display: block;
  height: ms(25);
  margin: 0 auto;
  width: ms(25);

  &-creative { background-image: url(../img/featured_creative.svg); }
  &-discuss  { background-image: url(../img/featured_discuss.svg);  }
  &-improve  { background-image: url(../img/featured_improve.svg);  }
}

.icon-facebook-logo {
  background-image: url(../img/facebook_white_f.svg);
  height: 24px;
  margin-right: 12px;
  margin-top: -4px;
  width: 24px;
}

// Crossword status icons

.icon-submission {
  height: ms(7);
  width: ms(7);

  &-error   { background-image: svg-load('../icons/submission_error.svg');   }
  &-correct { background-image: svg-load('../icons/submission_correct.svg'); }
  &-pending { background-image: svg-load('../icons/submission_pending.svg'); }

  &-submitted {
    &-error   { background-image: svg-load('../icons/submitted_error.svg');   }
    &-correct { background-image: svg-load('../icons/submitted_correct.svg'); }
    &-pending { background-image: svg-load('../icons/submitted_pending.svg'); }
  }
}

.icon-crossword-new {
  height: ms(7);
  width: ms(7);

  background-image: svg-load('../icons/crossword_new.svg');
}

// Shared crossword icons

.icon-shared-completed {
  display: block;
  height: ms(22);
  margin: 0 auto;
  width: ms(22);
}

// Forum icons

.icon-author    { background-image: svg-load('../icons/forum_author.svg'); }
.icon-moderator { background-image: svg-load('../icons/forum_moderator.svg'); }

.icon-search        { background-image: svg-load('../icons/search.svg', fill=$dark); }
.icon-search--white { background-image: svg-load('../icons/search.svg', fill=$white); }

.icon-last-active        { background-image: svg-load('../icons/last-active.svg', fill=$dark); }
.icon-last-active--red   { background-image: svg-load('../icons/last-active.svg', fill=$red); }
.icon-last-active--white { background-image: svg-load('../icons/last-active.svg', fill=$white); }

.icon-pinned { background-image: svg-load('../icons/pinned.svg', fill=$red); }

.icon-locked { background-image: svg-load('../icons/locked.svg', fill=$gray); }

.icon-pinned,
.icon-locked {
  height: ms(4);
  width: ms(4);
}

.icon-pinned + .icon-locked {
  margin-left: -( ms(-1) );
}

.icon-up { background-image: svg-load('../icons/up.svg', fill=$red); }

.icon-down { background-image: svg-load('../icons/down.svg', fill=$red); }

.icon-forum-shortcut {
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

// Profile icons

.icon-birthday { background-image: svg-load('../icons/birthday.svg', fill=$dark); }
.icon-location { background-image: svg-load('../icons/location.svg', fill=$dark);  }

// Subscription icons

.icon-subscription-type {
  height: ms(22);
  width: ms(22);

  &--free          { background-image: url(../img/subscription_free.svg);    }
  &--month         { background-image: url(../img/subscription_monthly.svg); }
  &--annual        { background-image: url(../img/subscription_annual.svg);  }

  &--annual--white {
    background-image: url(../img/subscription_annual_white.svg);
  }

  &--small {
    height: ms(18);
    width: ms(18);
  }

  &--large {
    height: ms(24);
    width: ms(24);
  }
}

.icon-subscription-completed {
  display: block;
  height: ms(24);
  margin: 0 auto;
  width: ms(24);
}

.icon-invoice {
  height: 24px;
  width: 24px;

  &--ok { background-image: svg-load('../icons/submission_correct.svg'); }
  &--error { background-image: svg-load('../icons/submission_error.svg'); }
}

// General icons

.icon-dropdown {
  background-image: svg-load('../icons/collapse.svg', fill=$dark);
  height: 10px;
  width: 14px;
}

.icon-close {
  background-image: svg-load('../icons/close.svg', fill=$dark);
  height: 15px;
  width: 15px;
}

.icon-close--white {
  background-image: svg-load('../icons/close.svg', fill=$white);
  height: 15px;
  width: 15px;
}

.icon-expand {
  background-image: svg-load('../icons/expand.svg', fill=$red);
  height: ms(-2);
  width: ms(-3);
}

.icon-copy {
  background-image: svg-load('../icons/copy.svg', fill=$red);
  height: 20px;
  width: 20px;
}

/**
 * SPRITE ICONS ----------------------- */

svg { display: block; }

.s-icon-menu {
  display: inline-block;
  color: $dark;
  height: 35px;
  width: 36px;
}

// Stars

.s-icon-star-full,
.s-icon-star-empty {
  display: inline-block;
  height: ms(1);
  margin-right: ms(-22);
  width: ms(1);
}

.s-icon-star--medium {
  height: ms(2);
  width: ms(2);
}

.s-icon-star--large {
  height: ms(4);
  width: ms(4);
}

.s-icon-star-white .s-icon-star-full,
.s-icon-star-white .s-icon-star-empty {
  color: $white;
}

.s-icon-star-gray .s-icon-star-full,
.s-icon-star-gray .s-icon-star-empty {
  color: $gray;
}

// Notifications

.s-icon-notification {
  height: ms(8);
  width: ms(8);

  &-messaged {
    fill: $pale-teal-20;
    color: $pale-red;
  }

  &-subscription {
    color: $pale-teal;
  }

  &--small {
    height: ms(2);
    width: ms(2);
  }
}

// Forum

.s-icon-category {
  color: $dark;
  height: 1rem;
  width: 1rem;
}

.s-icon-category-white { color: $white; }
.s-icon-category-gray  { color: $gray; }

/* DUMPSTER */

.icon-button-hide { background-image: svg-load('../icons/button_hide.svg'); }
.icon-button-show { background-image: svg-load('../icons/button_show.svg'); }
.icon-button-back { background-image: svg-load('../icons/button_back.svg'); }
.icon-button-forward { background-image: svg-load('../icons/button_forward.svg'); }

.icon-discuss {
  background-image: svg-load('../icons/discuss.svg', fill=$dark);
  height: 16px;
  width: 20px;
}

.icon-share {
  background-image: svg-load('../icons/share.svg', fill=$dark);
  height: 16px;
  width: 20px;
}

.icon-submissions-list {
  background-image: svg-load('../icons/submissions.svg', fill=$dark);
  height: 16px;
  width: 24px;
}

.icon-download { background-image: svg-load('../icons/download.svg', fill=$dark); }

.icon-help {
  background-image: svg-load('../icons/help.svg', fill=$dark);
  height: 16px;
  width: 16px;
}

.icon-hide {
  background-image: svg-load('../icons/eye-hidden.svg', fill=$dark);
  height: 28px;
  width: 28px;
}

.icon-messages {
  background-image: svg-load('../icons/messaged.svg', fill=$dark);
}

.icon-messages--white {
  background-image: svg-load('../icons/messaged.svg', fill=$white);
}

.icon-notifications {
  background-image: svg-load('../icons/notifications.svg', fill=$dark);
}

.icon-notifications--white {
  background-image: svg-load('../icons/notifications.svg', fill=$white);
}

.icon-settings {
  background-image: svg-load('../icons/settings.svg', fill=$dark);
}

.icon-settings--white {
  background-image: svg-load('../icons/settings.svg', fill=$white);
}

.icon-subscription  {
  background-image: svg-load('../icons/subscription.svg', fill=$dark);
}

.icon-subscription--white {
  background-image: svg-load('../icons/subscription.svg', fill=$white);
}

.icon-clear {
  background-image: svg-load('../icons/borders_clear.svg', fill=$dark);
  height: 20px;
  width: 20px;
}

.icon-errors {
  background-image: svg-load('../icons/errors.svg', fill=$dark);
  height: 20px;
  width: 21px;
}


/** --------- **/

.icon-discuss-list,
.icon-discuss-title {
  display: inline-block;
  fill: currentColor;
  vertical-align: middle;
}

.icon-avatar-decoration {
  height: ms(5);
  position: absolute;
  right: -(ms(5) / 2);
  top: -(ms(-16));
  width: ms(5);
}
