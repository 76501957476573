.pending-crossword {
  .icon-submission {
    height: ms(7);
    width: ms(7);

    &-pending,
    &-submitted-pending {
      background-image: svg-load('../icons/eye-hidden.svg', fill=$gray) !important;
    }

    &-pending:hover,
    &-submitted-pending:hover {
      background-image: svg-load('../icons/eye-hidden.svg', fill=$red) !important;
    }
  }
}
